import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import AnswerSummary from '@doc/components/patients/details/components/AnswerSummary'
import HealthRelatedChartInterface from '@doc/components/patients/details/components/HealthRelatedChartInterface'
import { ActionsInAppActions, ActionsInAppState, GraphInfo, PatientActivityData } from '@doc/type'
import { closestTo, format, fromUnixTime, isSameDay, parse } from 'date-fns'
import produce from 'immer'
import _ from 'lodash'
import { ReactElement, useEffect, useMemo, useReducer, useRef, useState } from 'react'
// import { useDraggable } from 'react-use-draggable-scroll'
import {
    FloatingArrow,
    FloatingFocusManager,
    arrow,
    autoUpdate,
    flip,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useHover,
    useId,
    useInteractions,
    useRole,
    useTransitionStyles
} from '@floating-ui/react'
import { Offcanvas, OffcanvasBody, OffcanvasHeader, Progress } from 'reactstrap'
interface ComponentProps {
    floatingUiBoundary: HTMLDivElement | null,
    getPatientActivityMutation: {
        isLoading: boolean
        isSuccess: boolean
    }
    patientActivityData: (PatientActivityData)[]
    patientChartData: GraphInfo[]
}

const DebouncedProgressBarComponent = ({ valueToPass }: {valueToPass: number}) => {
    const [value, setValue] = useState(0)

    // Define a debounced function to update the state with the provided value
    const debouncedUpdateValue = _.debounce((newValue) => {
        setValue(newValue)
    }, 500) // Debounce for 0.5 seconds

    useEffect(() => {
        console.log('progress bar component renders on mount.')
    }, [])

    useEffect(() => {
        // Trigger the debounced function with the provided value
        debouncedUpdateValue(valueToPass)

        // Cleanup the debounce function on component unmount
        return () => {
            debouncedUpdateValue.cancel()
        }
    }, [valueToPass]) // Re-run the effect when valueToPass changes

    return <Progress
        className={'mt-1'}
        value={value}
    >
    </Progress>
}

const TitleDateRange = ({ weekDates }: {weekDates: {
    dateString: string;
    arr:(PatientActivityData & {isRendered: boolean})[];
    isCurrent?: boolean | undefined;
        }[]}) => {
    if (weekDates.length) {
        const start = parse(weekDates[0]?.dateString, 'MM-dd-yyyy', new Date())
        const end = parse(weekDates[weekDates.length - 1].dateString
            , 'MM-dd-yyyy', new Date())

        const formattedStartDate = format(start, 'd MMM yyyy')
        const formattedEndDate = format(end, 'd MMM yyyy')

        return weekDates.length
            ? <span className={'fw-semibold'}>{
                [
                    formattedStartDate,
                    ' - ',
                    formattedEndDate
                ].join(' ')
            }</span>
            : <></>
    }

    return <></>
}

const OffCanvas = ({
    patientActivityData,
    actionsInAppState,
    actionsInAppDispatch,
    rootRef,
    setShowOffcanvas,
    showOffcanvas
}:{
    patientActivityData: ComponentProps['patientActivityData']
    actionsInAppState: ActionsInAppState
    actionsInAppDispatch: React.Dispatch<ActionsInAppActions>
    rootRef: React.MutableRefObject<HTMLDivElement | null>
    setShowOffcanvas: React.Dispatch<React.SetStateAction<boolean>>
    showOffcanvas: boolean
}) => {
    const strings = useAppSelector(selectStrings)

    // get available stuffs. no need to get from the reducer state.
    // that's just harder to do.
    const availableStepTypes = _.compact(
        _.uniq(
            _.map(
                _.isArray(patientActivityData)
                    ? patientActivityData || []
                    : [], (o) => o.theStepType
            )
        )
    )

    const availableStepStates = _.compact(
        _.uniq(
            _.map(
                _.isArray(patientActivityData)
                    ? patientActivityData || []
                    : [], (o) => o.stepState
            )
        )
    )

    // compact is removed because zero is  falsy value
    const availableStepStatuses =
        _.uniq(
            _.map(
                _.isArray(patientActivityData)
                    ? patientActivityData || []
                    : [], (o) => o.stepStatus
            )
        )

    let StepTypesCheckboxes: {
        id: string
        isChecked: boolean
        name: keyof ActionsInAppState['filters']['stepType']
        label: string | undefined
    }[] = []

    let StepStatesCheckboxes: {
        id: string
        isChecked: boolean
        name: keyof ActionsInAppState['filters']['stepState']
        label: string | undefined
    }[] = []

    let StepStatusesCheckboxes: {
        id: string
        isChecked: boolean
        name: keyof ActionsInAppState['filters']['stepStatus']
        label: string | undefined
    }[] = []

    StepTypesCheckboxes = _.map(availableStepTypes, (str) => {
        let stepString = ''

        if (str === 'activity') {
            stepString = strings.doc?.text.patient.patient_progress.table.filters.activity || ''
        } else if (str === 'content') {
            stepString = strings.doc?.text.patient.patient_progress.table.filters.content || ''
        } else if (str === 'lesson') {
            stepString = strings.doc?.text.patient.patient_progress.table.filters.lesson || ''
        } else if (str === 'uncertain') {
            stepString = strings.doc?.text
                .patient.patient_progress.table.filters.uncertain || ''
        } else if (str === 'reasoning') {
            stepString = strings.doc?.text
                .patient.patient_progress.table.filters.reasoning || ''
        }

        return {
            id: _.upperCase('PATIENT__CHECK__STEP_TYPE-'.concat(str)),
            isChecked: Boolean(actionsInAppState.filters.stepType[str]),
            name: str,
            label: stepString
        }
    })

    StepStatesCheckboxes = _.map(availableStepStates, (str) => {
        let stepString = ''

        if (str === 'complete') {
            stepString = strings.doc?.text.patient
                .patient_progress.table.stepState.complete || ''
        } else if (str === 'incomplete') {
            stepString = strings.doc?.text.patient
                .patient_progress.table.stepState.incomplete || ''
        } else if (str === 'open') {
            stepString = strings.doc?.text.patient
                .patient_progress.table.stepState.open || ''
        } else if (str === 'locked') {
            stepString = strings.doc?.text.patient
                .patient_progress.table.stepState.locked || ''
        } else if (str === 'uncertain') {
            stepString = strings.doc?.text.patient
                .patient_progress.table.stepState.uncertain || ''
        }

        return {
            id: _.upperCase('PATIENT__CHECK__STEP_STATE-'.concat(str)),
            isChecked: Boolean(actionsInAppState.filters.stepState[str]),
            name: str,
            label: stepString
        }
    })

    StepStatusesCheckboxes = _.map(availableStepStatuses, (str) => {
        let stepString = ''

        if (str === 0) {
            stepString = strings.doc?.text.patient.actions_in_app.severity.minimal || ''
        } else if (str === 1) {
            stepString = strings.doc?.text.patient.actions_in_app.severity.low || ''
        } else if (str === 2) {
            stepString = strings.doc?.text.patient.actions_in_app.severity.moderate || ''
        } else if (str === 3) {
            stepString = strings.doc?.text.patient.actions_in_app.severity.high || ''
        } else if (str === 4) {
            stepString = strings.doc?.text.patient.actions_in_app.severity.critical || ''
        }

        return {
            id: _.upperCase('PATIENT__CHECK__STEP_STATUS-'.concat(String(str))),
            isChecked: Boolean(actionsInAppState.filters.stepStatus[str]),
            name: str,
            label: stepString
        }
    })

    const renderedCheckboxes1 = _.map(StepTypesCheckboxes, (obj, index) => {
        const key = `checkbox-step-type-${ index }`

        return <div className={'mb-2 form-check'} key={key}>
            <input type={'checkbox'}
                className={'form-check-input'}
                id={obj.id}
                checked={obj.isChecked}
                onChange={() => {
                    // console.log('update 1')
                    actionsInAppDispatch({
                        type: 'UPDATE_FILTERS_STEP_TYPE',
                        value: {
                            filterValue: !obj.isChecked,
                            key: obj.name
                        }
                    })
                }}
            />
            <label htmlFor={obj.id}>
                <small className={'d-block'}>
                    {obj.label}
                </small>
            </label>
        </div>
    })

    const renderedCheckboxes2 = _.map(StepStatesCheckboxes, (obj, index) => {
        const key = `checkbox-step-state-${ index }`

        return <div className={'mb-2 form-check'} key={key}>
            <input type={'checkbox'}
                className={'form-check-input'}
                id={obj.id}
                checked={obj.isChecked}
                onChange={() => {
                    // console.log('update 2')
                    actionsInAppDispatch({
                        type: 'UPDATE_FILTERS_STEP_STATE',
                        value: {
                            filterValue: !obj.isChecked,
                            key: obj.name
                        }
                    })
                }}
            />
            <label htmlFor={obj.id}>
                <small className={'d-block'}>
                    {obj.label}
                </small>
            </label>
        </div>
    })

    const renderedCheckboxes3 = _.map(StepStatusesCheckboxes, (obj, index) => {
        const key = `checkbox-step-status-${ index }`

        return <div className={'mb-2 form-check'} key={key}>
            <input type={'checkbox'}
                className={'form-check-input'}
                id={obj.id}
                checked={obj.isChecked}
                onChange={() => {
                    // console.log('update 3')
                    actionsInAppDispatch({
                        type: 'UPDATE_FILTERS_STEP_STATUS',
                        value: {
                            filterValue: !obj.isChecked,
                            key: obj.name
                        }
                    })
                }}
            />
            <label htmlFor={obj.id}>
                <small className={'d-block'}>
                    {obj.label}
                </small>
            </label>
        </div>
    })
    return <Offcanvas
        fade={true}
        container={rootRef}
        isOpen={showOffcanvas}
        direction={'end'}
        toggle={() => {
            setShowOffcanvas(!showOffcanvas)
        }}
    >
        <OffcanvasHeader
            className={'align-items-center offcanvas-header'}
            tag={'div'}
            close={ <button
                type={'button'}
                className={
                    'border btn btn-round  offcanvas-trigger shadow-none'
                }
                onClick={() => {
                    setShowOffcanvas(!showOffcanvas)
                }}
            >
                <i className={'fa-light fa-xmark'} aria-hidden={'true'}></i>
            </button>}
            toggle={() => {
                setShowOffcanvas(!showOffcanvas)
            }}
        >

            <h2 id={'offcanvas-header-label mb-0'}>
                {strings.doc?.text.patient.patient_progress.table.filters.name}
            </h2>

        </OffcanvasHeader>
        <OffcanvasBody className={'d-flex flex-column'}>
            <div >

                <div className={'mb-4'} role={'group'}>

                    <small className={'d-block fw-bold mb-2'}>
                        {strings.doc?.text.patient.actions_in_app.type}
                    </small>

                    {renderedCheckboxes1}

                </div>

                <div className={'mb-4'} role={'group'}>

                    <small className={'d-block fw-bold mb-2'}>
                        {strings.doc?.text.patient.actions_in_app.state}
                    </small>

                    {renderedCheckboxes2}

                </div>

                <div className={'mb-4'} role={'group'}>

                    <small className={'d-block fw-bold mb-2'}>
                        {strings.doc?.text.patient.patient_progress.table.status}
                    </small>

                    {renderedCheckboxes3}

                </div>

            </div>
        </OffcanvasBody>
    </Offcanvas>
}

const TaskCard = ({
    floatingUiBoundary,
    p,
    innerIndex,
    dateString,
    actionsInAppDispatch
}: {
    floatingUiBoundary: HTMLDivElement | null,
    p: (PatientActivityData & {isRendered: boolean}),
    innerIndex: number,
    dateString: string,
    actionsInAppDispatch: React.Dispatch<ActionsInAppActions>
}) => {
    const strings = useAppSelector(selectStrings)

    let stepTypeString = ''

    if (p.theStepType === 'activity') {
        stepTypeString = strings.doc?.text.patient.patient_progress.table.filters.activity || ''
    } else if (p.theStepType === 'content') {
        stepTypeString = strings.doc?.text.patient.patient_progress.table.filters.content || ''
    } else if (p.theStepType === 'lesson') {
        stepTypeString = strings.doc?.text.patient.patient_progress.table.filters.lesson || ''
    } else if (p.theStepType === 'uncertain') {
        stepTypeString = strings.doc?.text
            .patient.patient_progress.table.filters.uncertain || ''
    } else if (p.theStepType === 'reasoning') {
        stepTypeString = strings.doc?.text
            .patient.patient_progress.table.filters.reasoning || ''
    }

    const isComplete = p.stepState === 'complete'
    const locked = p.stepState === 'locked'
    const uncertain = p.stepState === 'uncertain'
    const isIncomplete = p.stepState === 'incomplete'
    const isOpen = p.stepState === 'open'

    const stepStateString = isComplete
        ? strings.doc?.text.patient
            .patient_progress.table.stepState.complete
        : locked
            ? strings.doc?.text.patient
                .patient_progress.table.stepState.locked
            : uncertain
                ? strings.doc?.text.patient
                    .patient_progress.table.stepState.uncertain
                : isIncomplete
                    ? strings.doc?.text.patient
                        .patient_progress.table.stepState.incomplete
                    : isOpen
                        ? strings.doc?.text.patient
                            .patient_progress.table.stepState.open
                        : ''

    const stepStateIcon = isComplete
        ? 'fa-circle-check'
        : locked
            ? 'fa-circle-exclamation'
            : uncertain
                ? 'fa-circle-question'
                : isIncomplete
                    ? 'fa-clock'
                    : isOpen
                        ? 'fa-circle-xmark'
                        : 'fa-circle-o'

    const progressBar = <DebouncedProgressBarComponent
        valueToPass={_.round(p?.percentComplete || 0)}
    />
    const [visible, setVisible] = useState(false)

    const arrowRef = useRef(null)

    const { refs, floatingStyles, context } = useFloating({
        open: visible,
        placement: 'top',
        onOpenChange: (isOpen, event, reason) => {
            event && console.log(event)
            reason && console.log(reason)
            // when to close the tooltip.
            if (reason === 'click' || reason === 'outside-press') {
                setVisible(isOpen)
            }

            if (p.isRendered === false) {
                // pass in datestring,
                // pass in array index.
                actionsInAppDispatch({
                    type: 'UPDATE_IS_RENDERED',
                    value: {
                        dateString,
                        arrIndex: innerIndex,
                        boolean: true
                    }
                })
            }
        },
        middleware: [
            offset(10),
            flip({
                boundary: floatingUiBoundary || 'clippingAncestors',
                fallbackAxisSideDirection: 'end'
            }),
            shift(),
            arrow({
                element: arrowRef
            })
        ],
        whileElementsMounted: autoUpdate

    })

    const click = useClick(context)
    const dismiss = useDismiss(context)
    const role = useRole(context)

    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        dismiss,
        role
    ])

    const headingId = useId()

    return <div
    >
        <div ref={refs.setReference} {...getReferenceProps()}
            className={
                'task-cell mb-3 row gx-0 clickable'
            }>
            <div className={'col-auto'}>
                <div className={[
                    'icon',
                    p.stepState
                ].join(' ')}>
                    <i className={[
                        'fas',
                        stepStateIcon
                    ].join(' ')}></i>
                </div>
            </div>
            <div className={'col-auto ms-2'}>
                <span className={'title'}>{stepTypeString}</span>
                <span className={'subtitle'}>{stepStateString}</span>
                {/* add progress bar here */}
                {progressBar}
            </div>
        </div>
        {
            <FloatingFocusManager context={context} modal={false}>
                <div
                    className={'floating-ui answer-overview'}
                    ref={refs.setFloating}
                    style={{
                        ...floatingStyles,
                        display: visible ? '' : 'none'
                    }}
                    aria-labelledby={headingId}
                    {...getFloatingProps()}
                >
                    <FloatingArrow ref={arrowRef} context={context}
                    />
                    {
                        p.isRendered && <AnswerSummary careplanStepId={p.careplanStepId} />
                    }
                </div>

            </FloatingFocusManager>
        }
    </div>
}

const InformationPopover = ({ boundary, text, element }:
    {boundary: HTMLDivElement | null, text:string | undefined, element: ReactElement}) => {
    const arrowRef = useRef(null)
    const [visible, setVisible] = useState(false)

    const { refs, floatingStyles, context } = useFloating({
        open: visible,
        placement: 'top',
        onOpenChange: (isOpen, event, reason) => {
            setVisible(isOpen)
            // event && console.log(event)
            // reason && console.log(reason)
        },
        middleware: [
            offset(10),
            flip({
                fallbackAxisSideDirection: 'end',
                boundary: boundary || 'clippingAncestors'
            }),
            shift(),
            arrow({
                element: arrowRef
            })
        ],
        whileElementsMounted: autoUpdate

    })
    const { styles } = useTransitionStyles(context)

    const hover = useHover(context)

    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover
    ])

    const headingId = useId()

    return <>
        <div ref={refs.setReference} {...getReferenceProps()}>
            {element}
        </div>
        { visible && <FloatingFocusManager context={context} modal={false}>
            <div
                className={'floating-ui information'}
                ref={refs.setFloating}
                style={{
                    ...floatingStyles,
                    ...styles
                }}
                aria-labelledby={headingId}
                {...getFloatingProps()}
            >
                <FloatingArrow ref={arrowRef} context={context}
                />
                {

                    <div><span>{
                        text
                    }</span></div>
                }
            </div>

        </FloatingFocusManager>}

    </>
}

const ActionsInApp = ({
    floatingUiBoundary, getPatientActivityMutation,
    patientActivityData, patientChartData
} : ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const [showOffcanvas, setShowOffcanvas] = useState(false)
    const rootRef = useRef<HTMLDivElement | null>(null)

    const [actionsInAppState, actionsInAppDispatch] = useReducer(
        (state: ActionsInAppState, action: ActionsInAppActions) => {
            switch (action.type) {
                case 'SET_GROUPED_DATA': {
                    return produce(state, draft => {
                        draft.dates = action.value
                    })
                }
                case 'SET_IS_GROUP_CURRENT' : {
                    return produce(state, draft => {
                        const found = _.find(draft.dates, o => {
                            return (
                                o.dateString === action.value.dateString
                            )
                        })

                        if (found) {
                            found.isCurrent = action.value.isCurrent
                        }
                    })
                }
                case 'UPDATE_IS_RENDERED' : {
                    return produce(state, draft => {
                        const found = _.find(draft.dates, o => {
                            return (
                                o.dateString === action.value.dateString
                            )
                        })

                        // console.log(action.value)
                        // console.log(current(found))

                        // only one isRendered value can be true in an array.
                        // so close all first.
                        _.forEach(draft.dates, (o) => {
                            _.forEach(o.arr, (p) => {
                                p.isRendered = false
                            })
                        })

                        if (found) {
                            const found2 = found.arr[action.value.arrIndex]

                            // console.log(current(found2))

                            if (found2) {
                                found2.isRendered = action.value.boolean
                            }
                        }
                    })
                }
                case 'SET_CURRENT_INDEX' : {
                    return produce(state, draft => {
                        draft.currentIndex = action.value
                    })
                }
                case 'UPDATE_FILTERS_STEP_TYPE': {
                    return produce(state, draft => {
                        draft.filters.stepType[action.value.key] = action.value.filterValue
                    })
                }
                case 'UPDATE_FILTERS_STEP_STATE': {
                    return produce(state, draft => {
                        draft.filters.stepState[action.value.key] = action.value.filterValue
                    })
                }
                case 'UPDATE_FILTERS_STEP_STATUS': {
                    return produce(state, draft => {
                        draft.filters.stepStatus[action.value.key] = action.value.filterValue
                    })
                }
                case 'REMOVE_FILTERS_STEP_TYPE': {
                    return produce(state, draft => {
                        _.forEach(draft.filters.stepType, (val, key, obj) => {
                            obj[key] = false
                        })
                    })
                }
                case 'REMOVE_FILTERS_STEP_STATE': {
                    return produce(state, draft => {
                        _.forEach(draft.filters.stepState, (val, key, obj) => {
                            obj[key] = false
                        })
                    })
                }
                case 'REMOVE_FILTERS_STEP_STATUS': {
                    return produce(state, draft => {
                        _.forEach(draft.filters.stepStatus, (val, key, obj) => {
                            obj[Number(key)] = false
                        })
                    })
                }
            }
        }, {
            dates: [],
            currentIndex: 0,
            filters: {
                stepType: {},
                stepState: {},
                stepStatus: {}
            }
        }
    )
    const getSurroundingElements = (index: number, array: ActionsInAppState['dates']) => {
        const start = Math.max(0, index - 3)
        const end = Math.min(array.length - 1, index + 3)
        return array.slice(start, end + 1)
    }

    const [weekDates, setWeekDates] = useState<{
        dateString: string;
        arr:(PatientActivityData & {isRendered: boolean})[];
        isCurrent?: boolean | undefined;
            }[]>([])

    useEffect(() => {
        setWeekDates(getSurroundingElements(
            actionsInAppState.currentIndex, actionsInAppState.dates
        ))
    }, [actionsInAppState.currentIndex, actionsInAppState.dates])

    const filterData = (
        data: (PatientActivityData)[],
        filters: {
            stepType: ActionsInAppState['filters']['stepType'],
            stepState: ActionsInAppState['filters']['stepState'],
            stepStatus: ActionsInAppState['filters']['stepStatus'],
        }
    ): (PatientActivityData)[] => {
        return data.filter((item) => {
            // Filter by step type
            const filterStepType = () => {
                const areAllStepTypesFalse = Object
                    .values(filters.stepType).every(value => value === false)

                if (areAllStepTypesFalse) {
                    return true
                } else {
                    return _.some(filters.stepType, (_val, key, o) => {
                        return o[key] && item.theStepType === key
                    })
                }
            }

            // Filter by step state
            const filterStepState = () => {
                const areAllStepStatesFalse = Object
                    .values(filters.stepState).every(value => value === false)

                if (areAllStepStatesFalse) {
                    return true
                } else {
                    return _.some(filters.stepState, (_val, key, o) => {
                        return o[key] && item.stepState === key
                    })
                }
            }

            // Filter by step status
            const filterStepStatus = () => {
                const areAllStepStatusFalse = Object
                    .values(filters.stepStatus).every(value => value === false)

                if (areAllStepStatusFalse) {
                    return true
                } else {
                    return _.some(filters.stepStatus, (_val, key, o) => {
                        return o[Number(key)] && item.stepStatus === Number(key)
                    })
                }
            }

            // Apply all filters
            return filterStepType() && filterStepState() && filterStepStatus()
        })
    }

    const goToToday = () => {
        // find a way to set the today date as the current index.

        // step 1: get today's date.
        const today = new Date()

        // now find element that matches today's date. month. day. year.
        const found = _.find(actionsInAppState.dates, (o) => {
            const isValid = fromUnixTime(o.arr[0]?.carePlanStep.doneWhen || 0)

            return isSameDay(isValid, today)
        })

        // for the gradient to mark that it's today. not to be confused
        // with the date range logic that current index is for.
        actionsInAppDispatch({
            type: 'SET_IS_GROUP_CURRENT',
            value: {
                dateString: found?.dateString || '',
                isCurrent: true
            }
        })

        const dateStrings = _.map(actionsInAppState.dates, (o) => {
            return parse(o.dateString, 'MM-dd-yyyy', new Date())
        })

        const closestDate = dateStrings.find(dateString => isSameDay(dateString, today)) ||
         closestTo(today, dateStrings)
        // console.log('Closest date to today is: ', closestDate)

        if (closestDate) {
            const closestIndex = dateStrings.findIndex(dateString =>
                isSameDay(dateString, closestDate))
            // console.log('Index is: ', closestIndex)

            actionsInAppDispatch({
                type: 'SET_CURRENT_INDEX',
                // just set the middle.
                value: closestIndex
            })
        } else {
            // console.log('closest date is undefined.')
        }
    }

    // clicking on task card goes back to today. avoid it please.
    // don't go to today unless you have to.
    useEffect(() => {
        // goToToday()
    }, [actionsInAppState.dates])

    useEffect(() => {
        // will refresh on every response retrieved.
        // but so will any filter options.

        // before grouping, filter results.
        const filtered = filterData(patientActivityData, actionsInAppState.filters)

        // then sort all by date.
        const sorted = _.orderBy(filtered, o => o.carePlanStep.doneWhen, 'asc')

        // make sure they all have a new property named isRendered
        const mapped = _.map(sorted, (o) => {
            return {
                ...o,
                isRendered: false
            }
        })

        // step 1: map the result.
        const result = _.groupBy(mapped, (o) => {
            const date = fromUnixTime(o.carePlanStep?.doneWhen || 0)
            // format date here.
            return format(date, 'MM-dd-yyyy')
        })

        const test: {dateString: string,
             arr: (PatientActivityData & {isRendered: boolean})[], isCurrent?: boolean}[] = []

        _.forEach(_.entries(result), (o) => {
            test.push({
                dateString: o[0],
                arr: o[1]
            })
        })

        // then set.
        actionsInAppDispatch({
            type: 'SET_GROUPED_DATA',
            value: test
        })

        goToToday()
    }, [patientActivityData, actionsInAppState.filters])

    useEffect(() => {
        // console.log('update filters: ', actionsInAppState.filters)
    }, [actionsInAppState.filters])

    // useEffect(() => {
    //     console.log('weekdates is', weekDates)
    //     console.log('weekdates index is', actionsInAppState.currentIndex)
    // }, [weekDates])

    const header = <div className={'px-8 row align-items-center justify-content-between mb-4'}
        ref={rootRef}>
        <div className={'col-auto'}>
            <div className={'align-items-baseline gx-2 row'}>
                <div className={'col-auto'}>
                    <h5 className={'mb-0 fw-semibold '} >
                        {strings.doc?.text.patient.actions_in_app.title}
                    </h5>
                </div>
                <div className={'col-auto'}>
                    {/* date range label is moved to here without the arrows */}
                    {weekDates.length
                        ? <span className={'fw-semibold'}>{
                            <TitleDateRange weekDates={weekDates}/>
                        }</span>
                        : ''}

                </div>
            </div>
        </div>
        <div className={'col-auto'}>
            <div className={'row align-items-center gx-3'}>
                <div className={'col-auto'} onClick={() => {
                    goToToday()
                }}>
                    <div className={'row align-items-center gx-2 clickable'}>

                        <div className={'col-auto'}>
                            <i className={'fa-light fa-calendar-arrow-up'}></i>
                        </div>
                        <div className={'col-auto'}>
                            <span className={'fw-light'}>
                                {strings.doc?.text.patient.patient_progress.today}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={'col-auto'}>
                    <button
                        type={'button'}
                        className={'btn btn-round btn-rounded btn-lg'}
                        onClick={() => {
                            setShowOffcanvas(true)
                        }}
                    >
                        <i
                            className={'fa-light fa-filter'}
                            aria-hidden={'true'}>
                        </i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    // multiple offcanvases might need to be created here.

    // this doesn't need to be a table anymore. use flexbox instead.

    // const recordMenuRef = useRef<HTMLDivElement | null>()
    // const { events } = useDraggable(
    //     recordMenuRef as unknown as React.MutableRefObject<HTMLElement>
    // )

    // so that the cards will have 0 and then the value again
    // ideally,i want to render the progressbar when the task card is shown
    // and then rendered again when hidden and shown again.

    const isLoadingContent = <small className={'d-block text-center py-2'}>
        <div className={'spinner-container'}>
            <span className={'spinner-border spinner-border-sm'}></span>
            <span className={'ms-2'}>{
                strings.app?.text.loading || ''
            }</span>
        </div>
    </small>

    const taskStatusContent = useMemo(() => {
        return <div className={'row flex-nowrap gx-0'}>
            <div className={'col-auto task-cell-container'}>
                <div className={'task-header'}><br></br></div>
                <div className={'px-3 py-16 task-body'}>
                    <InformationPopover
                        boundary={floatingUiBoundary}
                        text={strings.doc?.text
                            .patient.patient_progress.move_left}
                        element={
                            <i className={[
                                'fa-solid fa-chevron-left clickable',
                                actionsInAppState.currentIndex <= 3 ? 'd-none' : ''
                            ].join(' ')}
                            onClick={() => {
                                if (!(actionsInAppState.currentIndex <= 3)) {
                                    const newIndexLeft = actionsInAppState.currentIndex > 0
                                        ? actionsInAppState.currentIndex - 1
                                        : 0

                                    actionsInAppDispatch({
                                        type: 'SET_CURRENT_INDEX',
                                        value: newIndexLeft
                                    })
                                }
                            }}
                            ></i>
                        }
                    />
                </div>
            </div>
            {
                _.map(weekDates, (o) => {
                    const key = o.dateString

                    const parsed = parse(o.dateString, 'MM-dd-yyyy', new Date())
                    const formatted = format(parsed, 'EEE dd MMM')

                    return <div key={key} className={[
                        'col task-cell-container',
                        o.isCurrent ? 'current' : ''
                    ].join(' ')}>
                        <div className={'task-header'}><span>{
                            formatted
                        }</span></div>
                        {
                            <div className={'task-body'}>
                                {
                                    _.map(o.arr, (p, index) => {
                                        return <TaskCard
                                            floatingUiBoundary={floatingUiBoundary}
                                            p={p}
                                            innerIndex={index}
                                            dateString={key}
                                            key={ `task-card-${ p.careplanStepId }`}
                                            actionsInAppDispatch={actionsInAppDispatch}
                                        />
                                    })
                                }
                            </div>
                        }
                    </div>
                })
            }

            <div className={'col-auto task-cell-container'}>
                <div className={'task-header'}><br></br></div>
                <div className={'px-3 py-16 task-body'}>
                    <InformationPopover
                        boundary={floatingUiBoundary}
                        text={strings.doc?.text
                            .patient.patient_progress.move_right}
                        element={
                            <i className={[
                                'fa-solid fa-chevron-right clickable',
                                actionsInAppState.currentIndex + 3 === actionsInAppState
                                    .dates.length - 1
                                    ? 'd-none'
                                    : ''
                            ].join(' ')}
                            onClick={() => {
                                if (!(actionsInAppState.currentIndex + 3 === actionsInAppState
                                    .dates.length - 1)) {
                                    const maximumIndex = actionsInAppState
                                        .dates.length - 1

                                    const newIndexRight = actionsInAppState.currentIndex <
                                    maximumIndex
                                        ? actionsInAppState.currentIndex + 1
                                        : maximumIndex

                                    actionsInAppDispatch({
                                        type: 'SET_CURRENT_INDEX',
                                        value: newIndexRight
                                    })
                                }
                            }}
                            ></i>
                        }
                    />
                </div>
            </div>
        </div>
    }, [
        strings,
        actionsInAppState,
        weekDates
    ])

    const isEmpty = <small className={'d-block text-center py-2'}>
        <span >{
            strings.app?.message.error.empty_table || ''
        }</span>
    </small>

    const taskStatusChecker = <div
        // ref={(e) => {
        //     recordMenuRef.current = e
        // } }
        className={'container-fluid task-status-checker px-0'}
        // { ...events}
    >
        {
            getPatientActivityMutation.isLoading
                ? isLoadingContent
                : patientActivityData.length
                    ? taskStatusContent
                    : getPatientActivityMutation.isSuccess && isEmpty }

    </div>

    const charts = <div className={'container-fluid px-0'}>
        <div className={'my-5 row g-3'}>
            {
                _.map(
                    _.orderBy(patientChartData, 'sortOrder', 'asc'),
                    (obj, index) => {
                        const key = `chart-main-${ obj.graphId }-index`

                        return <div key={key} className={[
                            obj.colSpan
                        ].join(' ')}>
                            <HealthRelatedChartInterface level={'main'} generalChartInfo={obj}
                                floatingUiBoundary={floatingUiBoundary}
                            />
                        </div>
                    })
            }
        </div>
    </div>

    return <>
        <div className={'course-interface px-0'}>
            {header}
            {taskStatusChecker}
            {<OffCanvas
                patientActivityData={patientActivityData}
                actionsInAppState={actionsInAppState}
                actionsInAppDispatch={actionsInAppDispatch}
                rootRef={rootRef}
                setShowOffcanvas={setShowOffcanvas}
                showOffcanvas={showOffcanvas}
            />}
        </div>
        {charts}

    </>
}

export default ActionsInApp
