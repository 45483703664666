import { useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'

import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { selectToken } from '@app/slices/slice.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import {
    CAREPROFESSIONAL_FORMIK_INITIAL_VALUES,
    CAREPROFESSIONAL_VALIDATION_SCHEMA,
    MODULE_VERSION
} from '@fmt/constants'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'

import { useAddCareProfessionalMutation } from '@fmt/api'
import DashboardResponsiveHeader from '@fmt/components/DashboardResponsiveHeader'
import DeptLocation from '@fmt/components/careprofessionals/details/inputs/DeptLocation'
import Email from '@fmt/components/careprofessionals/details/inputs/Email'
import CareProfessionalName from '@fmt/components/careprofessionals/details/inputs/Name'
import PhoneNumber from '@fmt/components/careprofessionals/details/inputs/PhoneNumber'
import Profession from '@fmt/components/careprofessionals/details/inputs/Profession'
import { back, push } from '@lagunovsky/redux-react-router'

import { CareProfessionalKeys } from '@fmt/type'
import _ from 'lodash'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import AddSystemUser from '@fmt/components/careprofessionals/details/inputs/AddSystemUser'

const CareprofessionalAddInterface = () => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)

    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()
    const validateRoute = useValidateRoute()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()

    // upload profile picture logic.
    const [showCancelWarning, setShowCancelWarning] = useState<boolean>(false)

    const [addCareProfessional, addCareProfessionalMutation] = useAddCareProfessionalMutation()

    const careprofessionalAddFormik = useFormik({
        initialValues:
         CAREPROFESSIONAL_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CAREPROFESSIONAL_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || '',
            strings.app?.message.error.email || ''
        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken(
                        {
                            value: token.value,
                            id: token.id
                        },
                        token.mode
                    )
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths.addCareProfessional.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        addCareProfessional({
                            authToken: newToken.value,
                            data: {
                                careProfessionalName: values.careProfessionalName || '',
                                careProfessionalPhoneNumber:
                                values.careProfessionalPhoneNumber || '',
                                careProfessionalEmail: values.careProfessionalEmail || '',
                                careProfessionalLocation: values.careProfessionalLocation || '',
                                careProfessionalProfession: values.careProfessionalProfession || '',
                                addSystemUser: values.addSystemUser || false
                            }
                        })
                    }
                }
            }
            call()
        }
    })

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    useEffect(() => {
        const data = addCareProfessionalMutation.data

        if (data) {
            if (data.status === 'OK') {
                // close component by clearing the add formik.
                // careprofessionalAddFormik.resetForm()
                // redirect user to edit page please.
                const isValid = validateRoute(
                    activeModules.arr,
                    MODULE_TABLE.fmt.moduleName,
                    MODULE_TABLE.fmt.routes.editCareprofessional,
                    true
                )

                dispatch(push(
                    _.replace(isValid.route,
                        ':careprofessionalId',
                        data.data.careProfessionalId || ''
                    )
                ))
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [addCareProfessionalMutation.data])

    useEffect(() => {
        if (addCareProfessionalMutation.error) {
            const message = getErrorText(addCareProfessionalMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [addCareProfessionalMutation.error])

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: CareProfessionalKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                careprofessionalAddFormik.handleSubmit()
            }
        }
    }

    const NameInput = useMemo(() => {
        return <CareProfessionalName
            readOnly={false}
            careprofessionalEditFormik={careprofessionalAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'careProfessionalProfession')
            }}
        />
    }, [

        strings,
        careprofessionalAddFormik.values.careProfessionalName,
        careprofessionalAddFormik.errors.careProfessionalName
    ])

    const ProfessionInput = useMemo(() => {
        return <Profession
            readOnly={false}
            careprofessionalEditFormik={careprofessionalAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'careProfessionalLocation')
            }}
        />
    }, [

        strings,
        careprofessionalAddFormik.values.careProfessionalProfession,
        careprofessionalAddFormik.errors.careProfessionalProfession
    ])

    const LocationInput = useMemo(() => {
        return <DeptLocation
            readOnly={false}
            careprofessionalEditFormik={careprofessionalAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'careProfessionalPhoneNumber')
            }}
        />
    }, [

        strings,
        careprofessionalAddFormik.values.careProfessionalLocation,
        careprofessionalAddFormik.errors.careProfessionalLocation
    ])

    const PhoneNumberInput = useMemo(() => {
        return <PhoneNumber
            readOnly={false}
            careprofessionalEditFormik={careprofessionalAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'careProfessionalEmail')
            }}
        />
    }, [

        strings,
        careprofessionalAddFormik.values.careProfessionalPhoneNumber,
        careprofessionalAddFormik.errors.careProfessionalPhoneNumber
    ])

    const EmailInput = useMemo(() => {
        return <Email
            readOnly={false}
            careprofessionalEditFormik={careprofessionalAddFormik}
            handleKeyDown={(e) => {
                if (e.key === 'Enter') {
                    // don't submit because you need to toggle the switch.
                }
            }}
        />
    }, [

        strings,
        careprofessionalAddFormik.values.careProfessionalEmail,
        careprofessionalAddFormik.errors.careProfessionalEmail
    ])

    const AddSystemUserInput = useMemo(() => {
        return <AddSystemUser
            careprofessionalEditFormik={careprofessionalAddFormik}
        />
    }, [

        strings,
        careprofessionalAddFormik.values.addSystemUser,
        careprofessionalAddFormik.errors.addSystemUser
    ])

    const CancelButton = <button type={'button'}
        className={[
            'btn btn-secondary w-100 py-2'
        ].join(' ')}
        onClick={() => {
            // if you made changes to the formik, show a warning first via popup
            const obj = careprofessionalAddFormik.touched

            interface FormikTouched {
                [key: string]: boolean;
              }

            function hasTrueValue (obj: FormikTouched) {
                for (const key in obj) {
                    if (obj[key] === true) {
                        return true
                    }
                }
                return false
            }

            const hasTrue = hasTrueValue(obj)
            if (hasTrue) {
                // show warning pls
                setShowCancelWarning(true)
            } else {
                // reset formik.
                careprofessionalAddFormik.resetForm()
            }
        }}>
        {strings.app?.text.cancel}
    </button>

    const LoadingContent = (
        <div className={'spinner-container'}>
            <span className={'spinner-border spinner-border-sm'}></span>
            <span className={'ms-2'}>{
                strings.app?.text.loading || ''
            }</span>
        </div>
    )

    const SubmitButton = <button type={'button'}
        disabled={
        // step 1 shouldn't be disabled here because of formik.
            false
        // step 2 can be skipped entirely.
        }
        className={[
            'btn btn-primary w-100 fw-semibold py-2'
        ].join(' ')}
        onClick={() => {
            careprofessionalAddFormik.handleSubmit()
        }}>
        { addCareProfessionalMutation.isLoading
            ? LoadingContent
            : strings.app?.text.save}
    </button>
    /** display list of careprofessionals here with the data table template. */

    const result = <div className={'row my-8'}>
        <div className={'col-12 col-lg'}>
            <div className={'add-interface'}>
                <h2 className={'mb-5 fw-semibold '} >{
                    strings.fmt?.text.department.details.careprofessional}</h2>
                {/* group one */}
                <div className={'mb-6'}>
                    <h4 className={'mb-6'}>{strings.fmt?.text.facility.add.steps['3'].details}</h4>
                    {NameInput}
                    {ProfessionInput}
                    {LocationInput}
                    {PhoneNumberInput}
                    {EmailInput}
                    {AddSystemUserInput}
                </div>

                <div className={'container'}>
                    <div className={'row align-items-center mt-6'}>
                        <div className={'col-6 col-lg-4 col-md-6 offset-lg-4 offset-md-0'}>
                            {CancelButton}
                        </div>
                        <div className={'col-6 col-lg-4 col-md-6'}>
                            {SubmitButton}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    return <div className={'careprofessional-page'}>

        {/* dashboard responsive header */}
        <DashboardResponsiveHeader />

        <div className={'main-content'}>

            <div className={'container-fluid mx-auto'}>
                <div className={'row justify-content-between pt-16 pb-0 px-5'}>
                    <div className={'col-auto'}>
                        <a onClick={(e) => {
                            e.preventDefault()
                            dispatch(back())
                        }} className={'btn btn-round btn-sm-md'}>
                            <i
                                className={'fa-light fa-arrow-left'}
                                aria-hidden={'true'}>
                            </i>
                        </a>
                    </div>
                    <div className={'col-auto'}>
                        <h1 className={'my-0'}>{strings.fmt?.text
                            .department.details.careprofessional}</h1>
                    </div>
                    <div className={'col-auto'}>
                        <a href={'#'}
                            onClick={(e) => {
                                e.preventDefault()
                            }} className={[
                                'btn btn-round btn-sm-md invisible'
                            ].join(' ')}>
                            <i
                                className={'fa-light fa-arrow-right'}
                                aria-hidden={'true'}
                            >
                            </i>
                        </a>
                    </div>
                </div>
                <div className={'row justify-content-between mt-5'}>
                    <div className={'col'}>
                        <div className={
                            'container-fluid px-5 px-sm-3 careprofessional-body list-interface'
                        }>
                            <div className={'h-100 row'}>
                                <div className={' col-12 mx-auto'}>
                                    {result}
                                </div>
                            </div>
                        </div>

                        <Modal centered={true} isOpen={showCancelWarning} toggle={() => {
                            setShowCancelWarning(!showCancelWarning)
                        }}>
                            <ModalHeader className={'justify-content-between'} toggle={() => {
                                setShowCancelWarning(!showCancelWarning)
                            }} close={ <a className={'btn btn-round '}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowCancelWarning(false)
                                }}>
                                <i className={'fa-light fa-multiply'} aria-hidden={'true'}>
                                </i>
                            </a>}
                            >
                                <span className={'h5'}>{
                                    strings.fmt?.message?.data_edit_warning?.title || ''
                                }</span>
                            </ModalHeader>
                            <ModalBody>
                                {/* content changes if idle / expire sessions expired. */}
                                <small className={'d-block my-4'}>
                                    {strings.fmt?.message?.data_edit_warning?.content || ''}
                                </small>
                                <div className={'row justify-content-end'}>
                                    <div className={'col-auto mb-2 mb-md-0'}>
                                        <button
                                            type={'button'}
                                            className={'btn btn-primary w-100'}
                                            onClick={ () => {
                                                setShowCancelWarning(false)
                                            }}>
                                            {strings.app?.text?.no || ''}
                                        </button>
                                    </div>
                                    <div className={'col-auto'}>
                                        <button type={'button'} onClick={() => {
                                            // reset formik.
                                            careprofessionalAddFormik.resetForm()
                                            setShowCancelWarning(false)
                                        }} className={'btn btn-primary w-100'}>
                                            {strings.app?.text.yes}
                                        </button>

                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    </div>
                </div>

            </div>

        </div>

        <div
            className={'position-fixed bottom-0 end-0 pe-5 fs-label fw-light version-text'}
        >
            {MODULE_VERSION}
        </div>
    </div>
}

export default CareprofessionalAddInterface
