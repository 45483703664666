import { useEffect, useMemo, useState } from 'react'

import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'

import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { selectToken } from '@app/slices/slice.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { useAddCareProfessionalMutation, useAddDepartmentCareProfessionalMutation } from '@fmt/api'
import DeptLocation from '@fmt/components/careprofessionals/details/inputs/DeptLocation'
import Email from '@fmt/components/careprofessionals/details/inputs/Email'
import CareProfessionalName from '@fmt/components/careprofessionals/details/inputs/Name'
import PhoneNumber from '@fmt/components/careprofessionals/details/inputs/PhoneNumber'
import Profession from '@fmt/components/careprofessionals/details/inputs/Profession'
import {
    CAREPROFESSIONAL_FORMIK_INITIAL_VALUES,
    CAREPROFESSIONAL_VALIDATION_SCHEMA
} from '@fmt/constants'

import AddSystemUser from '@fmt/components/careprofessionals/details/inputs/AddSystemUser'
import { CareProfessionalKeys, Department } from '@fmt/type'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

interface ComponentProps {
    department: Department,
    setDropdownsUpdateTrigger: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

const QuickAddCareprofessional = ({
    department,
    setDropdownsUpdateTrigger
}:ComponentProps) => {
    const strings = useAppSelector(selectStrings)

    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()

    const [showCancelWarning, setShowCancelWarning] = useState<boolean>(false)
    const [
        showAddCareprofessionalInterface,
        setShowAddCareprofessionalInterface
    ] = useState(false)

    const [submissionFlag, setSubmissionFlag] = useState<
    'SAVE_AND_CLOSE' | 'SAVE_AND_ADD_ANOTHER' | ''
    >('')
    const [addCareprofessional, addCareprofessionalMutation] = useAddCareProfessionalMutation()
    const [
        addDepartmentCareprofessional
    ] = useAddDepartmentCareProfessionalMutation({})

    const careprofessionalAddFormik = useFormik({
        initialValues: CAREPROFESSIONAL_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CAREPROFESSIONAL_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || '',
            strings.app?.message.error.email || ''

        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken(
                        {
                            value: token.value,
                            id: token.id
                        },
                        token.mode
                    )
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths.addCareProfessional.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        addCareprofessional({
                            authToken: newToken.value,
                            data: {
                                careProfessionalName: values.careProfessionalName || '',
                                careProfessionalPhoneNumber:
                                values.careProfessionalPhoneNumber || '',
                                careProfessionalEmail: values.careProfessionalEmail || '',
                                careProfessionalLocation: values.careProfessionalLocation || '',
                                careProfessionalProfession: values.careProfessionalProfession || '',
                                addSystemUser: values.addSystemUser || false

                            }
                        })
                    }
                }
            }
            call()
        }
    })

    useEffect(() => {
        const data = addCareprofessionalMutation.data

        if (data) {
            if (data.status === 'OK') {
                const foundApiPath = validateAPIPath(
                    activeModules.arr,
                    MODULE_TABLE.fmt.moduleName,
                    MODULE_TABLE.fmt.apiPaths.addDepartmentCareProfessional.path,
                    true
                )
                if (foundApiPath) {
                    addDepartmentCareprofessional({
                        authToken: token.value,
                        data: {
                            careProfessionalId: data.data.careProfessionalId || '',
                            departmentId: department.departmentId
                        }
                    }).unwrap().then((data) => {
                        if (data.status === 'OK') {
                            //
                            setDropdownsUpdateTrigger(true)

                            // then check submission flag and change back to ''
                            if (submissionFlag === 'SAVE_AND_CLOSE') {
                                // careprofessionalAddFormik.resetForm()
                                careprofessionalAddFormik.setFieldValue('careProfessionalName', '')

                                // then hide the form.
                                setShowAddCareprofessionalInterface(false)
                                setSubmissionFlag('')
                            } else if (submissionFlag === 'SAVE_AND_ADD_ANOTHER') {
                                // careprofessionalAddFormik.resetForm()
                                careprofessionalAddFormik.setFieldValue('careProfessionalName', '')

                                setSubmissionFlag('')
                            }
                        } else {
                            toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                        }
                    })
                }
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [addCareprofessionalMutation.data])

    useEffect(() => {
        if (addCareprofessionalMutation.error) {
            const message = getErrorText(addCareprofessionalMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [addCareprofessionalMutation.error])

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: CareProfessionalKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                careprofessionalAddFormik.handleSubmit()
            }
        }
    }

    const NameInput = useMemo(() => {
        return <CareProfessionalName
            readOnly={false}
            careprofessionalEditFormik={careprofessionalAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'careProfessionalProfession')
            }}
        />
    }, [

        strings,
        careprofessionalAddFormik.values.careProfessionalName,
        careprofessionalAddFormik.errors.careProfessionalName
    ])

    const ProfessionInput = useMemo(() => {
        return <Profession
            readOnly={false}
            careprofessionalEditFormik={careprofessionalAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'careProfessionalLocation')
            }}
        />
    }, [

        strings,
        careprofessionalAddFormik.values.careProfessionalProfession,
        careprofessionalAddFormik.errors.careProfessionalProfession
    ])

    const LocationInput = useMemo(() => {
        return <DeptLocation
            readOnly={false}
            careprofessionalEditFormik={careprofessionalAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'careProfessionalPhoneNumber')
            }}
        />
    }, [

        strings,
        careprofessionalAddFormik.values.careProfessionalLocation,
        careprofessionalAddFormik.errors.careProfessionalLocation
    ])

    const PhoneNumberInput = useMemo(() => {
        return <PhoneNumber
            readOnly={false}
            careprofessionalEditFormik={careprofessionalAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'careProfessionalEmail')
            }}
        />
    }, [

        strings,
        careprofessionalAddFormik.values.careProfessionalPhoneNumber,
        careprofessionalAddFormik.errors.careProfessionalPhoneNumber
    ])

    const EmailInput = useMemo(() => {
        return <Email
            readOnly={false}
            careprofessionalEditFormik={careprofessionalAddFormik}
            handleKeyDown={(e) => {
                // if (e.key === 'Enter') { careprofessionalAddFormik.handleSubmit() }
            }}
        />
    }, [

        strings,
        careprofessionalAddFormik.values.careProfessionalEmail,
        careprofessionalAddFormik.errors.careProfessionalEmail
    ])

    const AddSystemUserInput = useMemo(() => {
        return <AddSystemUser
            careprofessionalEditFormik={careprofessionalAddFormik}
        />
    }, [

        strings,
        careprofessionalAddFormik.values.addSystemUser,
        careprofessionalAddFormik.errors.addSystemUser
    ])

    const CancelButton = <button type={'button'}
        className={[
            'btn btn-secondary w-100 py-2'
        ].join(' ')}
        onClick={() => {
            // if you made changes to the formik, show a warning first via popup
            const obj = careprofessionalAddFormik.touched

            interface FormikTouched {
                [key: string]: boolean;
              }

            function hasTrueValue (obj: FormikTouched) {
                for (const key in obj) {
                    if (obj[key] === true) {
                        return true
                    }
                }
                return false
            }

            const hasTrue = hasTrueValue(obj)
            if (hasTrue) {
                // show warning pls
                setShowCancelWarning(true)
            } else {
                // reset formik.
                careprofessionalAddFormik.resetForm()
                setShowAddCareprofessionalInterface(false)
            }
        }}>
        {strings.app?.text.cancel}
    </button>
    const LoadingContent = (
        <div className={'spinner-container'}>
            <span className={'spinner-border spinner-border-sm'}></span>
            <span className={'ms-2'}>{
                strings.app?.text.loading || ''
            }</span>
        </div>
    )
    const SaveAndCloseButton = <button type={'button'}
        disabled={
            // step 1 shouldn't be disabled here because of formik.
            false
            // step 2 can be skipped entirely.
        }
        className={[
            'btn btn-primary w-100 fw-semibold py-2'
        ].join(' ')}
        onClick={() => {
            careprofessionalAddFormik.handleSubmit()
            setSubmissionFlag('SAVE_AND_CLOSE')
        }}>
        { addCareprofessionalMutation.isLoading
            ? LoadingContent
            : strings.app?.text.save_and_close}
    </button>

    const SaveAndAddAnotherButton = <button type={'button'}
        disabled={
            // step 1 shouldn't be disabled here because of formik.
            false
            // step 2 can be skipped entirely.
        }
        className={[
            'btn btn-primary w-100 fw-semibold py-2'
        ].join(' ')}
        onClick={() => {
            careprofessionalAddFormik.handleSubmit()
            setSubmissionFlag('SAVE_AND_ADD_ANOTHER')
        }}>
        { addCareprofessionalMutation.isLoading
            ? LoadingContent
            : strings.fmt?.text.facility.add.steps['2'].save_and_add_more}
    </button>

    const result = <div className={'card mt-3 p-5'}>
        <h2 className={'mb-5 fw-semibold '} >{strings.fmt
            ?.text.facility.add.steps['3'].careprofessional_data}</h2>
        {/* group one */}
        <div className={'mb-6'}>
            <h4 className={'mb-6'}>{strings.fmt
                ?.text.facility.add.steps['3'].details}</h4>
            {NameInput}
            {ProfessionInput}
            {LocationInput}
            {PhoneNumberInput}
            {EmailInput}
            {AddSystemUserInput}
        </div>

        <div className={'container'}>
            <div className={'row align-items-center mt-6'}>
                <div className={'col-4 col-lg-3 col-md-4 offset-lg-3 offset-md-0'}>
                    {CancelButton}
                </div>
                <div className={'col-4 col-lg-3 col-md-4 offset-md-0'}>
                    {SaveAndCloseButton}
                </div>
                <div className={'col-4 col-lg-3 col-md-4'}>
                    {SaveAndAddAnotherButton}
                </div>
            </div>
        </div>

        <Modal centered={true} isOpen={showCancelWarning} toggle={() => {
            setShowCancelWarning(!showCancelWarning)
        }}>
            <ModalHeader className={'justify-content-between'} toggle={() => {
                setShowCancelWarning(!showCancelWarning)
            }} close={ <a className={'btn btn-round '}
                onClick={(e) => {
                    e.preventDefault()
                    setShowCancelWarning(false)
                }}>
                <i className={'fa-light fa-multiply'} aria-hidden={'true'}>
                </i>
            </a>}
            >
                <span className={'h5'}>{
                    strings.fmt?.message?.data_edit_warning?.title || ''
                }</span>
            </ModalHeader>
            <ModalBody>
                {/* content changes if idle / expire sessions expired. */}
                <small className={'d-block my-4'}>
                    {strings.fmt?.message?.data_edit_warning?.content || ''}
                </small>
                <div className={'row justify-content-end'}>
                    <div className={'col-auto mb-2 mb-md-0'}>
                        <button
                            type={'button'}
                            className={'btn btn-primary w-100'}
                            onClick={ () => {
                                setShowCancelWarning(false)
                            }}>
                            {strings.app?.text?.no || ''}
                        </button>
                    </div>
                    <div className={'col-auto'}>
                        <button type={'button'} onClick={() => {
                        // reset formik.
                            careprofessionalAddFormik.resetForm()
                            setShowCancelWarning(false)
                            // hide after.
                            setShowAddCareprofessionalInterface(false)
                        }} className={'btn btn-primary w-100'}>
                            {strings.app?.text.yes}
                        </button>

                    </div>
                </div>
            </ModalBody>
        </Modal>
    </div>

    return <>
        <div className={'row mt-6'}>
            <div className={
                'col-12 d-flex justify-content-end align-items-center'
            }>
                <div className={[
                    'rounded-square d-flex me-5 btn ',
                    'justify-content-center align-items-center'
                ].join(' ')} onClick={() => {
                    // show department interface
                    setShowAddCareprofessionalInterface(
                        !showAddCareprofessionalInterface
                    )
                }}>
                    <i className={'fa-light fa-plus'}></i>
                </div>
                <h6 className={'mb-0 fw-semibold'}>{
                    strings.fmt?.text.facility.add.steps['3'].add_new_careprofessional
                }</h6>

            </div>
        </div>
        {showAddCareprofessionalInterface && result}
    </>
}

export default QuickAddCareprofessional
