import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { TokenData } from '@app/types/type.token'

import {
    useEditCareFacilityMutation,
    useGetCareFacilitiesMutation,
    useGetCareFacilityMutation
} from '@fmt/api'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import Address from '@fmt/components/facilities/details/inputs/Address'
import City from '@fmt/components/facilities/details/inputs/City'
import Email from '@fmt/components/facilities/details/inputs/Email'
import FacilityName from '@fmt/components/facilities/details/inputs/FacilityName'
import GeneralPhoneNumber from '@fmt/components/facilities/details/inputs/GeneralPhoneNumber'
import PostalCode from '@fmt/components/facilities/details/inputs/PostalCode'
import StreetName from '@fmt/components/facilities/details/inputs/StreetName'
import Website from '@fmt/components/facilities/details/inputs/Website'
import {
    FACILITY_STEP_ONE_FORMIK_INITIAL_VALUES,
    FACILITY_STEP_ONE_VALIDATION_SCHEMA
} from '@fmt/constants'
import { CareFacility, CareFacilityKeys } from '@fmt/type'
import { push } from '@lagunovsky/redux-react-router'
import { useFormik } from 'formik'
import _ from 'lodash'

const AccordionComponent = (
    { mode, isOpen, obj, fixedCacheKey }:
    { mode: 'EDIT' | 'VIEW', isOpen?: boolean, obj: CareFacility, fixedCacheKey:string}) => {
    const token = useAppSelector(selectToken)

    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const validateRoute = useValidateRoute()
    const dispatch = useAppDispatch()

    const [getCareFacility, getCareFacilityMutation] = useGetCareFacilityMutation()

    // accordion components are now editable.
    const [editCareFacility, editCareFacilityMutation] = useEditCareFacilityMutation()

    const [getCareFacilities] = useGetCareFacilitiesMutation({
        fixedCacheKey
    })

    const facilityEditFormik = useFormik({
        initialValues: FACILITY_STEP_ONE_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: FACILITY_STEP_ONE_VALIDATION_SCHEMA(
            strings.app?.message.error.email || '',
            strings.app?.message.error.empty || ''
        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken(
                        {
                            value: token.value,
                            id: token.id
                        },
                        token.mode
                    )
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths.editCareFacility.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        const data = {
                            facilityId: obj.facilityId,
                            facilityName: values.facilityName || '',
                            facilityAddressStreet: values.facilityAddressStreet,
                            facilityAddressNumber: values.facilityAddressNumber,
                            facilityAddressSuffix: values.facilityAddressSuffix || '',
                            facilityPostalcode: values.facilityPostalcode,
                            facilityCity: values.facilityCity,
                            facilityPhonenumber: values.facilityPhonenumber || '',
                            facilityEmailAddress: values.facilityEmailAddress || '',
                            facilityWebsite: values.facilityWebsite || ''
                        }

                        editCareFacility({
                            authToken: newToken.value,
                            data: {
                                ...data,
                                facilityId: obj.facilityId || ''
                            }
                        })
                    }
                }
            }
            call()
        }
    })

    useEffect(() => {
        const data = editCareFacilityMutation.data

        if (data) {
            if (data.status === 'OK') {
                // don't do anything.
                const isValid2 = validateAPIPath(
                    activeModules.arr,
                    MODULE_TABLE.fmt.moduleName,
                    MODULE_TABLE.fmt.apiPaths
                        .getCareFacilities.path,
                    true
                )

                if (isValid2) {
                    getCareFacilities({
                        authToken: token.value,
                        data: {
                        }
                    })
                }
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [editCareFacilityMutation.data])

    useEffect(() => {
        if (editCareFacilityMutation.error) {
            const message = getErrorText(editCareFacilityMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [editCareFacilityMutation.error])

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        let isMounted = true

        const call = async () => {
            if (
                token.valid &&
                isOpen === true &&
                getCareFacilityMutation.isSuccess === false
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths
                            .getCareFacility.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getCareFacility({
                            authToken: newToken.value,
                            data: {
                                facilityId: obj.facilityId
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
        }
    }

    // ONLY fetch it when obj.needAccordion is true AND if isSuccess is false.
    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid, isOpen, getCareFacilityMutation.isSuccess])

    useEffect(() => {
        if (getCareFacilityMutation.data) {
            const data = getCareFacilityMutation.data
            facilityEditFormik.setValues({
                ...facilityEditFormik.values,
                ...data.data
            })
        }
    }, [getCareFacilityMutation.data])

    useEffect(() => {
        if (getCareFacilityMutation.error) {
            const message = getErrorText(getCareFacilityMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getCareFacilityMutation.error])

    const ViewButton = <button
        type={'button'}
        className={'btn btn-secondary w-100'}
        onClick={(e) => {
            e.stopPropagation()

            const isValid = validateRoute(
                activeModules.arr,
                MODULE_TABLE.fmt.moduleName,
                MODULE_TABLE.fmt.routes.viewCareFacility,
                true
            )

            dispatch(push(
                _.replace(isValid.route,
                    ':careFacilityId',
                    obj.facilityId
                )
            ))
        }}
    >
        {strings.fmt?.text.facility.menu.view_facility}
    </button>
    const LoadingContent = (
        <div className={'spinner-container'}>
            <span className={'spinner-border spinner-border-sm'}></span>
            <span className={'ms-2'}>{
                strings.app?.text.loading || ''
            }</span>
        </div>
    )
    const SubmitButton = <button type={'button'}
        disabled={
        // step 1 shouldn't be disabled here because of formik.
            false
        // step 2 can be skipped entirely.
        }
        className={[
            'btn btn-primary w-100 px-8'
        ].join(' ')}
        onClick={() => {
            facilityEditFormik.handleSubmit()
        }}>
        { editCareFacilityMutation.isLoading
            ? LoadingContent
            : strings.app?.text.save}
    </button>

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: CareFacilityKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                facilityEditFormik.handleSubmit()
            }
        }
    }

    const FacilityNameInput = useMemo(() => {
        return <FacilityName
            readOnly={mode === 'VIEW'}
            facilityEditFormik={facilityEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'facilityPostalcode')
            }}
        />
    }, [
        mode,
        strings,
        facilityEditFormik.values.facilityName,
        facilityEditFormik.errors.facilityName
    ])

    const PostalCodeInput = useMemo(() => {
        return <PostalCode readOnly={mode === 'VIEW'}
            facilityEditFormik={facilityEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'facilityAddressNumber')
            }} />
    }, [
        mode,
        strings,
        facilityEditFormik.values.facilityPostalcode,
        facilityEditFormik.errors.facilityPostalcode
    ])

    const AddressInput = useMemo(() => {
        return <Address readOnly={mode === 'VIEW'}
            facilityEditFormik={facilityEditFormik}
            handleKeyDown={handleKeyDown} />
    }, [
        mode,
        strings,
        facilityEditFormik.values.facilityAddressNumber,
        facilityEditFormik.errors.facilityAddressNumber,
        facilityEditFormik.values.facilityAddressSuffix,
        facilityEditFormik.errors.facilityAddressSuffix
    ])

    const StreetNameInput = useMemo(() => {
        return <StreetName readOnly={mode === 'VIEW'}
            facilityEditFormik={facilityEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'facilityCity')
            }} />
    }, [
        mode,
        strings,
        facilityEditFormik.values.facilityAddressStreet,
        facilityEditFormik.errors.facilityAddressStreet
    ])

    const CityInput = useMemo(() => {
        return <City readOnly={mode === 'VIEW'}
            facilityEditFormik={facilityEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'facilityPhonenumber')
            }} />
    }, [
        mode,
        strings,
        facilityEditFormik.values.facilityCity,
        facilityEditFormik.errors.facilityCity
    ])

    /** GROUP 2 */

    const GeneralPhoneNumberInput = useMemo(() => {
        return <GeneralPhoneNumber
            readOnly={mode === 'VIEW'}
            facilityEditFormik={facilityEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'facilityEmailAddress')
            }} />
    }, [
        mode,
        strings,
        facilityEditFormik.values.facilityPhonenumber,
        facilityEditFormik.errors.facilityPhonenumber
    ])

    const EmailInput = useMemo(() => {
        return <Email readOnly={mode === 'VIEW'}
            facilityEditFormik={facilityEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'facilityWebsite')
            }} />
    }, [
        mode,
        strings,
        facilityEditFormik.values.facilityEmailAddress,
        facilityEditFormik.errors.facilityEmailAddress
    ])

    const WebsiteInput = useMemo(() => {
        return <Website readOnly={mode === 'VIEW'}
            facilityEditFormik={facilityEditFormik}
            handleKeyDown={(e) => {
                if (e.key === 'Enter') { facilityEditFormik.handleSubmit() }
            }} />
    }, [
        mode,
        strings,
        facilityEditFormik.values.facilityWebsite,
        facilityEditFormik.errors.facilityWebsite
    ])

    return <>
        {
            getCareFacilityMutation.isLoading && <small className={'d-block text-center py-2'}>
                <div className={'spinner-container'}>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{
                        strings.app?.text.loading || ''
                    }</span>
                </div>
            </small>
        }

        {getCareFacilityMutation.isSuccess && <div className={'px-16'}>
            <h5 className={'col mb-6'}>{strings.fmt?.text.facility.menu.facility_data}</h5>
            <div className={'mb-6'}>
                <h6 className={'mb-6'}>{strings.fmt
                    ?.text.facility.menu.name_and_address_details}</h6>
                {FacilityNameInput}
                {PostalCodeInput}
                {AddressInput}
                {StreetNameInput}
                {CityInput}
            </div>
            {/* group two */}

            <div>
                <h6 className={'mb-6'}>{strings.fmt
                    ?.text.facility.menu.contact_details}</h6>
                {GeneralPhoneNumberInput}
                {EmailInput}
                {WebsiteInput}
            </div>

            <div className={'row mt-3 align-items-center justify-content-end'}>
                <div className={'col-auto'}>
                    <span>
                        {ViewButton}
                    </span>
                </div>
                <div className={'col-auto'}>
                    {SubmitButton}
                </div>
            </div>
        </div>}
    </>
}

export default AccordionComponent
