import { MODULE_TABLE } from '@app/app.config'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import Overlay from '@app/components/Overlay'
import Pagination from '@app/components/Pagination'
import { selectActiveModules, selectDateFormats, selectStrings } from '@app/slices/slice.app'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import { CAREPROF_TABLE_LIMIT } from '@doc/constants'
import {
    AbnormalitiesActions,
    AbnormalitiesState,
    GetOverallAbnormalitiesResponse
} from '@doc/type'
import { push } from '@lagunovsky/redux-react-router'
import { format, fromUnixTime } from 'date-fns'
import _ from 'lodash'
import React from 'react'
import { DebouncedState, useDebouncedCallback } from 'use-debounce'

interface ComponentProps {
    debouncedFetchAbnormalities: DebouncedState<() => void>
    showOffcanvas: boolean
    setShowOffcanvas: React.Dispatch<React.SetStateAction<boolean>>
    rootRef: React.MutableRefObject<HTMLDivElement | null>
    getOverallAbnormalitiesResponse: {
        isLoading: boolean;
        isSuccess: boolean;
        response: GetOverallAbnormalitiesResponse | undefined;
        abnormalitiesState: AbnormalitiesState
        abnormalitiesDispatch: React.Dispatch<AbnormalitiesActions>

    }
}

// It gets a list of abnormalities reported by the system in the last 7 days
const AbnormalRecovery = ({
    debouncedFetchAbnormalities,
    showOffcanvas,
    setShowOffcanvas,
    rootRef,
    getOverallAbnormalitiesResponse
}: ComponentProps) => {
    const validateRoute = useValidateRoute()
    const activeModules = useAppSelector(selectActiveModules)
    const dispatch = useAppDispatch()
    const dateFormats = useAppSelector(selectDateFormats)
    const strings = useAppSelector(selectStrings)

    const isTableEmpty = <small className={'d-block text-center py-2'}>
        <span >{
            strings.app?.message.error.empty_table || ''
        }</span>
    </small>

    const debounceSetSearch = useDebouncedCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            getOverallAbnormalitiesResponse
                .abnormalitiesDispatch({
                    type: 'SET_SEARCH',
                    value: e.target.value
                })
            // but also change the skip value to zero as well.
            getOverallAbnormalitiesResponse
                .abnormalitiesDispatch({
                    type: 'SET_SKIP',
                    value: 0
                })
        },
        1000
    )

    const LoadingContent = (
        <small className={'d-block text-center py-2'}>
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        </small>
    )

    const tableContent =

            <tbody>
                {
                    getOverallAbnormalitiesResponse.response
                        ?.data.abnormalityData.length
                        ? _.map(getOverallAbnormalitiesResponse.response
                            ?.data.abnormalityData, (obj) => {
                            const key = obj.id

                            const progress = (obj.progress.actual)

                            const formattedDate = obj.date
                                ? format(fromUnixTime(
                                    obj.date || 0
                                ), dateFormats.format1)
                                : strings.doc?.text.careprofessionals.abnormalities_table.unknown

                            return <React.Fragment key={key}>
                                <tr onClick={() => {}}>
                                    <td className={[
                                        'risk-border',
                                        obj.progress.risk.riskColor
                                    ].join(' ')}>
                                        <input type={'checkbox'}
                                            className={'form-check-input'} defaultChecked={true}
                                        />
                                    </td>
                                    <td>
                                        <u className={'me-2 clickable'} onClick={() => {
                                        // go to patient details using userId.
                                            const isValid = validateRoute(
                                                activeModules.arr,
                                                MODULE_TABLE.doc.moduleName,
                                                MODULE_TABLE.doc.routes.viewPatient,
                                                true
                                            )

                                            dispatch(push(
                                                _.replace(isValid.route,
                                                    ':userId',
                                                    obj.userId
                                                )
                                            ))
                                        }}>{
                                                obj.name
                                            }</u>
                                    </td>
                                    <td>
                                        <span className={'me-2'}>{formattedDate}</span>
                                    </td>
                                    <td>
                                        <span className={'me-2 d-block'}>{obj.anomaly}</span>
                                        <span className={'me-2 d-block'}>{obj.stepName}</span>
                                    </td>
                                    <td>
                                        <div className={'align-items-center row flex-nowrap'}>
                                            <div className={'col'}>
                                                <div className={'progress'}>
                                                    <div
                                                        className={'progress-bar'}
                                                        role={'progressbar'}
                                                        style={{ width: `${ progress }%` }}
                                                        aria-valuenow={progress}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                    >
                                                        {/* {`${ progress.toFixed(2) }%`} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'col-auto fraction'}>
                                                <div className={''}>
                                                    <span>{obj.progress.actual}</span>
                                                    <span>{'/'}</span>
                                                    <span>{obj.progress.expected}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={[
                                            'risk-container',
                                            obj.progress.risk.riskColor
                                        ].join(' ')}>
                                            {
                                                obj.progress.risk.riskColor === 'red' && <i
                                                    className={
                                                        'fa-sharp fa-solid fa-circle-exclamation'
                                                    }></i>
                                            }

                                            <div className={[
                                                'fraction',
                                                obj.progress.risk.riskColor === 'red' && 'ms-2'
                                            ].join(' ')}>
                                                <span className={'numerator'}>{
                                                    obj.progress.risk.score}</span>
                                                <span>{'/'}</span>
                                                <span className={'denominator'}>{
                                                    obj.progress.risk.maxScore}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={'cells'}>
                                            {
                                                _.map(obj.symptoms, (o, index) => {
                                                    return <div key={`cell-${ index }`}>
                                                        {o}
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </td>
                                </tr>
                            </React.Fragment>
                        })
                        : <tr><td colSpan={6}>{isTableEmpty}</td></tr>
                }
            </tbody>

    return <div className={'abnormal-interface'} ref={rootRef}>
        <div className={'row align-items-center justify-content-start mb-4 mt-2'}>
            <div className={'col-auto'}>
                <h6 className={'mb-0 fw-semibold '} >
                    {strings.doc?.text.careprofessionals
                        .abnormalities_table.patients_with_abnormal_recovery}
                </h6>
            </div>
            <div className={'col-auto'}>
                {/* refresh button */}
                <div className={'icon d-inline-block clickable'} onClick={() => {
                    if (getOverallAbnormalitiesResponse.isLoading === false) {
                        debouncedFetchAbnormalities()
                    }
                }}>
                    <i className={'fa-light fa-rotate-right'} aria-hidden={'true'}></i>
                </div>
            </div>
        </div>
        {/* then there is a search tab.  */}
        <div className={'row align-items-center justify-content-start mb-4'}>
            <div className={'col col-xl-6'}>
                <div className={'search-box'}>
                    <i className={'fa-light fa-search'}></i>
                    <input type={'text'} className={'form-control'}
                        placeholder={strings?.app?.text.search.text} onChange={(e) => {
                            debounceSetSearch(e)
                        }} onKeyDown={(e) => {
                            if (e.key === 'Enter') { debouncedFetchAbnormalities() }
                        }} defaultValue={
                            getOverallAbnormalitiesResponse.abnormalitiesState.search
                        } />
                </div>
            </div>
            <div className={'col-auto'}>
                {/* offcanvas button */}
                <button
                    type={'button'}
                    className={'btn btn-round btn-rounded'}
                    onClick={() => {
                        setShowOffcanvas(!showOffcanvas)
                    }}
                >
                    <i
                        className={'fa-light fa-filter'}
                        aria-hidden={'true'}>
                    </i>
                </button>
            </div>
        </div>
        {/* now the table */}
        <div className={[
            'record-menu',
            (getOverallAbnormalitiesResponse.response
                ?.data.totalRecords || 1) <= (getOverallAbnormalitiesResponse.response
                ?.data.limit || CAREPROF_TABLE_LIMIT)
                ? 'h-auto'
                : 'h-auto'
        ].join(' ')}>

            <table>
                <thead>
                    <tr>
                        <th>
                            <span className={'me-2'}>
                                {strings.doc?.text.careprofessionals
                                    .abnormalities_table.name}</span>
                            <i className={'fa-solid fa-angles-up-down'}></i>
                        </th>
                        <th>
                            <span className={'me-2'}>{strings.doc?.text
                                .careprofessionals.abnormalities_table.anomaly_date}</span>
                            <i className={'fa-solid fa-angles-up-down'}></i>
                        </th>
                        <th>
                            <span className={'me-2'}>{strings.doc?.text
                                .careprofessionals.abnormalities_table.anomaly}</span>
                            <i className={'fa-solid fa-angles-up-down'}></i>
                        </th>
                        <th>
                            <span className={'me-2'}>{strings.doc?.text
                                .careprofessionals.abnormalities_table.progress}</span>
                            <i className={'fa-solid fa-angles-up-down'}></i>
                        </th>
                        <th>
                            <span className={'me-2'}>{strings.doc?.text
                                .careprofessionals.abnormalities_table.risk}</span>
                            <i className={'fa-solid fa-angles-up-down'}></i>
                        </th>
                        <th>
                            <span className={'me-2'}>{strings.doc?.text
                                .careprofessionals.abnormalities_table.symptoms}</span>
                            <i className={'fa-solid fa-angles-up-down'}></i>
                        </th>
                    </tr>
                </thead>
                {
                    getOverallAbnormalitiesResponse.isLoading
                        ? <tbody><tr><td colSpan={6}>{LoadingContent}</td></tr></tbody>
                        : tableContent
                }
            </table>

        </div>

        {
            (getOverallAbnormalitiesResponse.response
                ?.data.totalRecords || 1) > (getOverallAbnormalitiesResponse.response
                ?.data.limit || CAREPROF_TABLE_LIMIT) && (
                <div className={'container-fluid pb-4 pt-6 px-3'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-auto'}>
                            <Pagination
                                currentPage={getOverallAbnormalitiesResponse
                                    .abnormalitiesState.skip}
                                setCurrentPageDispatch={(value: number) => {
                                    getOverallAbnormalitiesResponse.abnormalitiesDispatch({
                                        type: 'SET_SKIP',
                                        value
                                    })
                                }}
                                limit={getOverallAbnormalitiesResponse.response
                                    ?.data.limit || getOverallAbnormalitiesResponse.response
                                    ?.data.totalRecords || 1}
                                skip={getOverallAbnormalitiesResponse.response?.data.skip || 0}
                                totalRecords={(getOverallAbnormalitiesResponse.response
                                    ?.data.totalRecords || 1)}
                            />
                        </div>
                    </div>
                </div>
            )
        }

    </div>
}

const AbnormalitiesTable = ({
    debouncedFetchAbnormalities,
    showOffcanvas,
    setShowOffcanvas,
    rootRef,
    getOverallAbnormalitiesResponse
}: ComponentProps) => {
    return getOverallAbnormalitiesResponse.response?.status === 'NOT_ACTIVE'
        ? <Overlay
            component={<AbnormalRecovery
                debouncedFetchAbnormalities={debouncedFetchAbnormalities}
                showOffcanvas={showOffcanvas}
                setShowOffcanvas={setShowOffcanvas}
                rootRef={rootRef}
                getOverallAbnormalitiesResponse={getOverallAbnormalitiesResponse}
            />}
            text={getOverallAbnormalitiesResponse.response.message || ''}
        />
        : <AbnormalRecovery
            debouncedFetchAbnormalities={debouncedFetchAbnormalities}
            showOffcanvas={showOffcanvas}
            setShowOffcanvas={setShowOffcanvas}
            rootRef={rootRef}
            getOverallAbnormalitiesResponse={getOverallAbnormalitiesResponse}
        />
}

export default AbnormalitiesTable
