import { MODULE_TABLE } from '@app/app.config'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import Pagination from '@app/components/Pagination'
import { selectActiveModules, selectDateFormats, selectStrings } from '@app/slices/slice.app'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import { CAREPROF_TABLE_LIMIT } from '@doc/constants'
import {
    GetPatientsUpcomingSurgeryResponse,
    UpcomingSurgeryActions,
    UpcomingSurgeryState
} from '@doc/type'
import { push } from '@lagunovsky/redux-react-router'
import { format, fromUnixTime } from 'date-fns'
import _ from 'lodash'
import React from 'react'
import { DebouncedState, useDebouncedCallback } from 'use-debounce'

interface ComponentProps {
    debouncedFetchUpcomingSurgery: DebouncedState<() => void>
    rootRef: React.MutableRefObject<HTMLDivElement | null>
    getPatientsUpcomingSurgeryResponse: {
        isLoading: boolean;
        isSuccess: boolean;
        response: GetPatientsUpcomingSurgeryResponse | undefined;
        upcomingSurgeryState: UpcomingSurgeryState
        upcomingSurgeryDispatch: React.Dispatch<UpcomingSurgeryActions>
    }
}
const UpcomingSurgeryTable = ({
    debouncedFetchUpcomingSurgery,
    rootRef,
    getPatientsUpcomingSurgeryResponse
}: ComponentProps) => {
    const strings = useAppSelector(selectStrings)

    const validateRoute = useValidateRoute()
    const activeModules = useAppSelector(selectActiveModules)
    const dispatch = useAppDispatch()
    const dateFormats = useAppSelector(selectDateFormats)

    const isTableEmpty = <small className={'d-block text-center py-2'}>
        <span >{
            strings.app?.message.error.empty_table || ''
        }</span>
    </small>

    const debounceSetSearch = useDebouncedCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            getPatientsUpcomingSurgeryResponse
                .upcomingSurgeryDispatch({
                    type: 'SET_SEARCH',
                    value: e.target.value
                })
            // but also change the skip value to zero as well.
            getPatientsUpcomingSurgeryResponse
                .upcomingSurgeryDispatch({
                    type: 'SET_SKIP',
                    value: 0
                })
        },
        1000
    )

    const LoadingContent = (
        <small className={'d-block text-center py-2'}>
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        </small>
    )

    const tableContent =

        <tbody>
            {
                getPatientsUpcomingSurgeryResponse.response
                    ?.data.treatmentData.length
                    ? _.map(getPatientsUpcomingSurgeryResponse.response
                        ?.data.treatmentData, (obj) => {
                        const key = obj.userId

                        const formattedDate = obj.date
                            ? format(fromUnixTime(
                                obj.date || 0
                            ), dateFormats.format1)
                            : strings.doc?.text.careprofessionals.abnormalities_table.unknown

                        return <React.Fragment key={key}>
                            <tr onClick={() => {}}>
                                <td>
                                    <u className={'me-2 clickable'} onClick={() => {
                                    // go to patient details using userId.
                                        const isValid = validateRoute(
                                            activeModules.arr,
                                            MODULE_TABLE.doc.moduleName,
                                            MODULE_TABLE.doc.routes.viewPatient,
                                            true
                                        )

                                        dispatch(push(
                                            _.replace(isValid.route,
                                                ':userId',
                                                obj.userId
                                            )
                                        ))
                                    }}>{
                                            obj.name
                                        }</u>
                                </td>
                                <td>
                                    <span className={'me-2'}>{formattedDate}</span>
                                </td>
                                <td>
                                    <span className={'me-2'}>{obj.treatment}</span>
                                </td>
                            </tr>
                        </React.Fragment>
                    })
                    : <tr><td colSpan={6}>{isTableEmpty}</td></tr>
            }
        </tbody>

    return <div className={'abnormal-interface'} ref={rootRef}>
        <div className={'row align-items-center justify-content-between mb-4 mt-2'}>
            <div className={'col-auto'}>
                <h6 className={'mb-0 fw-semibold '} >
                    {strings.doc?.text.careprofessionals.arriving_patients.title}
                </h6>
            </div>
            <div className={'col-auto'}>
                {/* refresh button */}
                <div className={'icon d-inline-block clickable'} onClick={() => {
                    if (getPatientsUpcomingSurgeryResponse.isLoading === false) {
                        debouncedFetchUpcomingSurgery()
                    }
                }}>
                    <i className={'fa-light fa-rotate-right'} aria-hidden={'true'}></i>
                </div>
            </div>
        </div>
        {/* then there is a search tab.  */}
        <div className={'row align-items-center justify-content-start mb-4'}>
            <div className={'col col-xl-6'}>
                <div className={'search-box'}>
                    <i className={'fa-light fa-search'}></i>
                    <input type={'text'} className={'form-control'}
                        placeholder={strings?.app?.text.search.text} onChange={(e) => {
                            debounceSetSearch(e)
                        }} onKeyDown={(e) => {
                            if (e.key === 'Enter') { debouncedFetchUpcomingSurgery() }
                        }} defaultValue={
                            getPatientsUpcomingSurgeryResponse.upcomingSurgeryState.search
                        } />
                </div>
            </div>
        </div>
        {/* now the table */}
        <div className={[
            'record-menu',
            (getPatientsUpcomingSurgeryResponse.response
                ?.data.totalRecords || 1) <= (getPatientsUpcomingSurgeryResponse.response
                ?.data.limit || CAREPROF_TABLE_LIMIT)
                ? 'h-auto'
                : ''
        ].join(' ')}>

            <table>
                <thead>
                    <tr>
                        <th>
                            <span className={'me-2'}>
                                {strings.doc?.text.careprofessionals
                                    .abnormalities_table.name}</span>
                            <i className={'fa-solid fa-angles-up-down'}></i>
                        </th>
                        <th>
                            <span className={'me-2'}>{strings.doc?.text
                                .careprofessionals.treated_patients.operation_date}</span>
                            <i className={'fa-solid fa-angles-up-down'}></i>
                        </th>
                        <th>
                            <span className={'me-2'}>{strings.doc?.text
                                .careprofessionals.treated_patients.treatment}</span>
                            <i className={'fa-solid fa-angles-up-down'}></i>
                        </th>
                    </tr>
                </thead>
                {
                    getPatientsUpcomingSurgeryResponse.isLoading
                        ? <tbody><tr><td colSpan={6}>{LoadingContent}</td></tr></tbody>
                        : tableContent
                }
            </table>

        </div>

        {
            (getPatientsUpcomingSurgeryResponse.response
                ?.data.totalRecords || 1) > (getPatientsUpcomingSurgeryResponse.response
                ?.data.limit || CAREPROF_TABLE_LIMIT) && (
                <div className={'container-fluid pb-4 pt-6 px-3'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-auto'}>
                            <Pagination
                                currentPage={getPatientsUpcomingSurgeryResponse
                                    .upcomingSurgeryState.skip}
                                setCurrentPageDispatch={(value: number) => {
                                    getPatientsUpcomingSurgeryResponse.upcomingSurgeryDispatch({
                                        type: 'SET_SKIP',
                                        value
                                    })
                                }}
                                limit={getPatientsUpcomingSurgeryResponse.response
                                    ?.data.limit || getPatientsUpcomingSurgeryResponse.response
                                    ?.data.totalRecords || 1}
                                skip={getPatientsUpcomingSurgeryResponse.response
                                    ?.data.skip || 0}
                                totalRecords={(getPatientsUpcomingSurgeryResponse.response
                                    ?.data.totalRecords || 1)}
                            />
                        </div>
                    </div>
                </div>
            )
        }

    </div>
}

export default UpcomingSurgeryTable
