import { MOBILE_RESPONSIVE_LIMIT, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectDateFormats, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { MyTherapeutic } from '@profile/type'
import { format, fromUnixTime } from 'date-fns'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { useDeleteCareplanMutation } from '@doc/api'
import { GetPatientTherapeuticsResponse } from '@doc/type'

import { MODULE_TABLE } from '@app/app.config'
import Pagination from '@app/components/Pagination'
import EditPatientTreatment from '@doc/components/patients/details/makeCareplan/EditPatientTreatment'
import { useMediaQuery } from 'react-responsive'

interface ComponentProps {
    mode: 'EDIT' | 'VIEW',
    fetchData: (token: TokenData) => () => void
    responseData?: GetPatientTherapeuticsResponse
    isLoading: boolean
}

const CompleteCards = ({
    responseData,
    currentPage,
    toggleShowEditModal,
    toggleActiveTreatment
}:{
    responseData: GetPatientTherapeuticsResponse | undefined
    currentPage: number
    toggleShowEditModal: React.Dispatch<React.SetStateAction<boolean>>
    toggleActiveTreatment: React.Dispatch<React.SetStateAction<MyTherapeutic>>
}) => {
    const strings = useAppSelector(selectStrings)

    const dateFormats = useAppSelector(selectDateFormats)
    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })
    function paginateArray (array: any[], pageSize:number, pageNumber:number) {
        const startIndex = (pageNumber) * pageSize
        const endIndex = startIndex + pageSize
        return array.slice(startIndex, endIndex)
    }

    const data = paginateArray(
        responseData?.data.myTherapeutics || [],
        2, currentPage
    )

    return data?.length
        ? <div className={'row row-cols-1 g-3 g-lg-5'}>
            {_.map(data, (o, index) => {
                const formattedDate = o.startDate
                    ? format(fromUnixTime(
                        o.startDate || 0
                    ), dateFormats.format3)
                    : strings.doc?.text.careprofessionals.abnormalities_table.unknown

                const key = o.id

                return <div className={'col'} key={key}>
                    {/* can have the completed and inactive classes */}
                    <div className={['card h-100 completed inactive',
                        !isMobile ? ' py-3' : ''].join(' ')}>

                        <div className={'card-body'}>

                            <div className={'row justify-content-between align-items-center'}>
                                <div className={'col'}>
                                    <span className={isMobile
                                        ? 'd-block h6 mb-1'
                                        : 'd-block h5 mb-2'
                                    }>
                                        {o.careplanName}
                                    </span>
                                    <span className={'d-inline-block'}>
                                        {strings.doc?.text
                                            .careprofessionals.treated_patients.operation_date}
                                        {':'}
                                    </span>
                                    <span className={'d-inline-block'}>
                                        {formattedDate}
                                    </span>

                                </div>
                                <div className={'col-auto'}>
                                    <button
                                        type={'button'}
                                        className={[
                                            'btn btn-round btn-rounded me-3'
                                        ].join('')}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            toggleActiveTreatment(o)
                                            toggleShowEditModal(true)
                                        }}
                                    >
                                        <i
                                            className={[
                                                'fa-light',
                                                'fa-pen-to-square'
                                            ].join(' ')}
                                            aria-hidden={'true'}>
                                        </i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            })}
        </div>
        : <div className={'text-center'}>
            {strings.app?.message.error.empty_table}
        </div>
}

const PatientTreatmentsInterface = ({
    mode, responseData, fetchData, isLoading
}: ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()
    const validateAPIPath = useValidateAPIPath()

    const token = useAppSelector(selectToken)

    const [showEditModal, toggleShowEditModal] = useState<boolean>(false)
    const [showDeleteModal, toggleShowDeleteModal] = useState<boolean>(false)
    const [activeTreatment, toggleActiveTreatment] = useState<MyTherapeutic>({
        careplanName: '',
        myCareplanId: '',
        canEditTreatment: true,
        startDate: 0,
        status: 'active'
    })

    const [currentPage, setCurrentPage] = useState(0)

    const [deleteCareplan, deleteCareplanMutation] = useDeleteCareplanMutation()
    useEffect(() => {
        if (deleteCareplanMutation.data) {
            const data = deleteCareplanMutation.data
            if (data.status === 'OK') {
                toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                toggleShowDeleteModal(false)
                fetchData(token)
            } else {
                toast.error(
                    data.message,
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }, [deleteCareplanMutation.data])

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    // startIncompleteCareplan modal goes here.
    // edit treatment form will be using something else.
    const editTreatmentForm = useMemo(() => {
        return activeTreatment.myCareplanId && <Modal
            unmountOnClose={false}
            size={'lg'}
            isOpen={showEditModal} toggle={() => {
                toggleShowEditModal(false)
            }}>
            <ModalHeader className={'justify-content-start'} toggle={() => {
                toggleShowEditModal(false)
                // btn-close me-auto ms-0
            }} close={ <a className={'btn btn-round '}
                onClick={(e) => {
                    e.preventDefault()
                    toggleShowEditModal(false)
                }}>
                <i className={'fa-light fa-arrow-left'} aria-hidden={'true'}>
                </i>
            </a>}
            >{}</ModalHeader>
            <ModalBody>
                <EditPatientTreatment
                    treatment={activeTreatment}
                    toggleShowEditModal={toggleShowEditModal}
                    fetchData={fetchData}
                />
            </ModalBody>
        </Modal>
    }, [
        showEditModal,
        activeTreatment
    ])

    const deleteTreatmentForm = useMemo(() => {
        return activeTreatment.myCareplanId && <Modal
            unmountOnClose={false}
            size={'lg'}
            isOpen={showDeleteModal} toggle={() => {
                toggleShowDeleteModal(false)
            }}>
            <ModalHeader className={'justify-content-start'} toggle={() => {
                toggleShowDeleteModal(false)
                // btn-close me-auto ms-0
            }} close={ <a className={'btn btn-round '}
                onClick={(e) => {
                    e.preventDefault()
                    toggleShowDeleteModal(false)
                }}>
                <i className={'fa-light fa-arrow-left'} aria-hidden={'true'}>
                </i>
            </a>}
            >{}</ModalHeader>
            <ModalBody>
                <p className={'fw-500 mb-2'}>{
                    strings.doc?.text.patient
                        .patient_records.delete_treatment?.confirmation_message?.[1]
                }</p>
            </ModalBody>
            <ModalFooter>
                <div className={'container px-0'}>

                    <div className={'row align-items-center'}>

                        <div className={'col-6 col-md-4 ms-auto'}>

                            <button type={'button'} onClick={async () => {
                                // do api call.
                                const newToken = await revalidateToken(token, token.mode)

                                const isValid = validateAPIPath(
                                    activeModules.arr,
                                    MODULE_TABLE.doc.moduleName,
                                    MODULE_TABLE.doc.apiPaths
                                        .deleteCareplan.path,
                                    true
                                )

                                if (isValid) {
                                    deleteCareplan({
                                        authToken: newToken.value,
                                        data: {
                                            myCareplanId: activeTreatment.myCareplanId
                                        }
                                    })
                                }
                            }} className={'btn btn-primary btn-sm w-100'}>
                                {strings.app?.text.yes}
                            </button>

                        </div>

                        <div className={'col-6 col-md-4 me-auto'}>

                            <button type={'button'} onClick={() => {
                                toggleShowDeleteModal(false)
                            }} className={'btn btn-secondary btn-sm w-100'}>
                                {strings.app?.text.no}
                            </button>

                        </div>

                    </div>

                </div>
            </ModalFooter>
        </Modal>
    }, [
        showDeleteModal,
        activeTreatment
    ])

    const LoadingContent = (
        <small className={'d-block text-center py-2'}>
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        </small>
    )

    const desktopResult = <div>
        {
            isLoading
                ? LoadingContent
                : <CompleteCards responseData={responseData}
                    currentPage={currentPage}
                    toggleShowEditModal={toggleShowEditModal}
                    toggleActiveTreatment={toggleActiveTreatment}
                />
        }
        {/* show pagination if content is greater than 2 */}
        <Pagination
            currentPage={currentPage}
            setCurrentPageState={setCurrentPage}
            limit={2}
            skip={1}
            totalRecords={responseData?.data.myTherapeutics.length || 1}
        />

        {editTreatmentForm}
        {deleteTreatmentForm}
    </div>

    return desktopResult
}

export default PatientTreatmentsInterface
