import { useAppSelector } from '@app/app.hook'
import { selectDateFormats, selectStrings } from '@app/slices/slice.app'
import _ from 'lodash'
import React, { useEffect, useReducer, useState } from 'react'

import Pagination from '@app/components/Pagination'
import {
    ComparisonDataActions,
    ComparisonDataState,
    PatientProgressTableDataResponse
} from '@doc/type'
import { format, fromUnixTime } from 'date-fns'
import produce from 'immer'
import { v4 as uuidV4 } from 'uuid'

interface ComponentProps {
    patientProgressTableData: PatientProgressTableDataResponse[] | undefined
    isLoading: boolean
    isSuccess: boolean
    dataId: string
}

const RecoveryComparisonTable = ({
    comparisonDataState,
    comparisonDataDispatch,
    currentPage,
    setCurrentPage,
    patientProgressTableData
}:{
    comparisonDataState:ComparisonDataState
    comparisonDataDispatch: React.Dispatch<ComparisonDataActions>
    currentPage:number
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
    patientProgressTableData: ComponentProps['patientProgressTableData']
    }) => {
    const dateFormats = useAppSelector(selectDateFormats)
    const strings = useAppSelector(selectStrings)

    const sortProgressData = (
        progress: (PatientProgressTableDataResponse & {id:string})[],
        field: keyof (PatientProgressTableDataResponse & {id:string}),
        order: 'asc' | 'desc' = 'asc'
    ) => {
        if (field === 'progressLevel') {
            return _.orderBy(progress,
                [dev => _.orderBy(dev.progressLevel, ['actual'], order)],
                order
            )
        } else if (field === 'statusLabels') {
            return _.orderBy(progress,
                [dev => _.orderBy(dev.statusLabels, ['labelName'], order)[0]?.labelName],
                order
            )
        } else {
            return _.orderBy(progress, field, order)
        }
    }

    const toggleSortAndArrange = (key: keyof (PatientProgressTableDataResponse & {id:string})) => {
        // toggle sort.
        if (comparisonDataState.sort !== key) {
            comparisonDataDispatch({
                type: 'SET_SORT',
                value: key
            })

            // but also set the arrange to asc.
            comparisonDataDispatch({
                type: 'SET_ARRANGE',
                value: 'asc'
            })
        } else {
            // toggle the arrange
            comparisonDataDispatch({
                type: 'SET_ARRANGE',
                value: comparisonDataState.arrange === 'asc'
                    ? 'desc'
                    : 'asc'
            })
        }
    }

    const sorted = sortProgressData(
        comparisonDataState.data,
        comparisonDataState.sort,
        comparisonDataState.arrange
    ).slice(comparisonDataState
        .skip, comparisonDataState
        .skip + comparisonDataState
        .limit)

    const sortArrows = <i
        className={[
            'fa-solid fa-angles-up-down',
            comparisonDataState.arrange === 'asc'
                ? ''
                : 'fa-rotate-180'
        ].join(' ')
        }>
    </i>

    // stepColor, Date (i prefer dd-mm-yyyy here), stepName,
    // stepType, Progress, stepState, Recommendation, Labels
    const tableHeaders = <tr>
        {/* <th>
            <input type={'checkbox'} className={'form-check-input'}/>
        </th> */}
        <th onClick={() => {
            toggleSortAndArrange('timestamp')
        }}>
            <span className={'me-2 clickable'}>
                {strings.doc?.text.patient.patient_progress.table.day}</span>
            {/* only show arrows IF this is set */}
            {
                comparisonDataState.sort === 'timestamp' && sortArrows
            }
        </th>
        <th onClick={() => {
            toggleSortAndArrange('stepName')
        }}>
            <span className={'me-2 clickable'}>
                {strings.doc?.text.patient.patient_progress.table.step_name}</span>
            {/* only show arrows IF this is set */}
            {
                comparisonDataState.sort === 'stepName' && sortArrows
            }
        </th>
        <th onClick={() => {
            toggleSortAndArrange('stepType')
        }}>
            <span className={'me-2 clickable'}>
                {strings.doc?.text.patient.patient_progress.table.step_type}</span>
            {/* only show arrows IF this is set */}
            {
                comparisonDataState.sort === 'stepType' && sortArrows
            }
        </th>
        <th onClick={() => {
            toggleSortAndArrange('progressLevel')
        }}>
            <span className={'me-2 clickable'}>{strings.doc?.text.patient
                .patient_progress.table.progress_expectation}</span>
            {/* only show arrows IF this is set */}
            {
                comparisonDataState.sort === 'progressLevel' && sortArrows
            }
        </th>
        <th onClick={() => {
            toggleSortAndArrange('stepState')
        }}>
            <span className={'me-2 clickable'}>
                {strings.doc?.text.patient.patient_progress.table.step_state}</span>
            {/* only show arrows IF this is set */}
            {
                comparisonDataState.sort === 'stepState' && sortArrows
            }
        </th>
        <th onClick={() => {
            toggleSortAndArrange('recommendation')
        }}>
            <span className={'me-2 clickable'}>
                {strings.doc?.text.patient.patient_progress.table.advice}</span>
            {/* only show arrows IF this is set */}
            {
                comparisonDataState.sort === 'recommendation' && sortArrows
            }
        </th>
        <th onClick={() => {
            toggleSortAndArrange('statusLabels')
        }}>
            <span className={'me-2 clickable'}>
                {strings.doc?.text.patient.patient_progress.table.status}</span>
            {/* only show arrows IF this is set */}
            {
                comparisonDataState.sort === 'statusLabels' && sortArrows
            }
        </th>

    </tr>

    const tableBody = _.map(sorted, (obj) => {
        const key = obj.id

        const progress = obj.progressLevel.actual
        const formattedDate = format(fromUnixTime(obj.timestamp), dateFormats.format1)

        // step state and type string goes here.
        let stepTypeString = ''

        if (obj.stepType === 'activity') {
            stepTypeString = strings.doc?.text.patient
                .patient_progress.table.filters.activity || ''
        } else if (obj.stepType === 'content') {
            stepTypeString = strings.doc?.text.patient
                .patient_progress.table.filters.content || ''
        } else if (obj.stepType === 'lesson') {
            stepTypeString = strings.doc?.text.patient
                .patient_progress.table.filters.lesson || ''
        } else if (obj.stepType === 'uncertain') {
            stepTypeString = strings.doc?.text.patient
                .patient_progress.table.filters.uncertain || ''
        } else if (obj.stepType === 'reasoning') {
            stepTypeString = strings.doc?.text.patient
                .patient_progress.table.filters.reasoning || ''
        }

        const isComplete = obj.stepState === 'complete'
        const locked = obj.stepState === 'locked'
        const uncertain = obj.stepState === 'uncertain'
        const isIncomplete = obj.stepState === 'incomplete'
        const isOpen = obj.stepState === 'open'

        const stepStateString = isComplete
            ? strings.doc?.text.patient
                .patient_progress.table.stepState.complete
            : locked
                ? strings.doc?.text.patient
                    .patient_progress.table.stepState.locked
                : uncertain
                    ? strings.doc?.text.patient
                        .patient_progress.table.stepState.uncertain
                    : isIncomplete
                        ? strings.doc?.text.patient
                            .patient_progress.table.stepState.incomplete
                        : isOpen
                            ? strings.doc?.text.patient
                                .patient_progress.table.stepState.open
                            : ''

        return <React.Fragment key={key}>
            <tr onClick={() => {}}>
                <td className={[
                    'risk-border',
                    _.lowerCase(obj.stepColor)
                ].join(' ')}>
                    <span className={'me-2'}>{formattedDate}</span>
                </td>
                <td style={{
                    maxWidth: 500,
                    minWidth: 500,
                    whiteSpace: 'normal'
                }}>
                    <span className={'me-2'}>{obj.stepName}</span>
                </td>
                <td>
                    <span className={'me-2'}>{stepTypeString}</span>
                </td>
                <td>
                    <div className={
                        'align-items-center justify-content-between row flex-nowrap'}
                    >
                        <div className={'col'}>
                            <div className={'progress'}>
                                <div
                                    className={'progress-bar'}
                                    role={'progressbar'}
                                    style={{ width: `${ progress }%` }}
                                    aria-valuenow={progress}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                >
                                    {/* {`${ progress.toFixed(2) }%`} */}
                                </div>
                            </div>
                        </div>
                        <div className={'col-auto fraction'}>
                            <div className={''}>
                                <span className={'fw-semibold'}>{
                                    [
                                        Math.round(obj.progressLevel.actual),
                                        obj.progressLevel.dataType
                                    ].join('')
                                }</span>
                                <span className={'mx-1'}>{'/'}</span>
                                <span>{
                                    [
                                        Math.round(obj.progressLevel.expected),
                                        obj.progressLevel.dataType
                                    ].join('')
                                }</span>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <span className={'me-2'}>{stepStateString}</span>
                </td>
                <td>
                    <span className={'me-2'}>{obj.recommendation}</span>
                </td>
                <td>
                    <div className={'cells'}>
                        {
                            _.map(obj.statusLabels, (o, index) => {
                                return <div className={[
                                    `cell-${ index }`,
                                    'risk-container',
                                    (o.labelColor)
                                ].join(' ')}>
                                    {
                                        o.labelColor === 'red' && <i className={
                                            'fa-sharp fa-solid fa-circle-exclamation'
                                        }></i>
                                    }

                                    <div className={[
                                        'fraction',
                                        o.labelColor === 'red' && 'ms-2'
                                    ].join(' ')}>
                                        <span>{
                                            o.labelName
                                        }</span>
                                    </div>
                                </div>
                            })
                        }
                    </div>

                </td>

            </tr>
        </React.Fragment>
    })

    const pagination = <div className={'container-fluid pb-4 pt-6 px-3'}>
        <div className={'row justify-content-center'}>
            <div className={'col-auto'}>
                <Pagination
                    currentPage={currentPage}
                    setCurrentPageState={setCurrentPage}
                    limit={comparisonDataState.limit || patientProgressTableData?.length || 1}
                    skip={comparisonDataState.skip}
                    totalRecords={patientProgressTableData?.length || 0}
                />
            </div>
        </div>
    </div>

    return <div className={'record-menu'}>
        <table>
            <thead>
                {tableHeaders}
            </thead>
            <tbody>
                {
                    tableBody
                }
            </tbody>
        </table>
        {/* show pagination when length is greater than limit */}
        {
            comparisonDataState.data.length > comparisonDataState.limit && pagination
        }
    </div>
}

const PatientProgressTable = ({
    patientProgressTableData, isLoading, isSuccess,
    dataId
}:ComponentProps) => {
    const strings = useAppSelector(selectStrings)

    const [currentPage, setCurrentPage] = useState(0)

    const [comparisonDataState, comparisonDataDispatch] = useReducer((
        state: ComparisonDataState, action: ComparisonDataActions
    ) => {
        switch (action.type) {
            case 'SET_DATA':{
                return produce(state, draft => {
                    draft.data = action.value
                })
            }
            case 'SET_SKIP':{
                return produce(state, draft => {
                    draft.skip = action.value
                })
            }
            case 'SET_LIMIT':{
                return produce(state, draft => {
                    draft.limit = action.value
                })
            }
            case 'SET_SORT':{
                return produce(state, draft => {
                    draft.sort = action.value
                })
            }
            case 'SET_ARRANGE':{
                return produce(state, draft => {
                    draft.arrange = action.value
                })
            }
        }
    }, {
        data: [],
        skip: 0,
        limit: 10,
        sort: 'stepName',
        arrange: 'asc'
    })

    useEffect(() => {
        comparisonDataDispatch({
            type: 'SET_DATA',
            value: _.map(patientProgressTableData, (o) => {
                return {
                    ...o,
                    id: uuidV4()
                }
            })
        })
    }, [patientProgressTableData])

    useEffect(() => {
        comparisonDataDispatch({
            type: 'SET_SKIP',
            value: currentPage * comparisonDataState.limit
        })
    }, [currentPage])

    const isLoadingContent = <small className={'d-block text-center py-2'}>
        <div className={'spinner-container'}>
            <span className={'spinner-border spinner-border-sm'}></span>
            <span className={'ms-2'}>{
                strings.app?.text.loading || ''
            }</span>
        </div>
    </small>

    const isTableEmpty = <small className={'d-block text-center py-2'}>
        <span >{
            strings.app?.message.error.empty_table || ''
        }</span>
    </small>

    const recoveryComparisonTableContainer = <div>
        {
            isLoading
                ? isLoadingContent
                : patientProgressTableData?.length
                    ? <div className={'record-menu chart-table-container'}>
                        {<RecoveryComparisonTable
                            comparisonDataState={comparisonDataState}
                            comparisonDataDispatch={comparisonDataDispatch}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            patientProgressTableData={patientProgressTableData}
                        />}
                    </div>
                    : isSuccess && isTableEmpty
        }

    </div>

    return <>
        {dataId &&
            recoveryComparisonTableContainer}
    </>
}

export default PatientProgressTable
