import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import RecoveryComparisonChart from '@doc/components/patients/details/charts/RecoveryComparisonChart'
import { useEffect, useReducer } from 'react'

import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import NewDatePicker from '@app/components/NewDatePicker'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useGetPatientProgressMutation } from '@doc/api'
import PatientProgressTable from '@doc/components/patients/details/components/patientProgress/PatientProgressTable'
import { IDS } from '@doc/constants'
import { ComparisonChartActions, ComparisonChartState, PatientParams } from '@doc/type'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { format, getUnixTime, subDays } from 'date-fns'
import produce from 'immer'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

interface ComponentProps {
    floatingUiBoundary: HTMLDivElement | null,
}

const TitleDateRange = ({ comparisonChartState }: {comparisonChartState: ComparisonChartState}) => {
    const start = comparisonChartState.dateRanges.start || new Date()
    const end = comparisonChartState.dateRanges.end || new Date()

    const formattedStartDate = format(start, 'd MMM yyyy')
    const formattedEndDate = format(end, 'd MMM yyyy')

    return <span className={'fw-semibold'}>{
        [
            formattedStartDate,
            ' - ',
            formattedEndDate
        ].join(' ')
    }</span>
}

const ComparisonChartInterface = ({
    floatingUiBoundary
} : ComponentProps) => {
    const strings = useAppSelector(selectStrings)
    const token = useAppSelector(selectToken)

    const [getPatientProgressChart, getPatientProgressChartMutation] =
    useGetPatientProgressMutation()

    const [getPatientProgressTable, getPatientProgressTableMutation] =
    useGetPatientProgressMutation()

    const { userId } = useParams<PatientParams>()
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const activeModules = useAppSelector(selectActiveModules)

    const [comparisonChartState, comparisonChartDispatch] = useReducer(
        (state: ComparisonChartState, action: ComparisonChartActions) => {
            switch (action.type) {
                case 'SET_DATE_RANGE': {
                    return produce(state, draft => {
                        draft.dateRanges[action.value.key] = action.value.date
                    })
                }
                case 'SET_DATA_ID' : {
                    return produce(state, draft => {
                        draft.dataId = action.value
                    })
                }
            }
        }, {
            dateRanges: {
                start: subDays(new Date(), 14),
                end: new Date()
            },
            dataId: '',
            filters: {}
        }
    )

    const patientProgressChartData = getPatientProgressChartMutation.data
        ?.data.chartData

    const patientProgressTableData = getPatientProgressTableMutation.data
        ?.data.progressData

    const goToToday = () => {
        comparisonChartDispatch({
            type: 'SET_DATE_RANGE',
            value: {
                date: subDays(new Date(), 14),
                key: 'start'
            }
        })

        comparisonChartDispatch({
            type: 'SET_DATE_RANGE',
            value: {
                date: new Date(),
                key: 'end'
            }
        })
    }

    const fetchData = (token: TokenData) => {
        let isMounted = true

        const call = async () => {
            if (
                token.valid && userId &&
                 comparisonChartState.dateRanges.start &&
                 comparisonChartState.dateRanges.end
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid4 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getPatientProgress.path,
                        true
                    )

                    // console.log('isValid: ', isValid4)

                    if (isValid4 && newToken.value) {
                        getPatientProgressChart({
                            authToken: newToken.value,
                            data: {
                                dateFrom: getUnixTime(comparisonChartState.dateRanges.start),
                                dateTo: getUnixTime(comparisonChartState.dateRanges.end),
                                userId
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
        }
    }

    const fetchData2 = (token: TokenData) => {
        let isMounted = true

        const call = async () => {
            if (
                token.valid && userId &&
                comparisonChartState.dateRanges.start && comparisonChartState.dateRanges.end &&
                 comparisonChartState.dataId
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid4 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getPatientProgress.path,
                        true
                    )

                    if (isValid4 && newToken.value) {
                        getPatientProgressTable({
                            authToken: newToken.value,
                            data: {
                                dateFrom: getUnixTime(comparisonChartState.dateRanges.start),
                                dateTo: getUnixTime(comparisonChartState.dateRanges.end),
                                dataId: comparisonChartState.dataId,
                                userId
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
        }
    }

    useEffect(() => {
        if (getPatientProgressChartMutation.error) {
            const message = getErrorText(getPatientProgressChartMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPatientProgressChartMutation.error])

    useEffect(() => {
        if (getPatientProgressTableMutation.error) {
            const message = getErrorText(getPatientProgressTableMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPatientProgressTableMutation.error])

    useEffect(() => {
        // console.log('useriD: ', userId)
        if (userId) {
            return fetchData(token)
        } else {
            return () => {}
        }
    }, [token.id, token.valid, userId, comparisonChartState.dateRanges])

    useEffect(() => {
        if (userId) {
            return fetchData2(token)
        } else {
            return () => {}
        }
    }, [comparisonChartState.dataId])

    const chartRangeId = [
        IDS.CHART.PROGRESS_RANGE
    ].join(' ')

    const IconPicker = <NewDatePicker
        id={chartRangeId}
        singleDate={comparisonChartState.dateRanges.start}
        startDate={comparisonChartState.dateRanges.start}
        endDate ={comparisonChartState.dateRanges.end }
        selectsRange={true}
        isRange={(dates) => {
            const [start, end] = dates

            comparisonChartDispatch({
                type: 'SET_DATE_RANGE',
                value: {
                    date: start,
                    key: 'start'
                }
            })

            comparisonChartDispatch({
                type: 'SET_DATE_RANGE',
                value: {
                    date: end,
                    key: 'end'
                }
            })

            // request to execute fetch data right after.
        }}

    />

    const header = <div className={'row justify-content-between mb-4 align-items-center g-2'}
    >
        <div className={'col-auto'}>
            <div className={'align-items-baseline gx-2 row'}>
                <div className={'col-auto'}>
                    <h5 className={'mb-0 fw-semibold '} >
                        {strings.doc?.text.patient.patient_progress.recovery_comparison}
                    </h5>
                </div>
                <div className={'col-auto'}>
                    {/* date range label is moved to here without the arrows */}
                    <span className={'fw-semibold'}>{
                        <TitleDateRange comparisonChartState={comparisonChartState} />
                    }</span>
                </div>
            </div>
        </div>
        <div className={'col-auto'}>
            <div className={'row justify-content-between align-items-center'}>
                <div className={'col-auto'}>
                    {/* refresh button */}
                    <div className={'icon d-inline-block clickable'} onClick={() => {
                        if (getPatientProgressChartMutation.isLoading === false) {
                            fetchData(token)
                        }
                    }}>
                        <i className={'fa-light fa-rotate-right'} aria-hidden={'true'}></i>
                    </div>
                </div>
                <div className={'col-auto'}>
                    {/* date range picker */}
                    {IconPicker}
                </div>
            </div>
        </div>
    </div>

    const filters = <div className={'row justify-content-between g-3'}>
        <div className={'col-auto'}>
            <div className={'align-items-center g-2 gap-3 row'}>
                <div className={'col-auto'}>
                    <div className={'row align-items-center g-2'}>
                        <div className={'col-auto'}>
                            <i className={'fa-light fa-calendar-arrow-up'}></i>
                        </div>
                        <div className={'col-auto'}>
                            <span className={'fw-light clickable'} onClick={() => {
                                goToToday()
                            }}>
                                {strings.doc?.text.patient.patient_progress.today}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    const isLoadingContent = <small className={'d-block text-center py-2'}>
        <div className={'spinner-container'}>
            <span className={'spinner-border spinner-border-sm'}></span>
            <span className={'ms-2'}>{
                strings.app?.text.loading || ''
            }</span>
        </div>
    </small>

    const isChartEmpty = <small className={'d-block text-center py-2'}>
        <span >{
            strings.app?.message.error.empty_table || ''
        }</span>
    </small>

    const chart = getPatientProgressChartMutation.isLoading
        ? isLoadingContent
        : patientProgressChartData?.length
            ? <div className={'chart-container mb-4'}>
                <RecoveryComparisonChart patientProgressChartData={patientProgressChartData}
                    comparisonChartDispatch={comparisonChartDispatch}
                />
            </div>
            : getPatientProgressChartMutation.isSuccess && isChartEmpty

    return <>
        <div className={'course-interface'}>
            {header}
            {filters}
            {chart}
            <PatientProgressTable
                patientProgressTableData={patientProgressTableData}
                isLoading={getPatientProgressTableMutation.isLoading}
                isSuccess={getPatientProgressTableMutation.isSuccess}
                dataId={comparisonChartState.dataId}
            />
        </div>
    </>
}

export default ComparisonChartInterface
