import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'

import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { selectToken } from '@app/slices/slice.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import {
    FACILITY_STEP_ONE_FORMIK_INITIAL_VALUES,
    FACILITY_STEP_ONE_VALIDATION_SCHEMA,
    FACILITY_STEP_TWO_FORMIK_INITIAL_VALUES,
    FACILITY_STEP_TWO_VALIDATION_SCHEMA,
    MODULE_VERSION
} from '@fmt/constants'
import {
    CareFacilityKeys,
    FacilityParams,
    GetCareFacilityResponse,
    GetDepartmentsResponse,
    UpdateProfilePictureFacilityResponse
} from '@fmt/type'
import { ImageDisplay } from '@profile/styles/ImageDisplay'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'

import { selectRouter } from '@app/app.store'
import { TokenData } from '@app/types/type.token'
import {
    useEditCareFacilityMutation,
    useGetCareFacilityMutation,
    useGetDepartmentsMutation,
    useUpdateProfilePictureFacilityMutation
} from '@fmt/api'
import DashboardResponsiveHeader from '@fmt/components/DashboardResponsiveHeader'
import DepartmentsViewTable from '@fmt/components/facilities/details/DepartmentsTable'
import Address from '@fmt/components/facilities/details/inputs/Address'
import City from '@fmt/components/facilities/details/inputs/City'
import Email from '@fmt/components/facilities/details/inputs/Email'
import FacilityName from '@fmt/components/facilities/details/inputs/FacilityName'
import GeneralPhoneNumber from '@fmt/components/facilities/details/inputs/GeneralPhoneNumber'
import LinkDepartmentDropdown from '@fmt/components/facilities/details/inputs/LinkDepartmentDropdown'
import PostalCode from '@fmt/components/facilities/details/inputs/PostalCode'
import StreetName from '@fmt/components/facilities/details/inputs/StreetName'
import Website from '@fmt/components/facilities/details/inputs/Website'
import { back, push } from '@lagunovsky/redux-react-router'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const ProfilePic = ({
    showRecentlyUploaded,
    updateProfilePictureFacilityMutation,
    getCareFacilityMutation
}: {
    showRecentlyUploaded: boolean
    updateProfilePictureFacilityMutation: {
        data: UpdateProfilePictureFacilityResponse | undefined
    }
    getCareFacilityMutation: {
        data?: GetCareFacilityResponse | undefined
    }
}) => {
    const url = showRecentlyUploaded
        ? updateProfilePictureFacilityMutation.data?.data.url
        : getCareFacilityMutation.data?.data.profilePicture
    const imageThumbnail = url
        ? <ImageDisplay
            url={url}
            className={'rounded-square profile'}
        />
        : <div
            className={[
                'img-placeholder rounded-square profile'
            ].join(' ')}
        >
        </div>

    return imageThumbnail
}

const FacilityDetailsInterface = () => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)

    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()
    const router = useAppSelector(selectRouter)
    const validateRoute = useValidateRoute()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()

    // upload profile picture logic.
    const [file, setFile] = useState<File | null>(null)
    const [getCareFacility, getCareFacilityMutation] = useGetCareFacilityMutation()
    const fixedCacheKey = 'details-getDepartments-key'
    const [getDepartments, getDepartmentsMutation] = useGetDepartmentsMutation({
        fixedCacheKey
    })
    const [currentPage, setCurrentPage] = useState(0)

    // a workaround to prevent tables from flickering. simple but it works.
    const [
        getDepartmentsResponse,
        setGetDepartmentsResponse
    ] = useState<GetDepartmentsResponse>()

    useEffect(() => {
        if (getDepartmentsMutation.data) {
            setGetDepartmentsResponse(getDepartmentsMutation.data)
        }
    }, [getDepartmentsMutation.data])

    const [showRecentlyUploaded, setShowRecentlyUploaded] = useState<boolean>(false)
    const [showCancelWarning, setShowCancelWarning] = useState<boolean>(false)

    const { careFacilityId } = useParams<FacilityParams>()

    const [editCareFacility, editCareFacilityMutation] = useEditCareFacilityMutation()

    const [mode, setMode] = useState<'EDIT' | 'VIEW'>(
        _.lowerCase(router.location.pathname).includes('edit') ? 'EDIT' : 'VIEW'
    )

    // a fix that does the ff things:
    // before this existed, the getPatients gets called twice from two separate useEffects
    // the first hook should only be done once you clicked on either buttons that
    // changes the url. ideally, a quick solution. should be set to FALSE.
    const [modeButtonClicked, setModeButtonClicked] = useState(false)

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    const facilityEditFormik = useFormik({
        initialValues: getCareFacilityMutation.data?.data ||
        FACILITY_STEP_ONE_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: FACILITY_STEP_ONE_VALIDATION_SCHEMA(
            strings.app?.message.error.email || '',
            strings.app?.message.error.empty || ''
        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken(
                        {
                            value: token.value,
                            id: token.id
                        },
                        token.mode
                    )
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths.editCareFacility.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        const data = {
                            careFacilityId,
                            facilityName: values.facilityName || '',
                            facilityAddressStreet: values.facilityAddressStreet,
                            facilityAddressNumber: values.facilityAddressNumber,
                            facilityAddressSuffix: values.facilityAddressSuffix || '',
                            facilityPostalcode: values.facilityPostalcode,
                            facilityCity: values.facilityCity,
                            facilityPhonenumber: values.facilityPhonenumber || '',
                            facilityEmailAddress: values.facilityEmailAddress || '',
                            facilityWebsite: values.facilityWebsite || ''
                        }

                        editCareFacility({
                            authToken: newToken.value,
                            data: {
                                ...data,
                                facilityId: careFacilityId || ''
                            }
                        })
                    }
                }
            }
            call()
        }
    })

    const LinkDepartmentsFormik = useFormik({
        initialValues: FACILITY_STEP_TWO_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: FACILITY_STEP_TWO_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || ''
        ),
        onSubmit: () => {
            // do nothing. not part of validation really.
        }
    })

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        let isMounted = true

        const call = async () => {
            if (
                token.valid &&
                careFacilityId
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths
                            .getCareFacility.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getCareFacility({
                            authToken: newToken.value,
                            data: {
                                facilityId: careFacilityId
                            }
                        })
                    }

                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths
                            .getDepartments.path,
                        true
                    )

                    if (isValid2 && newToken.value) {
                        getDepartments({
                            authToken: newToken.value,
                            data: {
                                facilityId: careFacilityId
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
        }
    }

    // THIS FETCH SHOULD NOT HAPPEN ON MOUNT.
    // solution change dependency to a useState. a button where you clicked on it
    // and then unset it.
    useEffect(() => {
        // should only be performed IF the button is clicked.
        if (modeButtonClicked) {
            setMode(
                _.lowerCase(router.location.pathname).includes('edit') ? 'EDIT' : 'VIEW'
            )

            // you go here ideally after submitting the values from the edit mode.
            if (_.lowerCase(router.location.pathname).includes('view')) {
                facilityEditFormik.resetForm()
                // and then fetch the data again just to double check.
                // you only want to do this IF you came from clicking the button.
                fetchData(token)
            }

            // don't forget to set it false after.
            setModeButtonClicked(false)
        }
    }, [router.location.pathname, modeButtonClicked])

    // ONLY fetch it when obj.needAccordion is true AND if isSuccess is false.
    useEffect(() => {
        if (careFacilityId) {
            setShowRecentlyUploaded(false)
            return fetchData(token)
        } else {
            return () => {}
        }
    }, [token.id, token.valid, careFacilityId])

    useEffect(() => {
        if (getCareFacilityMutation.data) {
            const data = getCareFacilityMutation.data
            facilityEditFormik.setValues({
                ...facilityEditFormik.values,
                ...data.data
            })
        }
    }, [getCareFacilityMutation.data])

    useEffect(() => {
        if (getCareFacilityMutation.error) {
            const message = getErrorText(getCareFacilityMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getCareFacilityMutation.error])

    useEffect(() => {
        if (getDepartmentsMutation.error) {
            const message = getErrorText(getDepartmentsMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getDepartmentsMutation.error])

    useEffect(() => {
        const data = editCareFacilityMutation.data

        if (data) {
            if (data.status === 'OK') {
                // close component by clearing the edit formik.
                toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                // facilityEditFormik.resetForm()
                fetchData(token)
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [editCareFacilityMutation.data])

    useEffect(() => {
        if (editCareFacilityMutation.error) {
            const message = getErrorText(editCareFacilityMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [editCareFacilityMutation.error])

    const [updateProfilePictureFacility, updateProfilePictureFacilityMutation] =
    useUpdateProfilePictureFacilityMutation()
    const fileInputRef = useRef<HTMLInputElement>(null)

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0]
        if (selectedFile) {
            setFile(selectedFile)
        }
    }

    const handleUpload = async () => {
        if (file) {
            const formData = new FormData()
            formData.append('profilePicture', file)

            const newToken = await revalidateToken({
                value: token.value,
                id: token.id
            }, token.mode)

            const foundApiPath = validateAPIPath(
                activeModules.arr,
                MODULE_TABLE.fmt.moduleName,
                MODULE_TABLE.fmt.apiPaths.updateProfilePictureFacility.path,
                true
            )

            if (foundApiPath) {
                updateProfilePictureFacility({
                    facilityId: careFacilityId || '',
                    data: formData,
                    authToken: newToken.value
                }).unwrap().then((data) => {
                    if (data.status === 'OK') {
                    // toast success message.
                        toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                        // we need to use this token now so set it.
                        setShowRecentlyUploaded(true)
                    } else {
                        toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                    }
                })
            }
        }
    }

    useEffect(() => {
        handleUpload()
    }, [file])

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: CareFacilityKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                facilityEditFormik.handleSubmit()
            }
        }
    }

    useEffect(() => {
        if (updateProfilePictureFacilityMutation.error) {
            const message = getErrorText(updateProfilePictureFacilityMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [updateProfilePictureFacilityMutation.error])

    const FacilityNameInput = useMemo(() => {
        return <FacilityName
            readOnly={mode === 'VIEW'}
            facilityEditFormik={facilityEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'facilityPostalcode')
            }} />
    }, [
        mode,
        strings,
        facilityEditFormik.values.facilityName,
        facilityEditFormik.errors.facilityName
    ])

    const PostalCodeInput = useMemo(() => {
        return <PostalCode
            readOnly={mode === 'VIEW'}
            facilityEditFormik={facilityEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'facilityAddressNumber')
            }} />
    }, [
        mode,
        strings,
        facilityEditFormik.values.facilityPostalcode,
        facilityEditFormik.errors.facilityPostalcode
    ])

    const AddressInput = useMemo(() => {
        return <Address
            readOnly={mode === 'VIEW'}
            facilityEditFormik={facilityEditFormik}
            handleKeyDown={handleKeyDown}/>
    }, [
        mode,
        strings,
        facilityEditFormik.values.facilityAddressNumber,
        facilityEditFormik.errors.facilityAddressNumber,
        facilityEditFormik.values.facilityAddressSuffix,
        facilityEditFormik.errors.facilityAddressSuffix
    ])

    const StreetNameInput = useMemo(() => {
        return <StreetName
            readOnly={mode === 'VIEW'}
            facilityEditFormik={facilityEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'facilityCity')
            }} />
    }, [
        mode,
        strings,
        facilityEditFormik.values.facilityAddressStreet,
        facilityEditFormik.errors.facilityAddressStreet
    ])

    const CityInput = useMemo(() => {
        return <City
            readOnly={mode === 'VIEW'}
            facilityEditFormik={facilityEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'facilityPhonenumber')
            }} />
    }, [
        mode,
        strings,
        facilityEditFormik.values.facilityCity,
        facilityEditFormik.errors.facilityCity
    ])

    /** GROUP 2 */

    const GeneralPhoneNumberInput = useMemo(() => {
        return <GeneralPhoneNumber
            readOnly={mode === 'VIEW'}
            facilityEditFormik={facilityEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'facilityEmailAddress')
            }} />
    }, [
        mode,
        strings,
        facilityEditFormik.values.facilityPhonenumber,
        facilityEditFormik.errors.facilityPhonenumber
    ])

    const EmailInput = useMemo(() => {
        return <Email
            readOnly={mode === 'VIEW'}
            facilityEditFormik={facilityEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'facilityWebsite')
            }} />
    }, [
        mode,
        strings,
        facilityEditFormik.values.facilityEmailAddress,
        facilityEditFormik.errors.facilityEmailAddress
    ])

    const WebsiteInput = useMemo(() => {
        return <Website
            readOnly={mode === 'VIEW'}
            facilityEditFormik={facilityEditFormik}
            handleKeyDown={(e) => {
                if (e.key === 'Enter') { facilityEditFormik.handleSubmit() }
            }} />
    }, [
        mode,
        strings,
        facilityEditFormik.values.facilityWebsite,
        facilityEditFormik.errors.facilityWebsite
    ])

    const CancelButton = <button type={'button'}
        className={[
            'btn btn-secondary w-100 py-2'
        ].join(' ')}
        onClick={() => {
            // if you made changes to the formik, show a warning first via popup
            const obj = facilityEditFormik.touched

            interface FormikTouched {
                [key: string]: boolean;
              }

            function hasTrueValue (obj: FormikTouched) {
                for (const key in obj) {
                    if (obj[key] === true) {
                        return true
                    }
                }
                return false
            }

            const hasTrue = hasTrueValue(obj)
            if (hasTrue) {
                // show warning pls
                setShowCancelWarning(true)
            } else {
                // go back to view
                const isValid = validateRoute(
                    activeModules.arr,
                    MODULE_TABLE.fmt.moduleName,
                    MODULE_TABLE.fmt.routes.viewCareFacility,
                    true
                )
                setModeButtonClicked(true)

                dispatch(push(
                    _.replace(isValid.route,
                        ':careFacilityId',
                        careFacilityId || ''
                    )
                ))
            }
        }}>
        {strings.app?.text.cancel}
    </button>
    const LoadingContent = (
        <div className={'spinner-container'}>
            <span className={'spinner-border spinner-border-sm'}></span>
            <span className={'ms-2'}>{
                strings.app?.text.loading || ''
            }</span>
        </div>
    )
    const SubmitButton = <button type={'button'}
        disabled={
        // step 1 shouldn't be disabled here because of formik.
            false
        // step 2 can be skipped entirely.
        }
        className={[
            'btn btn-primary w-100 fw-semibold py-2'
        ].join(' ')}
        onClick={() => {
            facilityEditFormik.handleSubmit()
        }}>
        { editCareFacilityMutation.isLoading
            ? LoadingContent
            : strings.app?.text.save}
    </button>

    const EditButton = <button type={'button'}
        className={[
            'btn btn-secondary w-100 py-2'
        ].join(' ')}
        onClick={() => {
            const isValid = validateRoute(
                activeModules.arr,
                MODULE_TABLE.fmt.moduleName,
                MODULE_TABLE.fmt.routes.editCareFacility,
                true
            )

            setModeButtonClicked(true)

            dispatch(push(
                _.replace(isValid.route,
                    ':careFacilityId',
                    careFacilityId || ''
                )
            ))
        }}>
        {strings.fmt?.text.department.details.edit}
    </button>

    const ChangeProfilePictureButton = <>
        <input
            type={'file'}
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
        />
        <button type={'button'}
            className={[
                'btn btn-secondary w-100 mt-5'
            ].join(' ')}
            onClick={() => {
                if (fileInputRef.current) {
                    fileInputRef.current.click()
                }
            }}>
            { editCareFacilityMutation.isLoading
                ? LoadingContent
                : strings.fmt?.text.department.details.change}
        </button>
    </>

    /** display list of departments here with the data table template. */

    const result = <div className={'row my-8'}>
        <div className={'col-12 col-lg'}>
            <div className={'edit-interface'}>
                <h2 className={'mb-5 fw-semibold '} >{strings.fmt
                    ?.text.facility.menu.facility_data}</h2>
                {/* group one */}
                <div className={'mb-6'}>
                    <h4 className={'mb-6'}>{strings.fmt?.text.facility.menu
                        .name_and_address_details}</h4>
                    {FacilityNameInput}
                    {PostalCodeInput}
                    {AddressInput}
                    {StreetNameInput}
                    {CityInput}
                </div>
                {/* group two */}

                <div>
                    <h4 className={'mb-6'}>{strings.fmt
                        ?.text.facility.menu.contact_details}</h4>
                    {GeneralPhoneNumberInput}
                    {EmailInput}
                    {WebsiteInput}
                </div>

                <div>
                    <LinkDepartmentDropdown
                        LinkDepartmentsFormik={LinkDepartmentsFormik}
                        careFacilityId={careFacilityId || ''}
                    />
                </div>

                {mode === 'EDIT' && <div className={'container button-container'}>
                    <div className={'row align-items-center mt-6'}>
                        <div className={'col-6 col-lg-4 col-md-6 offset-lg-4 offset-md-0'}>
                            {CancelButton}
                        </div>
                        <div className={'col-6 col-lg-4 col-md-6'}>
                            {SubmitButton}
                        </div>
                    </div>
                </div>}

                {mode === 'VIEW' && <div className={'container button-container'}>
                    <div className={'align-items-center justify-content-end mt-6 row'}>
                        <div className={'col-6 col-lg-4 col-md-6'}>
                            {EditButton}
                        </div>
                    </div>
                </div>}
            </div>
        </div>
        <div className={'col-12 col-lg-auto mt-6 mt-lg-0'}>
            <div className={'picture-interface'}>
                <h2 className={'mb-5 fw-semibold '} >{strings.fmt?.text
                    .department.details.image}</h2>
                <div className={'text-center'}>

                    <div className={'img-wrapper mx-auto mb-2'}>

                        {<ProfilePic
                            showRecentlyUploaded={showRecentlyUploaded}
                            updateProfilePictureFacilityMutation={{
                                data: updateProfilePictureFacilityMutation.data
                            }}
                            getCareFacilityMutation={getCareFacilityMutation}
                        />}

                    </div>
                    {
                        mode === 'EDIT' && ChangeProfilePictureButton
                    }
                </div>
            </div>
        </div>

    </div>

    return <div className={'facility-page'}>

        {/* dashboard responsive header */}
        <DashboardResponsiveHeader />

        <div className={'main-content'}>

            <div className={'container-fluid mx-auto'}>
                <div className={'row justify-content-between pt-16 pb-0 px-5'}>
                    <div className={'col-auto'}>
                        <a onClick={(e) => {
                            e.preventDefault()
                            dispatch(back())
                        }} className={'btn btn-round btn-sm-md'}>
                            <i
                                className={'fa-light fa-arrow-left'}
                                aria-hidden={'true'}>
                            </i>
                        </a>
                    </div>
                    <div className={'col-auto'}>
                        <h1 className={'my-0'}>{
                            getCareFacilityMutation.data?.data.facilityName
                        }</h1>
                    </div>
                    <div className={'col-auto'}>
                        <a href={'#'}
                            onClick={(e) => {
                                e.preventDefault()
                            }} className={[
                                'btn btn-round btn-sm-md invisible'
                            ].join(' ')}>
                            <i
                                className={'fa-light fa-arrow-right'}
                                aria-hidden={'true'}
                            >
                            </i>
                        </a>
                    </div>
                </div>
                <div className={'row justify-content-between mt-5'}>
                    <div className={'col'}>
                        <div className={
                            'container-fluid px-5 px-sm-3 facility-body'
                        }>
                            <div className={'h-100 row'}>
                                <div className={' col-12 mx-auto'}>
                                    {result}
                                </div>
                            </div>
                        </div>

                        <div className={['container-fluid px-5 px-sm-3',
                            ' facility-body list-interface'].join(' ')}>
                            <div className={'h-100 row'}>
                                <div className={' col-12 mx-auto'}>
                                    <h5 className={'fw-semibold'}>{strings.fmt
                                        ?.text.facility.add.departments}</h5>
                                    <DepartmentsViewTable
                                        mode={mode}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        responseData={getDepartmentsResponse}
                                        fixedCacheKey={fixedCacheKey}/>
                                </div>
                            </div>
                        </div>

                        <Modal centered={true} isOpen={showCancelWarning} toggle={() => {
                            setShowCancelWarning(!showCancelWarning)
                        }}>
                            <ModalHeader className={'justify-content-between'} toggle={() => {
                                setShowCancelWarning(!showCancelWarning)
                            }} close={ <a className={'btn btn-round '}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowCancelWarning(false)
                                }}>
                                <i className={'fa-light fa-multiply'} aria-hidden={'true'}>
                                </i>
                            </a>}
                            >
                                <span className={'h5'}>{
                                    strings.fmt?.message?.data_edit_warning?.title || ''
                                }</span>
                            </ModalHeader>
                            <ModalBody>
                                {/* content changes if idle / expire sessions expired. */}
                                <small className={'d-block my-4'}>
                                    {strings.fmt?.message?.data_edit_warning?.content || ''}
                                </small>
                                <div className={'row justify-content-end'}>
                                    <div className={'col-auto mb-2 mb-md-0'}>
                                        <button
                                            type={'button'}
                                            className={'btn btn-primary w-100'}
                                            onClick={ () => {
                                                setShowCancelWarning(false)
                                            }}>
                                            {strings.app?.text?.no || ''}
                                        </button>
                                    </div>
                                    <div className={'col-auto'}>
                                        <button type={'button'} onClick={() => {
                                            const isValid = validateRoute(
                                                activeModules.arr,
                                                MODULE_TABLE.fmt.moduleName,
                                                MODULE_TABLE.fmt.routes.viewCareFacility,
                                                true
                                            )

                                            setModeButtonClicked(true)

                                            dispatch(push(
                                                _.replace(isValid.route,
                                                    ':careFacilityId',
                                                    careFacilityId || ''
                                                )
                                            ))

                                            setShowCancelWarning(false)
                                        }} className={'btn btn-primary w-100'}>
                                            {strings.app?.text.yes}
                                        </button>

                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    </div>
                </div>

            </div>

        </div>

        <div
            className={'position-fixed bottom-0 end-0 pe-5 fs-label fw-light version-text'}
        >
            {MODULE_VERSION}
        </div>
    </div>
}

export default FacilityDetailsInterface
