
import { ReactElement, useRef, useState } from 'react'
// eslint-disable-next-line max-len
import { BuildingBlock } from '@careplan/type'
import BuildingBlockProgressChart from '@doc/components/patients/details/components/modal/ReadonlyCareplanStep/components/BuildingBlockProgressChart'
import { GPACareplanStepIdDetails } from '@doc/type'

interface ComponentProps {
    result: ReactElement<any, any>,
    printHr: boolean
    showProgressCard: boolean
    foundProgressData: {
        buildingBlock: {
            buildingBlockId: string;
            buildingBlockType: string;
            buidingBlockOrder: number;
            buildingBlockValue: GPACareplanStepIdDetails['content']['contentData']['buildingBlockValue'];
        } | undefined;
        buildingBlockId: string;
        updatedOn: number;
        careplanStepProgress: number;
        miscVar: any;
    }[]
    buildingBlock: BuildingBlock
}
const BuildingBlockContent = ({
    result, printHr, showProgressCard, foundProgressData, buildingBlock
}: ComponentProps) => {
    const blockRefs = useRef<{ [key: string]: HTMLDivElement | null }>({})

    const [hover, setHover] = useState(false)

    const handleScroll = (id: string) => {
        const block = blockRefs.current[id]
        if (block) {
            block.scrollIntoView({ behavior: 'smooth' })
        }
    }

    return <>
        {/* building block content goes here */}
        <div className={['building-block-content col', hover ? 'hovered' : ''].join(' ')}>
            {result}
            {/* {printHr && <hr/>} */}
            {printHr && <></>}
        </div>
        {
            showProgressCard && <div className={'careplan-progress-links col-auto py-0'} onMouseOver={() => {
                setHover(true)
            }} onMouseOut={() => {
                setHover(false)
            }}>
                <div className={'progress-block m-2 rounded-4 clickable mt-0'}
                    onClick={() => {
                        handleScroll(foundProgressData[0].buildingBlockId)
                    }}>
                    <span className={'d-block'}>
                        {buildingBlock?.buildingBlockType}
                    </span>
                    {/* then timestamp and finally the number */}
                    {/* turn this into a line chart instead. */}
                    {
                        foundProgressData.length
                            ? <div>
                                <BuildingBlockProgressChart arr={foundProgressData} />
                            </div>
                            : ''
                    }
                    {/* <div className={'row'}>
                                <div className={'col'}>
                                    <span>
                                        {buildingBlock?.buildingBlockOrder}
                                    </span>
                                    <span className={'ps-1'}>{
                                        format(fromUnixTime(foundProgressData[0]?.updatedOn || 0), dateFormats.format9)
                                    }</span>
                                </div>
                                <div className={'col-auto'}>
                                    <span> {[
                                        (foundProgressData[0]?.careplanStepProgress || 0).toFixed(2),
                                        '%'
                                    ].join('')}</span>
                                </div>
                            </div> */}
                </div>
            </div>

        }
    </>
}
export default BuildingBlockContent
