import React, { ReactElement } from 'react'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'

import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { defaultDepartmentCareFaciitiesSelection } from '@doc/components/patients/add/AddInterface'
import StepFour from '@doc/components/patients/add/steps/StepFour/Main'
import StepOne from '@doc/components/patients/add/steps/StepOne'
import StepThree from '@doc/components/patients/add/steps/StepThree/Main'
import StepTwo from '@doc/components/patients/add/steps/StepTwo/Main'
import { selectPatientMenu, setPatientMenu } from '@doc/slice'
import {
    AddPatientResponse,
    CareplanValues,
    DepartmentCareFacilitiesAction,
    DepartmentCareFacilitiesState,
    MakeCareplanResponse,
    PatientValues
} from '@doc/type'
import { FormikProps } from 'formik'
import _ from 'lodash'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

interface ComponentProps {
    fetchData: (token: TokenData) => () => void
    patientAddStepOneFormik: FormikProps<Pick<PatientValues,
    'firstName' | 'lastName' | 'email' | 'patientNumber'>>,
    patientAddStepTwoFormik: FormikProps<{
        careFacilityId: string;
        departmentId: string;
    }>,
    makeCareplanFormik: FormikProps<CareplanValues>,
    addPatientData: {
        isLoading: boolean
        data: AddPatientResponse | undefined
    }
    makeCareplanData: {
        isLoading: boolean
        data: MakeCareplanResponse | undefined
    }
    departmentCareFacilitiesState: DepartmentCareFacilitiesState,
    dispatchDepartmentCareFacilitiesAction: React.Dispatch<DepartmentCareFacilitiesAction>,
}
// won't put formik here because users can unclick and the data would be
// deinitialized.

const NextButton = ({
    fetchData, patientAddStepOneFormik, patientAddStepTwoFormik, makeCareplanFormik,
    addPatientData, makeCareplanData
}:Omit<ComponentProps, 'departmentCareFacilitiesState' | 'dispatchDepartmentCareFacilitiesAction'>) => {
    const strings = useAppSelector(selectStrings)
    const token = useAppSelector(selectToken)
    const patientMenu = useAppSelector(selectPatientMenu)
    const dispatch = useAppDispatch()

    const LoadingContent = (
        <div className={'spinner-container'}>
            <span className={'spinner-border spinner-border-sm'}></span>
            <span className={'ms-2'}>{
                strings.app?.text.loading || ''
            }</span>
        </div>
    )

    return <button type={'button'}
        disabled={
            // step 1 shouldn't be disabled here because of formik.
            false
            // step 2 can be skipped entirely.
        }
        className={[
            'btn btn-primary w-100 fw-semibold py-2'
        ].join(' ')}
        onClick={async () => {
            // different logic based on current step.
            if (patientMenu.currentStep === 1) {
                // validate specific fields first.
                patientAddStepOneFormik.handleSubmit()
            } else if (patientMenu.currentStep === 2) {
                // dispatch(setPatientMenu({
                //     ...patientMenu,
                //     currentStep: patientMenu.currentStep + 1
                // }))
                patientAddStepTwoFormik.handleSubmit()
            } else if (patientMenu.currentStep === 3) {
                makeCareplanFormik.handleSubmit()
            } else if (patientMenu.currentStep === patientMenu.steps.length) {
                dispatch(setPatientMenu({
                    ...patientMenu,
                    showAddModal: false
                }))
                fetchData(token)
            }
        }}>
        { addPatientData.isLoading || makeCareplanData.isLoading
            ? LoadingContent
            : patientMenu.currentStep === patientMenu.steps.length
                ? strings.app?.text.close
                : strings.app?.text.next}
    </button>
}

const AddModal = ({
    fetchData, patientAddStepOneFormik, patientAddStepTwoFormik, makeCareplanFormik,
    addPatientData, makeCareplanData, departmentCareFacilitiesState,
    dispatchDepartmentCareFacilitiesAction
}:ComponentProps) => {
    const dispatch = useAppDispatch()

    const patientMenu = useAppSelector(selectPatientMenu)

    // const CancelButton = patientMenu.currentStep === patientMenu.steps.length
    //  && <button type={'button'}
    //     className={[
    //         'btn btn-dark btn-outline-white w-100'
    //     ].join(' ')}
    //     onClick={() => {
    //     }}>
    //     {strings.app?.text.cancel}
    // </button>

    // const SkipButton = (patientMenu.currentStep === 2 || patientMenu.currentStep === 3) &&
    // <button type={'button'}
    //     className={[
    //         'btn btn-dark btn-outline-white w-100'
    //     ].join(' ')}
    //     onClick={() => {
    //         if (patientMenu.currentStep <  patientMenu.steps.length) {
    //             dispatch(setPatientMenu({
    //                 ...patientMenu,
    //                 currentStep: patientMenu.currentStep + 1
    //             }))
    //         }
    //     }}>
    //     {strings.app?.text.skip}
    // </button>

    const result = <Modal
        onClosed={() => {
        // make sure to reset to step 1 once unmounted.
            if (patientMenu.currentStep === patientMenu.steps.length) {
                dispatch(setPatientMenu({
                    ...patientMenu,
                    currentStep: 1
                }))

                // reset form upon their request.
                patientAddStepOneFormik.resetForm()
                patientAddStepTwoFormik.resetForm()
                dispatchDepartmentCareFacilitiesAction({
                    type: 'UPDATE_MAIN',
                    payload: _.cloneDeep(defaultDepartmentCareFaciitiesSelection)
                })
                makeCareplanFormik.resetForm()
            }
        }} size={'xxl'} cssModule={{
            modal: 'modal add-patient-form'
        }} centered={true} isOpen={patientMenu.showAddModal} toggle={() => {
            dispatch(setPatientMenu({
                ...patientMenu,
                showAddModal: !patientMenu.showAddModal
            }))
        }}>
        <ModalHeader tag={'div'} cssModule={{
            'modal-title': 'modal-title w-100',
            'modal-header': 'align-items-baseline modal-header '
        }} toggle={() => {
            dispatch(setPatientMenu({
                ...patientMenu,
                showAddModal: !patientMenu.showAddModal
            }))
        }} close={ <a className={'btn btn-round'}
            onClick={(e) => {
                e.preventDefault()
                dispatch(setPatientMenu({
                    ...patientMenu,
                    showAddModal: false
                }))
            }}>
            <i className={'fa-light fa-multiply'} aria-hidden={'true'}>
            </i>
        </a>}
        >
            <div className={'align-items-center justify-content-center row w-100 g-3'}>
                {
                    patientMenu.steps.map((step, index) => {
                        const stepCircleClasses = ['step-indicator']
                        if (patientMenu.currentStep === step.number) {
                            stepCircleClasses.push('active')
                        }

                        if (patientMenu.currentStep > step.number) {
                            stepCircleClasses.push('finished')
                        }

                        const stepTextClasses = ['step-active']

                        if (patientMenu.currentStep === step.number) {
                            stepTextClasses.push('current')
                        }

                        let text: ReactElement<any, any> = <>{step.number}</>

                        if (patientMenu.currentStep > step.number) {
                            text = <i className={'fa-light fa-check'} aria-hidden={'true'}>
                            </i>
                        }

                        return (<React.Fragment key={index} >
                            <div className={'col-auto'}>
                                <div className={stepCircleClasses.join(' ')}>
                                    {text}
                                </div>
                                <span className={stepTextClasses.join(' ')}>{step.name}</span>
                            </div>
                            { index < patientMenu.steps.length - 1 && <div className={
                                'col'
                            }>
                                <div className={'step-divider'}></div>
                            </div> }
                        </React.Fragment>

                        )
                    })}
            </div>
        </ModalHeader>
        <ModalBody>
            <div className={'pt-5 px-5 px-lg-5'}>
                {patientMenu.currentStep === 1 &&
                <StepOne patientAddStepOneFormik={patientAddStepOneFormik}/> }
                {patientMenu.currentStep === 2 &&
                <StepTwo
                    patientAddStepTwoFormik={patientAddStepTwoFormik}
                    departmentCareFacilitiesState={departmentCareFacilitiesState}
                    dispatchDepartmentCareFacilitiesAction={dispatchDepartmentCareFacilitiesAction}
                /> }
                {patientMenu.currentStep === 3 &&
                <StepThree
                    departmentCareFacilitiesState={departmentCareFacilitiesState}
                    dispatchDepartmentCareFacilitiesAction={dispatchDepartmentCareFacilitiesAction}
                /> }
                {patientMenu.currentStep === 4 &&
                <StepFour
                    patientAddStepOneFormik={patientAddStepOneFormik}
                    patientAddStepTwoFormik={patientAddStepTwoFormik}
                    makeCareplanFormik={makeCareplanFormik}
                    departmentCareFacilitiesState={departmentCareFacilitiesState}
                    dispatchDepartmentCareFacilitiesAction={dispatchDepartmentCareFacilitiesAction}
                /> }
            </div>
        </ModalBody>
        <ModalFooter>
            <div className={'w-100'}>
                <footer className={'footer wave-background d-flex align-items-end px-5'}>
                    <div className={'container pb-5'}>
                        <div className={'row align-items-center'}>
                            <div className={'col-6 col-lg-4 col-md-6 offset-lg-4 offset-md-0'}>
                                {/* {SkipButton} */}
                                {/* {CancelButton} */}
                            </div>
                            <div className={'col-6 col-lg-4 col-md-6'}>
                                {<NextButton
                                    fetchData={fetchData}
                                    patientAddStepOneFormik={patientAddStepOneFormik}
                                    patientAddStepTwoFormik={patientAddStepTwoFormik}
                                    makeCareplanFormik={makeCareplanFormik}
                                    addPatientData={addPatientData}
                                    makeCareplanData={makeCareplanData}
                                />}
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </ModalFooter>
    </Modal>

    return <>{result}</>
}

export default AddModal
