import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { TokenData } from '@app/types/type.token'
import { useEditPatientMutation, useGetPatientMutation, useGetPatientsMutation } from '@doc/api'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import { PATIENT_STEP_ONE_VALIDATION_SCHEMA } from '@doc/constants'
import { GetPatientsResponse, Patient, PatientKeys } from '@doc/type'
import { push } from '@lagunovsky/redux-react-router'
import { useFormik } from 'formik'
import _ from 'lodash'

import Active from '@doc/components/patients/details/inputs/Active'
import Email from '@doc/components/patients/details/inputs/Email'
import FirstName from '@doc/components/patients/details/inputs/FirstName'
import LastName from '@doc/components/patients/details/inputs/LastName'
import PatientNumber from '@doc/components/patients/details/inputs/PatientNumber'

const AccordionComponent = (
    { getPatientsMutation, currentPage, search, mode, isOpen, obj, fixedCacheKey }:
     {
        getPatientsMutation: {
            isLoading: boolean;
            isSuccess: boolean;
            responseData: GetPatientsResponse | undefined;
        }
        currentPage: number;
        search: string,
        mode: 'EDIT' | 'VIEW',
        isOpen?: boolean, obj: Patient,
        fixedCacheKey:'shared-getPatients-key' | 'details-getTreatmentPatients-key' }) => {
    const token = useAppSelector(selectToken)

    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const validateRoute = useValidateRoute()
    const dispatch = useAppDispatch()

    const [getPatient, getPatientMutation] = useGetPatientMutation()
    // accordion components are now editable.
    const [editPatient, editPatientMutation] = useEditPatientMutation()

    // 'details-getTreatmentPatients-key' VS 'shared-getPatients-key'
    const [getPatients] = useGetPatientsMutation({
        ...(fixedCacheKey === 'shared-getPatients-key' && {
            fixedCacheKey: 'shared-getPatients-key'
        })
    })

    const patientEditFormik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            patientNumber: '',
            active: false
        },
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: PATIENT_STEP_ONE_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || ''
        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken(
                        {
                            value: token.value,
                            id: token.id
                        },
                        token.mode
                    )
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths.editPatient.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        editPatient({
                            authToken: newToken.value,
                            data: {
                                userId: obj.userId || '',
                                firstName: values.firstName || '',
                                lastName: values.lastName || '',
                                email: values.email || '',
                                patientNumber: values.patientNumber || '',
                                active: values.active || false
                            }
                        })
                    }
                }
            }
            call()
        }
    })

    useEffect(() => {
        const data = editPatientMutation.data

        if (data) {
            if (data.status === 'OK') {
                // don't do anything.

                if (fixedCacheKey === 'shared-getPatients-key') {
                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getPatients.path,
                        true
                    )

                    if (isValid2) {
                        const skip = currentPage * (getPatientsMutation.responseData
                            ?.data.limit || 1)

                        const totalRecords = (getPatientsMutation.responseData
                            ?.data.totalRecords || 0) - 1

                        getPatients({
                            authToken: token.value,
                            data: {
                                skip: skip > (getPatientsMutation.responseData?.data
                                    .totalRecords || 0)
                                    ? totalRecords
                                    : skip,
                                limit: getPatientsMutation.responseData?.data.limit,
                                search
                            }
                        })
                    }
                }
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [editPatientMutation.data])

    useEffect(() => {
        if (editPatientMutation.error) {
            const message = getErrorText(editPatientMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [editPatientMutation.error])

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        let isMounted = true

        const call = async () => {
            if (
                token.valid &&
                isOpen === true &&
                getPatientMutation.isSuccess === false
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getPatientId.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getPatient({
                            authToken: newToken.value,
                            data: {
                                userId: obj.userId
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
        }
    }

    // ONLY fetch it when obj.needAccordion is true AND if isSuccess is false.
    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid, isOpen, getPatientMutation.isSuccess])

    useEffect(() => {
        if (getPatientMutation.data) {
            const data = getPatientMutation.data
            patientEditFormik.setValues({
                firstName: data.data.patientPersonalData.patientData[0]?.userData[0]
                    ?.firstName || '',
                lastName: data.data.patientPersonalData.patientData[0]?.userData[0]?.lastName || '',
                email: data.data.patientPersonalData.patientData[0]?.userData[0]?.email || '',
                patientNumber: data.data.patientPersonalData.patientData[0]
                    ?.userData[0]?.patientNumber || '',
                active: data.data.patientPersonalData.patientData[0]
                    ?.userData[0]?.active || false
            })
        }
    }, [getPatientMutation.data])

    useEffect(() => {
        if (getPatientMutation.error) {
            const message = getErrorText(getPatientMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPatientMutation.error])

    const ViewButton = <button
        type={'button'}
        className={'btn btn-secondary w-100'}
        onClick={(e) => {
            e.stopPropagation()

            const isValid = validateRoute(
                activeModules.arr,
                MODULE_TABLE.doc.moduleName,
                MODULE_TABLE.doc.routes.viewPatient,
                true
            )

            dispatch(push(
                _.replace(isValid.route,
                    ':userId',
                    obj.userId
                )
            ))
        }}
    >
        {strings.doc?.text.patient.patient_menu.accordion.view_patient}
    </button>

    const LoadingContent = (
        <div className={'spinner-container'}>
            <span className={'spinner-border spinner-border-sm'}></span>
            <span className={'ms-2'}>{
                strings.app?.text.loading || ''
            }</span>
        </div>
    )
    const SubmitButton = <button type={'button'}
        disabled={
        // step 1 shouldn't be disabled here because of formik.
            false
        // step 2 can be skipped entirely.
        }
        className={[
            'btn btn-primary w-100 px-8'
        ].join(' ')}
        onClick={() => {
            patientEditFormik.handleSubmit()
        }}>
        { editPatientMutation.isLoading
            ? LoadingContent
            : strings.app?.text.save}
    </button>

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: PatientKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                patientEditFormik.handleSubmit()
            }
        }
    }

    const FirstNameInput = useMemo(() => {
        return <FirstName
            readOnly={mode === 'VIEW'}
            patientEditFormik={patientEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'firstName')
            }}
        />
    }, [
        mode,
        strings,
        patientEditFormik.values.firstName,
        patientEditFormik.errors.firstName
    ])

    const LastNameInput = useMemo(() => {
        return <LastName
            readOnly={mode === 'VIEW'}
            patientEditFormik={patientEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'lastName')
            }}
        />
    }, [
        mode,
        strings,
        patientEditFormik.values.lastName,
        patientEditFormik.errors.lastName
    ])

    const EmailInput = useMemo(() => {
        return <Email
            readOnly={mode === 'VIEW'}
            patientEditFormik={patientEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'email')
            }}
        />
    }, [
        mode,
        strings,
        patientEditFormik.values.email,
        patientEditFormik.errors.email
    ])

    const PatientNumberInput = useMemo(() => {
        return <PatientNumber
            readOnly={mode === 'VIEW'}
            patientEditFormik={patientEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'patientNumber')
            }}
        />
    }, [
        mode,
        strings,
        patientEditFormik.values.patientNumber,
        patientEditFormik.errors.patientNumber
    ])

    const ActiveSwitch = useMemo(() => {
        return <Active
            readOnly={mode === 'VIEW'}
            patientEditFormik={patientEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'patientNumber')
            }}
        />
    }, [
        mode,
        strings,
        patientEditFormik.values.active,
        patientEditFormik.errors.active
    ])

    return <>
        {
            getPatientMutation.isLoading && <small className={'d-block text-center py-2'}>
                <div className={'spinner-container'}>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{
                        strings.app?.text.loading || ''
                    }</span>
                </div>
            </small>
        }
        {getPatientMutation.isSuccess && <div className={'px-16'}>
            <h5 className={'col fw-semibold'}>{strings.doc?.text
                .patient.patient_records.patient}</h5>
            {FirstNameInput}
            {LastNameInput}
            {EmailInput}
            {PatientNumberInput}
            {ActiveSwitch}
            {mode === 'EDIT' && <div className={'container button-container'}>
                <div className={'row align-items-center mt-6'}>
                    <div className={'col-6 col-lg-4 col-md-6 offset-lg-4 offset-md-0'}>
                        {ViewButton}
                    </div>
                    <div className={'col-6 col-lg-4 col-md-6'}>
                        {SubmitButton}
                    </div>
                </div>
            </div>}
        </div>}
    </>
}

export default AccordionComponent
