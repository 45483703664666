import {
    ACTION_MUTATION_PROMISE,
    MOBILE_RESPONSIVE_LIMIT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRouteAll } from '@login/MutationProvider/validateRouteAll'

import { selectCurrentWorkflowStep, selectWorkflowId } from '@login/slices/slice.workflow'
import GetWorkflowConsumer from '@login/workflow/GetWorkflowConsumer'
import {
    useGetOverallProgressMutation,
    useGetWorkflowMutation,
    useUpdateWorkflowProgressMutation
} from '@reasonWithMe/api'
import CreateCareplanConsumer from '@reasonWithMe/components/CreateCareplanConsumer'
// eslint-disable-next-line max-len
import RecommendationConditionalButtons from '@reasonWithMe/components/resultsPage/RecommendationConditionalButtons'
import { MODULE_VERSION } from '@reasonWithMe/constants'
import { selectQuestionInterface } from '@reasonWithMe/slice'
import {
    CurrentReasoning,
    MakeTherapeuticsActions,
    MakeTherapeuticsState,
    TherapeuticsCheck
} from '@reasonWithMe/type'

import { HeaderImage } from '@stylesheet/globalStyles/group/endUser/reasonWithMe/Components'
import produce from 'immer'
import _ from 'lodash'
import { useEffect, useMemo, useReducer } from 'react'
import { toast } from 'react-toastify'

import { MODULE_TABLE } from '@app/app.config'
import FixedImage from '@app/components/FixedImage'
import { TokenData } from '@app/types/type.token'
import { replace } from '@lagunovsky/redux-react-router'
import { useMediaQuery } from 'react-responsive'

const MainReasoningOutcome = (
    {
        questionInterface
    }:{
        questionInterface: {
            firstReasoningSetId: string;
            reasoningSetVersion: number;
            currentReasonWithMeResponse: CurrentReasoning;
        }
    }
) => {
    const reasoningSessionId = questionInterface.currentReasonWithMeResponse.results
        ?.reasoningSessionId
    const dataToCheck = questionInterface.currentReasonWithMeResponse.results
        ?.reasoningRecommendation?.reasoningOutcome || []

    const conditionOne = reasoningSessionId && dataToCheck?.length <= 0
    // // if all outcomes have no therapeutics.
    // const conditionTwo = dataToCheck?.length > 0 &&
    // AllReasoningOutcomes.length && _.every(AllReasoningOutcomes, (outcome) => {
    //     return outcome?.recommendedTherapeutics.length === 0
    // })

    const MainRecommendationReasoningOutcome = questionInterface
        .currentReasonWithMeResponse.results?.reasoningOutcome

    // new exception added in 2024.
    // if there is exactly one element in the reasoningOutcome array,
    // and if the reasoningOutcomeDetailedText property is the same as the main one,
    // allow it to be displayed.
    const conditionTwo = reasoningSessionId && dataToCheck.length === 1 && (
        _.isEqual(
            dataToCheck[0]?.reasoningOutcomeDetailedText,
            MainRecommendationReasoningOutcome?.reasoningOutcomeDetailedText
        )
    )

    return (conditionOne || conditionTwo)
        ? <div className={'text-center'}>
            <h1 className={'d-block mb-4 mt-3 p'}>{
                MainRecommendationReasoningOutcome?.reasoningOutcomeDetailedText?.baseHeading
            }</h1>
            <span className={'p d-block'}>{
                MainRecommendationReasoningOutcome
                    ?.reasoningOutcomeDetailedText?.baseOutcome
            }</span>
        </div>
        : <></>
}

function ResultsPage () {
    const token = useAppSelector(selectToken)
    const dispatch = useAppDispatch()

    const initializeSidebarVisibility = useInitializeSidebarVisibility()
    const strings = useAppSelector(selectStrings)
    const questionInterface = useAppSelector(selectQuestionInterface)
    const currentWorkflowStep = useAppSelector(selectCurrentWorkflowStep)
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const workflowId = useAppSelector(selectWorkflowId)
    const validateRouteAll = useValidateRouteAll()
    const activeModules = useAppSelector(selectActiveModules)
    const [getOverallProgress, getOverallProgressMutation] = useGetOverallProgressMutation()

    const unsubscribeGetOverallProgress = () => {
        const unsubscribeMutation = getOverallProgress({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetOverallProgress()

        let getOverallProgressPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.reasonWithMe.moduleName,
                        MODULE_TABLE.reasonWithMe.apiPaths.getOverallProgress.path,
                        true
                    )

                    if (isValid2 && newToken.value) {
                        getOverallProgressPromise = getOverallProgress({
                            authToken: newToken.value
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getOverallProgressPromise && getOverallProgressPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid])

    // an array of media players that don't need a useReducer to track.
    // it only needs the progress number and then seek to its equivalent
    // in the clip

    useEffect(() => {
        const check = questionInterface.currentReasonWithMeResponse.results?.reasoningSessionId
        console.log('checking for reasoning session id: ', check)

        if (!check) {
            let routeToUse = token.details.roid

            if (['/', ''].includes(token.details.roid)) {
                console.warn('Default route changed to login.')
                routeToUse = MODULE_TABLE.login.routes.login
            }

            const defaultRoute = validateRouteAll(activeModules.arr,
                routeToUse, false)
            console.log('route: ', defaultRoute)
            if (defaultRoute.route) {
                dispatch(replace(routeToUse))
            }
        }
    }, [])

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    useEffect(() => {
        initializeSidebarVisibility(false)
    }, [])

    const [getWorkflow, getWorkflowMutation] = useGetWorkflowMutation()
    const [
        updateWorkflowProgress
    ] = useUpdateWorkflowProgressMutation()

    const [makeTherapeuticsState, makeTherapeuticsDispatch] = useReducer(
        (state: MakeTherapeuticsState, action: MakeTherapeuticsActions) => {
            switch (action.type) {
                case 'SET_DATA': {
                    return produce(state, draft => {
                        // yes you could do this but I prefer that you don't
                        // remove the address of the array that was initialized
                        // prior to mount. Instead, just push elements if the building block
                        // content id isn't found.

                        // you can add a dispatch to clear the contents of the array.

                        // step1: get draft arr and push the result IF not found

                        const newObject = {
                            reasoningOutcome: action.value.reasoningOutcome,
                            confidenceLevel: action.value.confidenceLevel,
                            isPassed: action.value.isPassed,
                            arr: []
                        }

                        const findOutcome = _.find(draft.outcomes,
                            (o) => o.reasoningOutcome === action.value.reasoningOutcome &&
                            o.confidenceLevel === action.value.confidenceLevel
                        )

                        if (!findOutcome) {
                            draft.outcomes.push(newObject)
                        }

                        // get object again and do what was originally planned.
                        const findOutcomeAgain = _.find(draft.outcomes,
                            (o) => o.reasoningOutcome === action.value.reasoningOutcome &&
                            o.confidenceLevel === action.value.confidenceLevel
                        )

                        // should return true anyways.
                        if (findOutcomeAgain) {
                            _.forEach(action.value.arr, (obj) => {
                                const therapeuticsId = obj.therapeutic.therapeuticsId
                                if (!_.find(findOutcomeAgain.arr,
                                    (o) => o.therapeutic.therapeuticsId === therapeuticsId)
                                ) {
                                    findOutcomeAgain.arr.push(obj)
                                }
                            })
                        }

                        // and we are done.
                    })
                } case 'SET_IS_INITIALIZING': {
                    return produce(state, draft => {
                        const findOutcome = _.find(draft.outcomes,
                            (o) => o.reasoningOutcome === action.value.reasoningOutcome &&
                            o.confidenceLevel === action.value.confidenceLevel
                        )

                        if (findOutcome) {
                            const findTherapeutic = _.find(findOutcome.arr, o => {
                                return (
                                    o.therapeutic.therapeuticsId === action
                                        .value.obj.therapeuticsId
                                )
                            })

                            if (findTherapeutic) {
                                findTherapeutic.creationProcess.isInitialized = action
                                    .value.obj.isInitialized
                            }
                        }
                    })
                } case 'SET_STATUS_CHECK': {
                    return produce(state, draft => {
                        const findOutcome = _.find(draft.outcomes,
                            (o) => o.reasoningOutcome === action.value.reasoningOutcome &&
                            o.confidenceLevel === action.value.confidenceLevel
                        )

                        if (findOutcome) {
                            const findTherapeutic = _.find(findOutcome.arr, o => {
                                return (
                                    o.therapeutic.therapeuticsId === action
                                        .value.obj.therapeuticsId
                                )
                            })

                            if (findTherapeutic) {
                                findTherapeutic.creationProcess.statusCheck = action
                                    .value.obj.statusCheck
                            }
                        }
                    })
                } case 'SET_CREATE_PROGRESS': {
                    return produce(state, draft => {
                        const findOutcome = _.find(draft.outcomes,
                            (o) => o.reasoningOutcome === action.value.reasoningOutcome &&
                            o.confidenceLevel === action.value.confidenceLevel
                        )

                        if (findOutcome) {
                            const findTherapeutic = _.find(findOutcome.arr, o => {
                                return (
                                    o.therapeutic.therapeuticsId === action
                                        .value.obj.therapeuticsId
                                )
                            })

                            if (findTherapeutic) {
                                findTherapeutic.creationProcess.createProgress = action
                                    .value.obj.createProgress
                            }
                        }
                    })
                } case 'SET_IS_SUCCESS': {
                    return produce(state, draft => {
                        const findOutcome = _.find(draft.outcomes,
                            (o) => o.reasoningOutcome === action.value.reasoningOutcome &&
                            o.confidenceLevel === action.value.confidenceLevel
                        )

                        if (findOutcome) {
                            const findTherapeutic = _.find(findOutcome.arr, o => {
                                return (
                                    o.therapeutic.therapeuticsId === action
                                        .value.obj.therapeuticsId
                                )
                            })

                            if (findTherapeutic) {
                                findTherapeutic.creationProcess.isSuccess = action
                                    .value.obj.isSuccess
                            }
                        }
                    })
                } case 'SET_STAGE_TYPE': {
                    return produce(state, draft => {
                        const findOutcome = _.find(draft.outcomes,
                            (o) => o.reasoningOutcome === action.value.reasoningOutcome &&
                            o.confidenceLevel === action.value.confidenceLevel
                        )

                        if (findOutcome) {
                            const findTherapeutic = _.find(findOutcome.arr, o => {
                                return (
                                    o.therapeutic.therapeuticsId === action
                                        .value.obj.therapeuticsId
                                )
                            })

                            if (findTherapeutic) {
                                findTherapeutic.creationProcess.stageType = action
                                    .value.obj.stageType
                            }
                        }
                    })
                } case 'SET_IS_PASSED': {
                    return produce(state, draft => {
                        const findOutcome = _.find(draft.outcomes,
                            (o) => o.reasoningOutcome === action.value.reasoningOutcome &&
                            o.confidenceLevel === action.value.confidenceLevel
                        )

                        if (findOutcome) {
                            findOutcome.isPassed = action.value.isPassed
                        }
                    })
                }
            }
        }, {
            outcomes: []
        }
    )

    /** should make an interval here to check on the make personal care plan progress
     * but not now. We'll do it after the call is finished just to see if it works.
     */

    /** i'll need the reasoningOutcome with the highest score. */
    const AllReasoningOutcomes = useMemo(() => {
        return _.orderBy(
            questionInterface.currentReasonWithMeResponse
                .results?.reasoningRecommendation?.reasoningOutcome || [],
            (o) => {
                return o.confidenceLevel
            },
            'desc'
        )
    }, [
        questionInterface.currentReasonWithMeResponse
            .results?.reasoningRecommendation?.reasoningOutcome || []
    ])

    // set values to disable the buttons if their makePersonalCareplans are done.
    useEffect(() => {
        const arr: MakeTherapeuticsState['outcomes'] = _.map(
            AllReasoningOutcomes, (outcomes) => {
                const obj = {
                    reasoningOutcome: outcomes.reasoningOutcome,
                    confidenceLevel: outcomes.confidenceLevel,
                    isPassed: false,
                    arr: _.map(
                        outcomes?.recommendedTherapeutics, (o) => {
                            return {
                                therapeutic: o,
                                creationProcess: {
                                    isInitialized: false,
                                    statusCheck: false,
                                    createProgress: 0,
                                    isSuccess: false
                                }
                            }
                        }) as TherapeuticsCheck[]
                }

                return obj
            }
        )

        _.forEach(arr, (o) => {
            makeTherapeuticsDispatch({
                type: 'SET_DATA',
                value: o
            })
        })
    }, [AllReasoningOutcomes])

    // useEffect(() => {
    //     console.log('should only be updated once.')
    // }, [AllReasoningOutcomes])

    // useEffect(() => {
    //     console.log('loops a lot')
    // }, [makeTherapeuticsState.outcomes])

    /** now we have to make clones of all components here. */

    /** a useEffect to enable isPassed IF all therapeutics in each outcome has been addressed. */
    useEffect(() => {
        _.forEach(makeTherapeuticsState.outcomes, (outcome) => {
            const isPassed = outcome.arr.length > 0
                ? outcome.arr.length && _.every(outcome.arr, (therapeutic) => {
                    return therapeutic.creationProcess.isSuccess === true
                })
                : outcome.arr.length === 0

            if (isPassed) {
                makeTherapeuticsDispatch({
                    type: 'SET_IS_PASSED',
                    value: {
                        reasoningOutcome: outcome.reasoningOutcome,
                        confidenceLevel: outcome.confidenceLevel,
                        isPassed
                    }
                })
            }
        })
    }, [makeTherapeuticsState.outcomes])

    // BUT if there's NO reasoning outcome OR if the reasoningOutcome's boolean values
    // are both false, then do this useEffect. Make sure that the response data is truthy
    // or else a getWorkflow call will be done too early.
    useEffect(() => {
        const reasoningSessionId = questionInterface.currentReasonWithMeResponse.results
            ?.reasoningSessionId
        const dataToCheck = questionInterface.currentReasonWithMeResponse.results
            ?.reasoningRecommendation?.reasoningOutcome || []

        // if reasoning outcome is empty, do getWorkflow script and go to the next step.
        // create getWorkflowMutation here and import the getWorkflowConsumer
        const conditionOne = reasoningSessionId && dataToCheck?.length <= 0
        // if all outcomes have no therapeutics.
        // const conditionTwo = dataToCheck?.length > 0 &&
        // AllReasoningOutcomes.length && _.every(AllReasoningOutcomes, (outcome) => {
        //     // return outcome?.recommendedTherapeutics.length && _.every(
        //     //     outcome?.recommendedTherapeutics, (o) => {
        //     //         return o.includeTherapeutic === false &&
        //     //         o.includeReasoningSet === false &&
        //     //         o.includeOutcome === false
        //     //     }
        //     // )
        //     return outcome?.recommendedTherapeutics.length === 0
        // })

        if (conditionOne) {
            const call = async () => {
                if (currentWorkflowStep) {
                    const newToken = await revalidateToken({
                        value: token.value,
                        id: token.id
                    }, token.mode)
                    // update workflow and then get workflow after.
                    // 100% because we really are done.
                    const percentComplete = 100

                    console.log([
                        'updating workflow progress if ',
                        'there are no outcomes anymore.'
                    ].join(' '))

                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.reasonWithMe.moduleName,
                        MODULE_TABLE.reasonWithMe.apiPaths.updateWorkflowProgress.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        updateWorkflowProgress({
                            authToken: newToken.value,
                            data: {
                                stepId: currentWorkflowStep.stepId,
                                workflowId,
                                percentComplete
                            }
                        }).unwrap().then((data) => {
                        // now we can get the workflow. without moduleid.
                        // we need a default route at this point via an empty
                        // response.
                            if (data.status === 'OK') {
                                console.log([
                                    'getting workflow from results page after',
                                    'checking that there are no outcomes anymore'
                                ].join(' '))
                                getWorkflow({
                                    authToken: token.value,
                                    data: {
                                        route: token.details.roid
                                    }
                                })
                            } else {
                                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                            }
                        })
                    }
                }
            }
            call()
        }
    }, [questionInterface.currentReasonWithMeResponse.results
        ?.reasoningRecommendation?.reasoningOutcome, AllReasoningOutcomes])

    /** if includeOutcome is false, make the calls automatically  */

    useEffect(() => {
        _.forEach(AllReasoningOutcomes, (outcome) => {
            _.forEach(outcome?.recommendedTherapeutics, (o) => {
                if (o.includeTherapeutic === true && o.includeOutcome === false) {
                    console.log('will trigger because includeOutcome is false')
                    makeTherapeuticsDispatch({
                        type: 'SET_IS_INITIALIZING',
                        value: {
                            reasoningOutcome: outcome.reasoningOutcome,
                            confidenceLevel: outcome.confidenceLevel,
                            obj: {
                                therapeuticsId: o.therapeuticsId,
                                isInitialized: true
                            }
                        }
                    })
                } else {
                    console.log('will not trigger because includeOutcome is true')
                }
            })
        })
    }, [
        AllReasoningOutcomes
    ])

    /** this is for what happens when all are successful and if includeOutcome is false */
    useEffect(() => {
        /**
         * condition for each element in an empty array is considered to be met because
         * there are no elements to evaluate. In other words, if there are no elements
         * in the array, there are no elements that fail the condition, so the result is
         * considered to be true
         */

        const everyCheck = makeTherapeuticsState.outcomes.length > 0 &&
        makeTherapeuticsState.outcomes.length && _.every(
            makeTherapeuticsState.outcomes, (outcome) => {
                return _.every(
                    outcome?.arr, (o) => {
                        return o.therapeutic.includeOutcome === false &&
                     o.creationProcess.isSuccess === true
                    }
                ) && outcome.isPassed === true
            })

        console.log('outcomes check on case 2:', makeTherapeuticsState.outcomes)

        if (everyCheck) {
            const call = async () => {
                if (currentWorkflowStep) {
                    const newToken = await revalidateToken({
                        value: token.value,
                        id: token.id
                    }, token.mode)
                    // update workflow and then get workflow after.
                    // 100% because we really are done.
                    const percentComplete = 100

                    console.log([
                        'updating workflow progress from ',
                        'finishing "includeOutcome === false" therapeutics'
                    ].join(' '))

                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.reasonWithMe.moduleName,
                        MODULE_TABLE.reasonWithMe.apiPaths.updateWorkflowProgress.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        updateWorkflowProgress({
                            authToken: newToken.value,
                            data: {
                                stepId: currentWorkflowStep.stepId,
                                workflowId,
                                percentComplete
                            }
                        }).unwrap().then((data) => {
                        // now we can get the workflow. without moduleid.
                        // we need a default route at this point via an empty
                        // response.
                            if (data.status === 'OK') {
                                console.log([
                                    'getting workflow from results page after',
                                    'finishing "includeOutcome === false" therapeutics'
                                ].join(' '))
                                // will need to add the token.details.roid.
                                getWorkflow({
                                    authToken: token.value,
                                    data: {
                                        route: token.details.roid
                                    }
                                })
                            } else {
                                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                            }
                        })
                    }
                }
            }
            call()
        }
    }, [makeTherapeuticsState.outcomes])

    /** previously this next button would only apply to one outcome's therapeutics.
     * you'd enable the button ONCE all are done BUT this time, we are checking
     * all therapeutics from all outcomes.
     */
    const NextButton = useMemo(() => {
        // the next button will only show up IF in any outcomes we have,
        // if there is at least ONE therapeutic that's includeOutcome === true.
        const enableScript = makeTherapeuticsState.outcomes.length && _.some(
            makeTherapeuticsState.outcomes, (outcome) => {
                return _.some(
                    outcome?.arr, (o) => {
                        return o.therapeutic.includeOutcome === true
                    }
                ) && outcome.isPassed === true
            })

        const buttonClassName = `btn btn-primary btn-lg w-100 
        ${ enableScript ? '' : 'disabled' }`.trim()

        return enableScript
            ? <a
                className={buttonClassName}
                onClick={async (e) => {
                    e.preventDefault()
                    if (enableScript) {
                        if (currentWorkflowStep) {
                            const newToken = await revalidateToken({
                                value: token.value,
                                id: token.id
                            }, token.mode)

                            console.log([
                                'updating workflow progress MANULLY from ',
                                'finishing ANY therapeutics'
                            ].join(' '))

                            const percentComplete = 100

                            const isValid = validateAPIPath(
                                activeModules.arr,
                                MODULE_TABLE.reasonWithMe.moduleName,
                                MODULE_TABLE.reasonWithMe.apiPaths.updateWorkflowProgress.path,
                                true
                            )

                            if (isValid && newToken.value) {
                                updateWorkflowProgress({
                                    authToken: newToken.value,
                                    data: {
                                        stepId: currentWorkflowStep.stepId,
                                        workflowId,
                                        percentComplete
                                    }
                                }).unwrap().then((data) => {
                                    // now we can get the workflow. without moduleid.
                                    // we need a default route at this point via an empty
                                    // response.
                                    if (data.status === 'OK') {
                                        console.log([
                                            'getting workflow from results page after MANUALLY',
                                            'finishing "includeOutcome === false" therapeutics'
                                        ].join(' '))
                                        getWorkflow({
                                            authToken: token.value,
                                            data: {
                                                route: token.details.roid
                                            }
                                        })
                                    } else {
                                        toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                                    }
                                })
                            }
                        }
                    }
                }}
            >
                <div className={'container'}>
                    <div className={'row justify-content-between align-items-center'}>
                        <div className={'col text-center'}>
                            {strings.app?.text.next}
                        </div>
                        <div className={'col-auto'}>
                            <i className={'fa-regular fa-arrow-right float-end'}
                                aria-hidden={'true'} ></i>
                        </div>
                    </div>
                </div>
            </a>
            : ''
    }, [strings, questionInterface,
        AllReasoningOutcomes, makeTherapeuticsState.outcomes
    ])

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'header py-3 text-center'}>
        {/* image of logo goes here. fixed width but height can change whatever */}
        <img src={'/images_new/header/logo.svg'} />
    </div>

    const desktopResult = <>
        {/* put header image here */}
        <FixedImage
            imageUrl={'/images_new/header/logo.svg'} position={'bottom-middle'}
        />
        <HeaderImage url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} />

        <div className={'position-absolute w-100 main-content'}>
            <div className={'container card shadow border-0 mb-20'}>

                <div className={'row form-container'}>
                    <div className={'col-12 col-md-8 col-lg-8 mx-auto'}>
                        {/* put main header here. */}
                        {<MainReasoningOutcome questionInterface={questionInterface} />}

                        {
                            _.map(AllReasoningOutcomes, (reasoningOutcome, index) => {
                                const key = `outcome-${ index }`

                                const stateOutcome = _.find(makeTherapeuticsState.outcomes, (o) => {
                                    return o.reasoningOutcome === reasoningOutcome
                                        .reasoningOutcome &&
                            o.confidenceLevel === reasoningOutcome.confidenceLevel
                                })

                                const renderButtons = stateOutcome
                                    ? <RecommendationConditionalButtons
                                        outcome={stateOutcome}
                                        makeTherapeuticsDispatch={makeTherapeuticsDispatch}
                                        MainRecommendationReasoningOutcome={reasoningOutcome}
                                    />
                                    : ''

                                return <div key={key}>
                                    {/* <div className={'justify-content-center row text-center'}>
                                <div className={'col-10 px-0'}>
                                    <h2 className={'mt-5'}>
                                        {reasoningOutcome
                                            ?.reasoningOutcomeDetailedText?.baseHeading}
                                    </h2>
                                    <span className={'d-inline-block mt-2'}>
                                        {reasoningOutcome
                                            ?.reasoningOutcomeDetailedText?.baseOutcome}
                                    </span>
                                </div>
                            </div> */}
                                    <div className={'mt-5'}>
                                        {renderButtons}
                                    </div>
                                </div>
                            })
                        }

                    </div>
                </div>
                <div className={'row align-items-center justify-content-center'}>
                    <div className={'col-12 col-sm-8 col-md-8 col-lg-5  '}>
                        {NextButton}
                    </div>
                </div>

            </div>
        </div>
        <div className={
            'position-fixed bottom-0 end-0 py-2 pe-5 fs-label fw-light'
        }>
            {MODULE_VERSION}
        </div>
    </>

    const mobileResult = <>
        {mobileHeader}
        <HeaderImage url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} />
        <div className={'container-fluid main-content px-12'}>

            <div className={'form-container'}>
                <div >
                    {/* put main header here. */}
                    {<MainReasoningOutcome questionInterface={questionInterface}/>}

                    {
                        _.map(AllReasoningOutcomes, (reasoningOutcome, index) => {
                            const key = `outcome-${ index }`

                            const stateOutcome = _.find(makeTherapeuticsState.outcomes, (o) => {
                                return o.reasoningOutcome === reasoningOutcome
                                    .reasoningOutcome &&
                                    o.confidenceLevel === reasoningOutcome.confidenceLevel
                            })

                            const renderButtons = stateOutcome
                                ? <RecommendationConditionalButtons
                                    outcome={stateOutcome}
                                    makeTherapeuticsDispatch={makeTherapeuticsDispatch}
                                    MainRecommendationReasoningOutcome={reasoningOutcome}
                                />
                                : ''

                            return <div key={key}>
                                {/* <div className={'justify-content-center row text-center'}>
                                    <div className={'col-10 px-0'}>
                                        <h2 className={'mt-5'}>
                                            {reasoningOutcome
                                                ?.reasoningOutcomeDetailedText?.baseHeading}
                                        </h2>
                                        <span className={'d-inline-block mt-2'}>
                                            {reasoningOutcome
                                                ?.reasoningOutcomeDetailedText?.baseOutcome}
                                        </span>
                                    </div>
                                </div> */}

                                <div className={'row'}>
                                    <div className={'col-12 col-md-8 col-lg-7 mx-auto'}>
                                        <div className={'mt-2'}>
                                            {renderButtons}

                                        </div>

                                    </div>

                                </div>
                            </div>
                        })
                    }

                </div>
                <div >
                    <div className={'row align-items-center justify-content-center'}>
                        <div className={'col-12 col-sm-8 col-md-8 col-lg-5  '}>
                            {NextButton}

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>

    return (<div>
        <div className={'reason-with-me-page'}>

            <GetWorkflowConsumer
                updateWorkflowProgress={updateWorkflowProgress}
                getWorkflow={getWorkflow}
                originalArgs={getWorkflowMutation.originalArgs}
                data={getWorkflowMutation.data}
            />

            {/* for every outcome, create their own createCareplanConsumer */}
            {
                _.map(makeTherapeuticsState.outcomes, (reasoningOutcome, outerIndex) => {
                    return _.map(reasoningOutcome.arr, (o, innerIndex) => {
                        const key = `create-careplan-consumer-${ outerIndex }-${ innerIndex }`
                        // console.log(o)
                        return <CreateCareplanConsumer
                            key={key}
                            outcome={reasoningOutcome}
                            therapeuticsCheck={o}
                            makeTherapeuticsDispatch={makeTherapeuticsDispatch}
                        />
                    })
                })
            }

            {
                isMobile ? mobileResult : desktopResult
            }

        </div>

    </div>)
}

export default ResultsPage
