import React, { ReactElement } from 'react'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'

import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import StepFive from '@fmt/components/facilities/add/steps/StepFive/Main'
import StepFour from '@fmt/components/facilities/add/steps/StepFour/Main'
import StepOne from '@fmt/components/facilities/add/steps/StepOne'
import StepThree from '@fmt/components/facilities/add/steps/StepThree/Main'
import StepTwo from '@fmt/components/facilities/add/steps/StepTwo/Main'
import { selectFacilityMenu, setFacilityMenu } from '@fmt/slice'
import {
    AddCareFacilityResponse,
    CareFacilityValues,
    Department,
    GetCareProfessionalsResponse,
    GetTreatmentsResponse
} from '@fmt/type'
import { FormikProps } from 'formik'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

interface ComponentProps {
    fetchData: (token: TokenData) => () => void,
    facilityStepOneAddFormik: FormikProps<CareFacilityValues>,
    facilityStepTwoAddFormik: FormikProps<{
        linkedDepartments: Department[]
    }>,
    facilityStepThreeAddFormik: FormikProps<{
        linkedCareprofessionals: {
        department: Department,
        collapsible: boolean,
        arr: GetCareProfessionalsResponse['data']['careprofessionals']
    }[]
    }>,
    facilityStepFourAddFormik: FormikProps<{
        linkedTreatments: {
        department: Department,
        collapsible: boolean,
        arr: GetTreatmentsResponse['data']['treatments']
    }[]
    }>,
    addCareFacilityData: {
        isLoading: boolean
        data: AddCareFacilityResponse | undefined
    }
}
// won't put formik here because users can unclick and the data would be
// deinitialized.

const AddModal = ({
    fetchData, facilityStepOneAddFormik, facilityStepTwoAddFormik, facilityStepThreeAddFormik,
    facilityStepFourAddFormik, addCareFacilityData
}:ComponentProps) => {
    const dispatch = useAppDispatch()

    const strings = useAppSelector(selectStrings)
    const facilityMenu = useAppSelector(selectFacilityMenu)
    const token = useAppSelector(selectToken)

    // facilityMenu.currentStep > 1 && facilityMenu.currentStep <= 4
    const PrevButton = <button type={'button'}
        disabled={
            // step 1 shouldn't be disabled here because of formik.
            facilityMenu.currentStep === 1
        }
        className={[
            'btn btn-dark btn-outline-white w-100'
        ].join(' ')}
        onClick={() => {
            if (facilityMenu.currentStep > 1 && facilityMenu.currentStep <= 5) {
                dispatch(setFacilityMenu({
                    ...facilityMenu,
                    currentStep: facilityMenu.currentStep - 1
                }))
            }
        }}>
        {strings.app?.text.previous}
    </button>
    const LoadingContent = (
        <div className={'spinner-container'}>
            <span className={'spinner-border spinner-border-sm'}></span>
            <span className={'ms-2'}>{
                strings.app?.text.loading || ''
            }</span>
        </div>
    )
    const NextButton = <button type={'button'}
        disabled={
            facilityMenu.currentStep === 2 && facilityStepTwoAddFormik
                .values.linkedDepartments.length <= 0
        // no validation in step 3 cause departments can technically
        // have no professionals.
        }
        className={[
            'btn btn-primary w-100 fw-semibold py-2'
        ].join(' ')}
        onClick={async () => {
        // different logic based on current step.
            if (facilityMenu.currentStep === 1) {
                facilityStepOneAddFormik.handleSubmit()
            } else if (facilityMenu.currentStep === 2) {
                facilityStepTwoAddFormik.handleSubmit()
            } else if (facilityMenu.currentStep === 3) {
                facilityStepThreeAddFormik.handleSubmit()
            } else if (facilityMenu.currentStep === 4) {
                facilityStepFourAddFormik.handleSubmit()
            } else if (facilityMenu.currentStep === facilityMenu.steps.length) {
                dispatch(setFacilityMenu({
                    ...facilityMenu,
                    showAddModal: false
                }))
                fetchData(token)
            }
        }}>
        { addCareFacilityData.isLoading
            ? LoadingContent
            : facilityMenu.currentStep === facilityMenu.steps.length
                ? strings.app?.text.close
                : strings.app?.text.next}
    </button>

    const result = <Modal onClosed={() => {
        // make sure to reset to step 1 once unmounted.
        if (facilityMenu.currentStep === facilityMenu.steps.length) {
            dispatch(setFacilityMenu({
                ...facilityMenu,
                currentStep: 1
            }))

            // reset form on close.
            facilityStepOneAddFormik.resetForm()
            facilityStepTwoAddFormik.resetForm()
            facilityStepThreeAddFormik.resetForm()
            facilityStepFourAddFormik.resetForm()
        }
    }} size={'xxl'} cssModule={{
        modal: 'modal add-facility-form'
    }} centered={true} isOpen={facilityMenu.showAddModal} toggle={() => {
        dispatch(setFacilityMenu({
            ...facilityMenu,
            showAddModal: !facilityMenu.showAddModal
        }))
    }}>
        <ModalHeader tag={'div'} cssModule={{
            'modal-title': 'modal-title w-100',
            'modal-header': 'align-items-baseline modal-header '
        }} toggle={() => {
            dispatch(setFacilityMenu({
                ...facilityMenu,
                showAddModal: !facilityMenu.showAddModal
            }))
        }} close={ <a className={'btn btn-round '}
            onClick={(e) => {
                e.preventDefault()
                dispatch(setFacilityMenu({
                    ...facilityMenu,
                    showAddModal: false
                }))
            }}>
            <i className={'fa-light fa-multiply'} aria-hidden={'true'}>
            </i>
        </a>}
        >
            <div className={'align-items-center justify-content-center row w-100 g-3'}>
                {
                    facilityMenu.steps.map((step, index) => {
                        const stepCircleClasses = ['step-indicator']
                        if (facilityMenu.currentStep === step.number) {
                            stepCircleClasses.push('active')
                        }

                        if (facilityMenu.currentStep > step.number) {
                            stepCircleClasses.push('finished')
                        }

                        const stepTextClasses = ['step-active']

                        let text: ReactElement<any, any> = <>{step.number}</>

                        if (facilityMenu.currentStep > step.number) {
                            text = <i className={'fa-light fa-check'} aria-hidden={'true'}>
                            </i>
                        }

                        return (<React.Fragment key={index} >
                            <div className={'col-auto'}>
                                <div className={stepCircleClasses.join(' ')}>
                                    {text}
                                </div>
                                <span className={stepTextClasses.join(' ')}>{step.name}</span>
                            </div>
                            { index < facilityMenu.steps.length - 1 && <div className={
                                'col'
                            }>
                                <div className={'step-divider'}></div>
                            </div> }
                        </React.Fragment>

                        )
                    })}
            </div>
        </ModalHeader>
        <ModalBody>
            <div className={'pt-5 px-5 px-lg-5'}>
                {facilityMenu.currentStep === 1 &&
                <StepOne facilityStepOneAddFormik={facilityStepOneAddFormik}/> }
                {facilityMenu.currentStep === 2 &&
                <StepTwo facilityStepTwoAddFormik={facilityStepTwoAddFormik}
                /> }
                {facilityMenu.currentStep === 3 &&
                <StepThree
                    facilityStepThreeAddFormik={facilityStepThreeAddFormik}
                /> }
                {facilityMenu.currentStep === 4 &&
                <StepFour
                    facilityStepFourAddFormik={facilityStepFourAddFormik}
                /> }
                {facilityMenu.currentStep === facilityMenu.steps.length &&
                <StepFive
                    facilityStepOneAddFormik={facilityStepOneAddFormik}
                    facilityStepTwoAddFormik={facilityStepTwoAddFormik}
                /> }
            </div>
        </ModalBody>
        <ModalFooter>
            <div className={'w-100'}>
                <footer className={'footer wave-background d-flex align-items-end px-5'}>
                    <div className={'container pb-5'}>
                        <div className={'row align-items-center offset-lg-3 offset-md-0'}>
                            { facilityMenu.currentStep > 1 && facilityMenu.currentStep <= 4
                                ? <div className={'col'}>
                                    {PrevButton}
                                </div>
                                : <div className={'col'}>
                                </div>}
                            {/* { (facilityMenu.currentStep === 2 || facilityMenu.currentStep === 3)
                                ? <div className={'col'}>
                                    {SkipButton}
                                </div>
                                : ''} */}
                            <div className={'col'}>
                                {NextButton}
                            </div>
                            {/* <div className={'col-6 col-lg-4 col-md-6 offset-lg-4 offset-md-0'}>
                                {PrevButton}
                                {SkipButton}
                            </div>
                            <div className={'col-6 col-lg-4 col-md-6'}>
                                {NextButton}
                            </div> */}
                        </div>
                    </div>
                </footer>
            </div>
        </ModalFooter>
    </Modal>

    return <>{result}</>
}

export default AddModal
