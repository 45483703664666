import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { TokenData } from '@app/types/type.token'
import {
    useEditCarepathMutation,
    useGetCarepathMutation,
    useGetCarepathsMutation,
    useGetTreatmentCarepathsMutation
} from '@fmt/api'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import CarepathDateName from '@fmt/components/carepaths/details/inputs/DateName'
import CarepathName from '@fmt/components/carepaths/details/inputs/Name'
import { CAREPATH_FORMIK_INITIAL_VALUES, CAREPATH_VALIDATION_SCHEMA } from '@fmt/constants'
import { Carepath, CarepathKeys, GetCarepathsResponse } from '@fmt/type'
import { push } from '@lagunovsky/redux-react-router'
import { useFormik } from 'formik'
import _ from 'lodash'

const AccordionComponent = (
    {
        responseData,
        currentPage,
        search,
        mode,
        isOpen,
        obj,
        fixedCacheKey
    }:
    {
        responseData: GetCarepathsResponse | undefined,
        currentPage: number;
        search: string,
        mode: 'EDIT' | 'VIEW',
        isOpen?: boolean,
        obj: Carepath,
        fixedCacheKey:'shared-getCarepaths-key' | 'details-getTreatmentCarepaths-key'
    }) => {
    const token = useAppSelector(selectToken)

    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const validateRoute = useValidateRoute()
    const dispatch = useAppDispatch()

    const [getCarepath, getCarepathMutation] = useGetCarepathMutation()
    // accordion components are now editable.
    const [editCarepath, editCarepathMutation] = useEditCarepathMutation()

    // 'details-getTreatmentCarepaths-key' VS 'shared-getCarepaths-key'
    const [getCarepaths] = useGetCarepathsMutation({
        ...(fixedCacheKey === 'shared-getCarepaths-key' && {
            fixedCacheKey: 'shared-getCarepaths-key'
        })
    })

    const [getTreatmentCarepaths] = useGetTreatmentCarepathsMutation({
        ...(fixedCacheKey === 'details-getTreatmentCarepaths-key' && {
            fixedCacheKey: 'details-getTreatmentCarepaths-key'
        })
    })

    const carepathEditFormik = useFormik({
        initialValues: CAREPATH_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CAREPATH_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || ''
        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken(
                        {
                            value: token.value,
                            id: token.id
                        },
                        token.mode
                    )
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths.editCarepath.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        editCarepath({
                            authToken: newToken.value,
                            data: {
                                carepathId: obj.carepathId || '',
                                treatmentId: getCarepathMutation.data?.data.treatmentId || '',
                                carepathName: values.carepathName || '',
                                dateName: values.dateName || ''
                            }
                        })
                    }
                }
            }
            call()
        }
    })

    useEffect(() => {
        const data = editCarepathMutation.data

        if (data) {
            if (data.status === 'OK') {
                // don't do anything.

                if (fixedCacheKey === 'shared-getCarepaths-key') {
                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths
                            .getCarepaths.path,
                        true
                    )

                    if (isValid2) {
                        const skip = currentPage * (responseData
                            ?.data.limit || 1)

                        const totalRecords = (responseData
                            ?.data.totalRecords || 0) - 1

                        getCarepaths({
                            authToken: token.value,
                            data: {
                                skip: skip > (responseData?.data
                                    .totalRecords || 0)
                                    ? totalRecords
                                    : skip,
                                limit: responseData?.data.limit
                            }
                        })
                    }
                } else {
                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths
                            .getTreatmentCarepaths.path,
                        true
                    )

                    if (isValid2) {
                        getTreatmentCarepaths({
                            authToken: token.value,
                            data: {
                                treatmentId: obj.treatmentId
                            }
                        })
                    }
                }
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [editCarepathMutation.data])

    useEffect(() => {
        if (editCarepathMutation.error) {
            const message = getErrorText(editCarepathMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [editCarepathMutation.error])

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        let isMounted = true

        const call = async () => {
            if (
                token.valid &&
                isOpen === true &&
                getCarepathMutation.isSuccess === false
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths
                            .getCarepathId.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getCarepath({
                            authToken: newToken.value,
                            data: {
                                carepathId: obj.carepathId
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
        }
    }

    // ONLY fetch it when obj.needAccordion is true AND if isSuccess is false.
    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid, isOpen, getCarepathMutation.isSuccess])

    useEffect(() => {
        if (getCarepathMutation.data) {
            const data = getCarepathMutation.data
            carepathEditFormik.setValues({
                ...carepathEditFormik.values,
                ...data.data
            })
        }
    }, [getCarepathMutation.data])

    useEffect(() => {
        if (getCarepathMutation.error) {
            const message = getErrorText(getCarepathMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getCarepathMutation.error])

    const ViewButton = <button
        type={'button'}
        className={'btn btn-secondary w-100'}
        onClick={(e) => {
            e.stopPropagation()

            const isValid = validateRoute(
                activeModules.arr,
                MODULE_TABLE.fmt.moduleName,
                MODULE_TABLE.fmt.routes.viewCarepath,
                true
            )

            dispatch(push(
                _.replace(isValid.route,
                    ':carepathId',
                    obj.carepathId
                )
            ))
        }}
    >
        {strings.fmt?.text.carepath.view_carepath}
    </button>

    const LoadingContent = (
        <div className={'spinner-container'}>
            <span className={'spinner-border spinner-border-sm'}></span>
            <span className={'ms-2'}>{
                strings.app?.text.loading || ''
            }</span>
        </div>
    )

    const SubmitButton = <button type={'button'}
        disabled={
        // step 1 shouldn't be disabled here because of formik.
            false
        // step 2 can be skipped entirely.
        }
        className={[
            'btn btn-primary w-100 px-8'
        ].join(' ')}
        onClick={() => {
            carepathEditFormik.handleSubmit()
        }}>
        { editCarepathMutation.isLoading
            ? LoadingContent
            : strings.app?.text.save}
    </button>

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: CarepathKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                carepathEditFormik.handleSubmit()
            }
        }
    }

    const NameInput = useMemo(() => {
        return <CarepathName
            readOnly={mode === 'VIEW'}
            carepathEditFormik={carepathEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'carepathName')
            }}
        />
    }, [
        mode,
        strings,
        carepathEditFormik.values.carepathName,
        carepathEditFormik.errors.carepathName
    ])

    const DateNameInput = useMemo(() => {
        return <CarepathDateName
            readOnly={mode === 'VIEW'}
            carepathEditFormik={carepathEditFormik}
            handleKeyDown={(e) => {
                if (e.key === 'Enter') { carepathEditFormik.handleSubmit() }
            }}
        />
    }, [
        mode,
        strings,
        carepathEditFormik.values.dateName,
        carepathEditFormik.errors.dateName
    ])

    return <>
        {
            getCarepathMutation.isLoading && <small className={'d-block text-center py-2'}>
                <div className={'spinner-container'}>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{
                        strings.app?.text.loading || ''
                    }</span>
                </div>
            </small>
        }
        {getCarepathMutation.isSuccess && <div className={'px-16'}>
            <h5 className={'col fw-semibold'}>{strings.fmt?.text.carepath.carepath}</h5>
            {NameInput}
            {DateNameInput}

            {mode === 'EDIT' && <div className={'container button-container'}>
                <div className={'row align-items-center mt-6'}>
                    <div className={'col-6 col-lg-4 col-md-6 offset-lg-4 offset-md-0'}>
                        {ViewButton}
                    </div>
                    <div className={'col-6 col-lg-4 col-md-6'}>
                        {SubmitButton}
                    </div>
                </div>
            </div>}
        </div>}
    </>
}

export default AccordionComponent
