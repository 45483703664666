import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectDateFormats, selectStrings } from '@app/slices/slice.app'
import { ImageLightbox } from '@careplan/components/ImageLightbox'
import { VOLUME } from '@careplan/constants'
import { BuildingBlock, BuildingBlockHeader, ReasoningCard } from '@careplan/type'
// eslint-disable-next-line max-len
import ReadOnlyLinkArticle from '@doc/components/patients/details/components/modal/ReadonlyCareplanStep/components/ReadOnlyLinkArticle'
// eslint-disable-next-line max-len
import ReadOnlyPlayer from '@doc/components/patients/details/components/modal/ReadonlyCareplanStep/components/ReadOnlyPlayer'
// eslint-disable-next-line max-len
import ReadOnlyListRoutine from '@doc/components/patients/details/components/modal/ReadonlyCareplanStep/components/ReadoOnlyListRoutine'
import { selectMediaPlayers, setMediaPlayer } from '@doc/slice'
import { GPACareplanStepIdDetails } from '@doc/type'
import {
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/careplanStep/Components'
import { format, fromUnixTime } from 'date-fns'
import _ from 'lodash'
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import ReactPlayer from 'react-player'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import rehypeRaw from 'rehype-raw'
import remarkBreaks from 'remark-breaks'
import { remarkDefinitionList } from 'remark-definition-list'
import remarkEmoji from 'remark-emoji'
import remarkGfm from 'remark-gfm'
import remarkHeadingId from 'remark-heading-id'
import supersub from 'remark-supersub'
// eslint-disable-next-line max-len
import BuildingBlockContent from '@doc/components/patients/details/components/modal/ReadonlyCareplanStep/components/BuildingBlockContent'
import ReasoningBlockModal from '@doc/components/patients/details/components/modal/ReadonlyCareplanStep/components/ReasoningBlockModal/Main'

interface ComponentProps {
    contentData:GPACareplanStepIdDetails['response']['content'] | undefined,
    userId: string | undefined
    careplanStepId: string | undefined
}
const ReadonlyCareplanStep = (props: ComponentProps) => {
    // ok so you want to display the careplan step the way it is BUT readonly version.
    // best to do it from scratch. start with how to render the data.
    // we can import types from careplan to make less time.
    const strings = useAppSelector(selectStrings)
    const dispatch = useAppDispatch()
    const dateFormats = useAppSelector(selectDateFormats)

    const data = props.contentData?.data
    const mediaPlayerState = useAppSelector(selectMediaPlayers)
    // to get the react-player's ref. Will try to use map now.
    const mediaPlayersRef = useRef<{
        key: string,
        player: ReactPlayer | null
    }[]>([])

    const [reasoningAnswersModal, setReasoningAnswersModal] =
    useState<BuildingBlock | undefined>(undefined)

    const blockRefs = useRef<{ [key: string]: HTMLDivElement | null }>({})

    useEffect(() => {
        // get all pieces that are audio or video.

        if (data?.contentData) {
            const filtered = _.filter(data?.contentData || [],
                (buildingBlock) => {
                    return buildingBlock.buildingBlockType === 'audio/content' ||
                    buildingBlock.buildingBlockType === 'video/content'
                }
            )

            _.forEach(filtered, (buildingBlock) => {
                const foundProgress = _.find(data?.progressData,
                    (o) => o.buildingBlockId === buildingBlock.buildingBlockId
                )

                dispatch(setMediaPlayer({
                    key: buildingBlock.buildingBlockId,
                    item: {
                        seeking: false,
                        played: (foundProgress?.careplanStepProgress || 0) / 100,
                        looped: false,
                        playing: false,
                        volume: VOLUME.MAX,
                        muted: false
                    }
                }))
            })
        }
    }, [data])

    // steo 1. header image main.
    const HeaderImageMain = useMemo(() => {
        const found = _.find(data?.contentData, (buildingBlock) => {
            return buildingBlock.buildingBlockType === 'image/header'
        })

        const buildingBlockHeader = (found?.buildingBlockValue
            ? found.buildingBlockValue
            : {
                imageUrl: '',
                xCoord: 0,
                yCoord: 0
            }) as BuildingBlockHeader

        const img = buildingBlockHeader.imageUrl
            ? buildingBlockHeader.imageUrl
            : ''

        return <div style={{ height: 260 }}>
            {/* will reuse the components from the careplan step too */}
            <HeaderImage
                style={{
                    zIndex: 0,
                    width: '100%'
                }}
                url={img}
                backgroundPositionX={buildingBlockHeader.xCoord}
                backgroundPositionY={buildingBlockHeader.yCoord}
            >
            </HeaderImage>
            <HeaderGradient
                style={{
                    zIndex: 0,
                    width: '100%'
                }}/>
        </div>
    }, [data])

    let stepTypeString = ''

    if (data?.activityData?.theStepType === 'activity') {
        stepTypeString = strings.doc?.text.patient
            .patient_progress.table.filters.activity || ''
    } else if (data?.activityData?.theStepType === 'content') {
        stepTypeString = strings.doc?.text.patient
            .patient_progress.table.filters.content || ''
    } else if (data?.activityData?.theStepType === 'lesson') {
        stepTypeString = strings.doc?.text.patient
            .patient_progress.table.filters.lesson || ''
    } else if (data?.activityData?.theStepType === 'uncertain') {
        stepTypeString = strings.doc?.text.patient
            .patient_progress.table.filters.uncertain || ''
    } else if (data?.activityData?.theStepType === 'reasoning') {
        stepTypeString = strings.doc?.text.patient
            .patient_progress.table.filters.reasoning || ''
    }

    // this is a read only version of the careplan step.
    // remove handlers as you see fit.
    const hasDuplicateId = (data: BuildingBlock[]) => {
        const idSet = new Set()
        for (const item of data) {
            if (idSet.has(item.buildingBlockId)) {
                return true
            }
            idSet.add(item.buildingBlockId)
        }
        return false
    }

    // Step 1: Create an array with the combined progressData and contentData
    const combinedData = _.map(data?.progressData, (o) => {
        const buildingBlock = _.find(data?.contentData, (p) => {
            return p.buildingBlockId === o.buildingBlockId
        })

        return {
            ...o,
            buildingBlock
        }
    })

    const BuildingBlocks = useMemo(() => {
        const renderContent = (buildingBlock: BuildingBlock, i: number, arr: BuildingBlock[]) => {
            // backend flaw where buildingBlockId could be similar.

            const key = hasDuplicateId(arr) ? i.toString() : buildingBlock.buildingBlockId

            let result: ReactElement<any, any> = <></>
            let printHr = true
            let showProgressCard = true

            const contentType = buildingBlock.buildingBlockType

            const foundProgress = _.find(data?.progressData,
                (o) => o.buildingBlockId === buildingBlock.buildingBlockId
            )

            const contents = buildingBlock.buildingBlockValue !== null &&
            typeof buildingBlock.buildingBlockValue !== 'string' &&
            !_.isArray(buildingBlock.buildingBlockValue)
                ? buildingBlock.buildingBlockValue as ReasoningCard
                : {
                    reasoningSetTitle: '',
                    reasoningSetDescription: '',
                    reasoningSetId: buildingBlock.buildingBlockValue as string
                } as ReasoningCard

            const reasoningCard = <div className={'questionnaire clickable'} onClick={() => {
                setReasoningAnswersModal(buildingBlock)
            }}>
                <div className={'card'}>
                    <div className={'card-body'}>
                        <p className={'mb-0'}>
                            {contents.reasoningSetTitle || strings.doc
                                ?.text.patient.patient_progress.table.filters.reasoningSet }
                        </p>
                    </div>
                </div>
            </div>

            switch (buildingBlock.buildingBlockType) {
                case 'text/plain':
                    result = <span>
                        {_.isString(buildingBlock.buildingBlockValue)
                            ? buildingBlock.buildingBlockValue
                            : ''}
                    </span>
                    break
                case 'text/markdown':{
                    const forMarkdownContent = _.isString(buildingBlock.buildingBlockValue)
                        ? buildingBlock.buildingBlockValue
                            .replace(/==([^=]+)==/g, '<mark>$1</mark>')
                            .replace(/~(\d+)~/g, '<sub>$1</sub>')
                            .replace(/~~([^~]+)~~/g, '<s>$1</s>')
                        : ''

                    const MonitoringProgress = <div className={'markdown'}>
                        {/* use workaround for  == to <mark></mark> */}
                        <ReactMarkdown
                            components={{
                            // supersub replaces markdown with del tags
                            // for somereason.
                                del: (props) => <sub {...props} />,
                                ul: (props) => {
                                    const modifiedProps = { ...props }
                                    // eslint-disable-next-line react/prop-types
                                    modifiedProps.ordered = props.ordered.toString() as any

                                    if (modifiedProps.className && modifiedProps.className
                                        .includes('contains-task-list')) {
                                        return <ul
                                            {...modifiedProps}
                                            className={[
                                                'contains-task-list list-unstyled ps-4'
                                            ].join(' ')}
                                        />
                                    } else {
                                        return <ul
                                            {...modifiedProps}

                                        />
                                    }
                                }
                            }}
                            linkTarget={'_blank'}
                            remarkPlugins={[
                                remarkBreaks,
                                remarkGfm,
                                supersub,
                                remarkEmoji,
                                remarkDefinitionList,
                                remarkHeadingId
                            ]} rehypePlugins={[
                                rehypeRaw
                            ]}
                        >
                            {`${ forMarkdownContent }`}
                        </ReactMarkdown>
                    </div>

                    result = MonitoringProgress
                    break
                }
                case 'audio/content':
                case 'video/content': {
                    // Display the media player.

                    // get mediaPlayer state.
                    const playerState = _.find(mediaPlayerState, (obj) => {
                        return obj.key === key
                    })

                    // get mediaPlayer ref.
                    const playerRef = _.find(mediaPlayersRef.current, (obj) => {
                        return obj.key === key
                    })

                    if (playerState) {
                        result = <ReadOnlyPlayer
                            playerState={playerState}
                            type={contentType === 'audio/content'
                                ? 'audio'
                                : 'video'}
                            buildingBlock={buildingBlock}
                            playerRef={playerRef}
                            mapKey={key}
                        />
                    }

                    break
                }

                case 'image/content': {
                    result = <div className={'text-center'}>
                        <ImageLightbox onModal={true} imageUrl={
                            _.isString(buildingBlock.buildingBlockValue)
                                ? buildingBlock.buildingBlockValue
                                : ''
                        } onLoad={() => {}}/>
                    </div>
                    break
                }

                case 'reasoning/card': {
                    result = reasoningCard
                    break
                }

                case 'reasoning/set': {
                    result = reasoningCard
                    break
                }

                // do schedule.
                case 'list/routine': {
                    result = <ReadOnlyListRoutine
                        buildingBlock={buildingBlock}
                        buildingBlockProgress={foundProgress}
                    />
                    break
                }

                case 'link/article': {
                    result = <ReadOnlyLinkArticle
                        buildingBlock={buildingBlock}
                    />

                    break
                }

                default: {
                    printHr = false
                    showProgressCard = false
                }
            }

            const foundProgressData = _.filter(combinedData, (p) => {
                return p.buildingBlockId === buildingBlock.buildingBlockId
            })

            return <div key={key} className={'mt-3 row'}
                ref={(el) => (blockRefs.current[key] = el)}
            >
                {/* building block content goes here */}
                <BuildingBlockContent
                    result={result}
                    printHr={printHr}
                    showProgressCard={showProgressCard}
                    foundProgressData={foundProgressData}
                    buildingBlock={buildingBlock}
                />

            </div>
        }

        const content = _.map(
            _.sortBy(
                data?.contentData || [], 'buildingBlockOrder'
            ), renderContent
        )

        return content
    }, [
        // added mediaPlayerState to render players
        data, strings, mediaPlayerState
    ])

    // shouldn't be responsive.
    const careplanStepResults = <div>
        {HeaderImageMain}
        {/* use the same classes from the careplan step but you'll have to recreate the */}
        {/* properties in the doc module. why? because it's from endUser. you are on admin.
        this includes css_question_types. */}
        <div className={'main-content'} >
            {/* container with a col width and then set to center */}

            <div className={'content-container'}>
                <div className={'align-items-center justify-content-between meta-info row'}>
                    <div className={'col-auto'}>
                        {/* d-inline-block */}
                        <small className={[
                            'fw-bold content-type',
                            'd-inline-block',
                            data?.activityData?.theStepType
                        ].join(' ')}>
                            {stepTypeString}
                        </small>
                        <small className={[
                            'd-inline-block ms-3'
                        ].join(' ')}>
                            <i className={'fa-light fa-clock'} aria-hidden={'true'}>
                            </i>
                            <span className={'d-inline-block ms-2'}>
                                {format(fromUnixTime(
                                    data?.activityData?.carePlanStep.doneWhen || 0
                                ), dateFormats.format1) || ''}
                            </span>
                        </small>
                    </div>
                    <div className={'col-auto'}>
                    </div>
                </div>
                <div className={'row mt-3'}>
                    <div className={'col-auto'}>
                        <h1>
                            {data?.activityData?.stepName}
                        </h1>
                    </div>
                </div>
                {BuildingBlocks}
            </div>
        </div>
    </div>

    const reasoningAnswersModalResult = <Modal
        unmountOnClose={false}
        // overflow-auto necessary for smaller screen sizes. this modal should not be responsive.
        className={'answer-summary-modal overflow-auto'}
        isOpen={reasoningAnswersModal !== undefined} toggle={() => {
            setReasoningAnswersModal(undefined)
        }}>
        <ModalHeader className={'justify-content-start'} toggle={() => {
            setReasoningAnswersModal(undefined)
        // btn-close me-auto ms-0
        }} close={ <a className={'btn btn-round '}
            onClick={(e) => {
                e.preventDefault()
                setReasoningAnswersModal(undefined)
            }}>
            <i className={'fa-light fa-arrow-left'} aria-hidden={'true'}>
            </i>
        </a>}
        >{}</ModalHeader>
        <ModalBody>
            {reasoningAnswersModal && <ReasoningBlockModal buildingBlock={reasoningAnswersModal}
                userId={props.userId} careplanStepId={props.careplanStepId} />}
        </ModalBody>
    </Modal>

    // UPDATE from IHD-331.
    const result = <div>
        {careplanStepResults}
    </div>

    return <>
        {
            data !== undefined ? result : ''
        }
        {reasoningAnswersModalResult}
    </>
}
export default ReadonlyCareplanStep
