import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'

import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { selectToken } from '@app/slices/slice.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import {
    CAREPROFESSIONAL_FORMIK_INITIAL_VALUES,
    CAREPROFESSIONAL_VALIDATION_SCHEMA,
    MODULE_VERSION
} from '@fmt/constants'
import {
    CareProfessionalKeys,
    CareProfessionalParams,
    GetCareProfessionalResponse,
    UpdateProfilePictureCareProfessionalResponse
} from '@fmt/type'
import { ImageDisplay } from '@profile/styles/ImageDisplay'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'

import { selectRouter } from '@app/app.store'
import { TokenData } from '@app/types/type.token'
import {
    useEditCareProfessionalMutation,
    useGetCareProfessionalMutation,
    useUpdateProfilePictureCareProfessionalMutation
} from '@fmt/api'
import DashboardResponsiveHeader from '@fmt/components/DashboardResponsiveHeader'
import DeptLocation from '@fmt/components/careprofessionals/details/inputs/DeptLocation'
import Email from '@fmt/components/careprofessionals/details/inputs/Email'
import CareProfessionalName from '@fmt/components/careprofessionals/details/inputs/Name'
import PhoneNumber from '@fmt/components/careprofessionals/details/inputs/PhoneNumber'
import Profession from '@fmt/components/careprofessionals/details/inputs/Profession'
import { back, push } from '@lagunovsky/redux-react-router'

import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const ProfilePic = ({
    showRecentlyUploaded,
    updateProfilePictureCareProfessionalMutation,
    getCareProfessionalMutation
}: {
    showRecentlyUploaded: boolean
    updateProfilePictureCareProfessionalMutation: {
        data: UpdateProfilePictureCareProfessionalResponse | undefined
    }
    getCareProfessionalMutation: {
        data?: GetCareProfessionalResponse | undefined
    }
}) => {
    const url = showRecentlyUploaded
        ? updateProfilePictureCareProfessionalMutation.data?.data.url
        : getCareProfessionalMutation.data?.data.profilePicture
    const imageThumbnail = url
        ? <ImageDisplay
            url={url}
            className={'rounded-square profile'}
        />
        : <div
            className={[
                'img-placeholder rounded-square profile'
            ].join(' ')}
        >
        </div>

    return imageThumbnail
}

const CareprofessionalDetailsInterface = () => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)

    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()
    const router = useAppSelector(selectRouter)
    const validateRoute = useValidateRoute()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()

    // upload profile picture logic.
    const [file, setFile] = useState<File | null>(null)
    const [getCareProfessional, getCareProfessionalMutation] = useGetCareProfessionalMutation()

    const [showRecentlyUploaded, setShowRecentlyUploaded] = useState<boolean>(false)
    const [showCancelWarning, setShowCancelWarning] = useState<boolean>(false)

    const { careprofessionalId } = useParams<CareProfessionalParams>()

    const [editCareProfessional, editCareProfessionalMutation] = useEditCareProfessionalMutation()

    const [mode, setMode] = useState<'EDIT' | 'VIEW'>(
        _.lowerCase(router.location.pathname).includes('edit') ? 'EDIT' : 'VIEW'
    )

    // a fix that does the ff things:
    // before this existed, the getPatients gets called twice from two separate useEffects
    // the first hook should only be done once you clicked on either buttons that
    // changes the url. ideally, a quick solution. should be set to FALSE.
    const [modeButtonClicked, setModeButtonClicked] = useState(false)

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    const careprofessionalEditFormik = useFormik({
        initialValues: getCareProfessionalMutation.data?.data ||
         CAREPROFESSIONAL_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CAREPROFESSIONAL_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || '',
            strings.app?.message.error.email || ''

        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken(
                        {
                            value: token.value,
                            id: token.id
                        },
                        token.mode
                    )
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths.editCareProfessional.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        editCareProfessional({
                            authToken: newToken.value,
                            data: {
                                careProfessionalId: careprofessionalId || '',
                                careProfessionalName: values.careProfessionalName || '',
                                careProfessionalPhoneNumber:
                                values.careProfessionalPhoneNumber || '',
                                careProfessionalEmail: values.careProfessionalEmail || '',
                                careProfessionalLocation: values.careProfessionalLocation || '',
                                careProfessionalProfession: values.careProfessionalProfession || ''
                            }
                        })
                    }
                }
            }
            call()
        }
    })

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        let isMounted = true

        const call = async () => {
            if (
                token.valid &&
                careprofessionalId
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths
                            .getCareProfessional.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getCareProfessional({
                            authToken: newToken.value,
                            data: {
                                careProfessionalId: careprofessionalId || ''
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
        }
    }

    // THIS FETCH SHOULD NOT HAPPEN ON MOUNT.
    // solution change dependency to a useState. a button where you clicked on it
    // and then unset it.
    useEffect(() => {
        // should only be performed IF the button is clicked.
        if (modeButtonClicked) {
            setMode(
                _.lowerCase(router.location.pathname).includes('edit') ? 'EDIT' : 'VIEW'
            )

            // you go here ideally after submitting the values from the edit mode.
            if (_.lowerCase(router.location.pathname).includes('view')) {
                careprofessionalEditFormik.resetForm()
                // and then fetch the data again just to double check.
                // you only want to do this IF you came from clicking the button.
                fetchData(token)
            }

            // don't forget to set it false after.
            setModeButtonClicked(false)
        }
    }, [router.location.pathname, modeButtonClicked])

    // ONLY fetch it when needAccordion is true AND if isSuccess is false.
    useEffect(() => {
        if (careprofessionalId) {
            setShowRecentlyUploaded(false)
            return fetchData(token)
        } else {
            return () => {}
        }
    }, [token.id, token.valid, careprofessionalId])

    useEffect(() => {
        if (getCareProfessionalMutation.data) {
            const data = getCareProfessionalMutation.data
            careprofessionalEditFormik.setValues({
                ...careprofessionalEditFormik.values,
                ...data.data
            })
        }
    }, [getCareProfessionalMutation.data])

    useEffect(() => {
        if (getCareProfessionalMutation.error) {
            const message = getErrorText(getCareProfessionalMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getCareProfessionalMutation.error])

    useEffect(() => {
        const data = editCareProfessionalMutation.data

        if (data) {
            if (data.status === 'OK') {
                // close component by clearing the edit formik.
                toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                // careprofessionalEditFormik.resetForm()
                fetchData(token)
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [editCareProfessionalMutation.data])

    useEffect(() => {
        if (editCareProfessionalMutation.error) {
            const message = getErrorText(editCareProfessionalMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [editCareProfessionalMutation.error])

    const [updateProfilePictureCareProfessional, updateProfilePictureCareProfessionalMutation] =
    useUpdateProfilePictureCareProfessionalMutation()
    const fileInputRef = useRef<HTMLInputElement>(null)

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0]
        if (selectedFile) {
            setFile(selectedFile)
        }
    }

    const handleUpload = async () => {
        if (file) {
            const formData = new FormData()
            formData.append('profilePicture', file)

            const newToken = await revalidateToken({
                value: token.value,
                id: token.id
            }, token.mode)

            const foundApiPath = validateAPIPath(
                activeModules.arr,
                MODULE_TABLE.fmt.moduleName,
                MODULE_TABLE.fmt.apiPaths.updateProfilePictureCareProfessional.path,
                true
            )
            if (foundApiPath) {
                updateProfilePictureCareProfessional({
                    careprofessionalId: careprofessionalId || '',
                    data: formData,
                    authToken: newToken.value
                }).unwrap().then((data) => {
                    if (data.status === 'OK') {
                        // toast success message.
                        toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                        // we need to use this token now so set it.
                        setShowRecentlyUploaded(true)
                    } else {
                        toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                    }
                })
            }
        }
    }

    useEffect(() => {
        handleUpload()
    }, [file])

    useEffect(() => {
        if (updateProfilePictureCareProfessionalMutation.error) {
            const message = getErrorText(updateProfilePictureCareProfessionalMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [updateProfilePictureCareProfessionalMutation.error])

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: CareProfessionalKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                careprofessionalEditFormik.handleSubmit()
            }
        }
    }

    const NameInput = useMemo(() => {
        return <CareProfessionalName
            readOnly={mode === 'VIEW'}
            careprofessionalEditFormik={careprofessionalEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'careProfessionalProfession')
            }}
        />
    }, [
        mode,
        strings,
        careprofessionalEditFormik.values.careProfessionalName,
        careprofessionalEditFormik.errors.careProfessionalName
    ])

    const ProfessionInput = useMemo(() => {
        return <Profession
            readOnly={mode === 'VIEW'}
            careprofessionalEditFormik={careprofessionalEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'careProfessionalLocation')
            }}
        />
    }, [
        mode,
        strings,
        careprofessionalEditFormik.values.careProfessionalProfession,
        careprofessionalEditFormik.errors.careProfessionalProfession
    ])

    const LocationInput = useMemo(() => {
        return <DeptLocation
            readOnly={mode === 'VIEW'}
            careprofessionalEditFormik={careprofessionalEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'careProfessionalPhoneNumber')
            }}
        />
    }, [
        mode,
        strings,
        careprofessionalEditFormik.values.careProfessionalLocation,
        careprofessionalEditFormik.errors.careProfessionalLocation
    ])

    const PhoneNumberInput = useMemo(() => {
        return <PhoneNumber
            readOnly={mode === 'VIEW'}
            careprofessionalEditFormik={careprofessionalEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'careProfessionalEmail')
            }}
        />
    }, [
        mode,
        strings,
        careprofessionalEditFormik.values.careProfessionalPhoneNumber,
        careprofessionalEditFormik.errors.careProfessionalPhoneNumber
    ])

    const EmailInput = useMemo(() => {
        return <Email
            readOnly={mode === 'VIEW'}
            careprofessionalEditFormik={careprofessionalEditFormik}
            handleKeyDown={(e) => {
                if (e.key === 'Enter') { careprofessionalEditFormik.handleSubmit() }
            }}
        />
    }, [
        mode,
        strings,
        careprofessionalEditFormik.values.careProfessionalEmail,
        careprofessionalEditFormik.errors.careProfessionalEmail
    ])

    const CancelButton = <button type={'button'}
        className={[
            'btn btn-secondary w-100 py-2'
        ].join(' ')}
        onClick={() => {
            // if you made changes to the formik, show a warning first via popup
            const obj = careprofessionalEditFormik.touched

            interface FormikTouched {
                [key: string]: boolean;
              }

            function hasTrueValue (obj: FormikTouched) {
                for (const key in obj) {
                    if (obj[key] === true) {
                        return true
                    }
                }
                return false
            }

            const hasTrue = hasTrueValue(obj)
            if (hasTrue) {
                // show warning pls
                setShowCancelWarning(true)
            } else {
                // go back to view
                const isValid = validateRoute(
                    activeModules.arr,
                    MODULE_TABLE.fmt.moduleName,
                    MODULE_TABLE.fmt.routes.viewCareprofessional,
                    true
                )

                setModeButtonClicked(true)

                dispatch(push(
                    _.replace(isValid.route,
                        ':careprofessionalId',
                        careprofessionalId || ''
                    )
                ))
            }
        }}>
        {strings.app?.text.cancel}
    </button>

    const LoadingContent = (
        <div className={'spinner-container'}>
            <span className={'spinner-border spinner-border-sm'}></span>
            <span className={'ms-2'}>{
                strings.app?.text.loading || ''
            }</span>
        </div>
    )

    const SubmitButton = <button type={'button'}
        disabled={
        // step 1 shouldn't be disabled here because of formik.
            false
        // step 2 can be skipped entirely.
        }
        className={[
            'btn btn-primary w-100 fw-semibold py-2'
        ].join(' ')}
        onClick={() => {
            careprofessionalEditFormik.handleSubmit()
        }}>
        { editCareProfessionalMutation.isLoading
            ? LoadingContent
            : strings.app?.text.save}
    </button>

    const EditButton = <button type={'button'}
        className={[
            'btn btn-secondary w-100 py-2'
        ].join(' ')}
        onClick={() => {
            const isValid = validateRoute(
                activeModules.arr,
                MODULE_TABLE.fmt.moduleName,
                MODULE_TABLE.fmt.routes.editCareprofessional,
                true
            )

            setModeButtonClicked(true)

            dispatch(push(
                _.replace(isValid.route,
                    ':careprofessionalId',
                    careprofessionalId || ''
                )
            ))
        }}>
        {strings.fmt?.text.department.details.edit}
    </button>

    const ChangeProfilePictureButton = () => {
        const LoadingContent = (
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        )

        return <>
            <input
                type={'file'}
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <button type={'button'}
                className={[
                    'btn btn-secondary w-100 mt-5'
                ].join(' ')}
                onClick={() => {
                    if (fileInputRef.current) {
                        fileInputRef.current.click()
                    }
                }}>
                { editCareProfessionalMutation.isLoading
                    ? LoadingContent
                    : strings.fmt?.text.department.details.change}
            </button>
        </>
    }

    /** display list of careprofessionals here with the data table template. */

    const result = <div className={'row my-8'}>
        <div className={'col-12 col-lg'}>
            <div className={'edit-interface'}>
                <h2 className={'mb-5 fw-semibold '} >{strings.fmt?.text
                    .department.details.careprofessional}</h2>
                {/* group one */}
                <div className={'mb-6'}>
                    <h4 className={'mb-6'}>{strings.fmt?.text.facility.add.steps['3'].details}</h4>
                    {NameInput}
                    {ProfessionInput}
                    {LocationInput}
                    {PhoneNumberInput}
                    {EmailInput}
                </div>

                {mode === 'EDIT' && <div className={'container button-container'}>
                    <div className={'row align-items-center mt-6'}>
                        <div className={'col-6 col-lg-4 col-md-6 offset-lg-4 offset-md-0'}>
                            {CancelButton}
                        </div>
                        <div className={'col-6 col-lg-4 col-md-6'}>
                            {SubmitButton}
                        </div>
                    </div>
                </div>}

                {mode === 'VIEW' && <div className={'container button-container'}>
                    <div className={'align-items-center justify-content-end mt-6 row'}>
                        <div className={'col-6 col-lg-4 col-md-6'}>
                            {EditButton}
                        </div>
                    </div>
                </div>}
            </div>
        </div>
        <div className={'col-12 col-lg-auto mt-6 mt-lg-0'}>
            <div className={'picture-interface'}>
                <h2 className={'mb-5 fw-semibold '} >{strings.fmt?.text
                    .department.details.image}</h2>
                <div className={'text-center'}>

                    <div className={'img-wrapper mx-auto mb-2'}>

                        {<ProfilePic
                            showRecentlyUploaded={showRecentlyUploaded}
                            updateProfilePictureCareProfessionalMutation={{
                                data: updateProfilePictureCareProfessionalMutation.data
                            }}
                            getCareProfessionalMutation={getCareProfessionalMutation}
                        />}

                    </div>
                    {
                        mode === 'EDIT' && <ChangeProfilePictureButton/>
                    }
                </div>
            </div>
        </div>

    </div>

    return <div className={'careprofessional-page'}>

        {/* dashboard responsive header */}
        <DashboardResponsiveHeader />

        <div className={'main-content'}>

            <div className={'container-fluid mx-auto'}>
                <div className={'row justify-content-between pt-16 pb-0 px-5'}>
                    <div className={'col-auto'}>
                        <a onClick={(e) => {
                            e.preventDefault()
                            dispatch(back())
                        }} className={'btn btn-round btn-sm-md'}>
                            <i
                                className={'fa-light fa-arrow-left'}
                                aria-hidden={'true'}>
                            </i>
                        </a>
                    </div>
                    <div className={'col-auto'}>
                        <h1 className={'my-0'}>{
                            getCareProfessionalMutation.data
                                ?.data.careProfessionalName
                        }</h1>
                    </div>
                    <div className={'col-auto'}>
                        <a href={'#'}
                            onClick={(e) => {
                                e.preventDefault()
                            }} className={[
                                'btn btn-round btn-sm-md invisible'
                            ].join(' ')}>
                            <i
                                className={'fa-light fa-arrow-right'}
                                aria-hidden={'true'}
                            >
                            </i>
                        </a>
                    </div>
                </div>
                <div className={'row justify-content-between mt-5'}>
                    <div className={'col'}>
                        <div className={
                            'container-fluid px-5 px-sm-3 careprofessional-body'
                        }>
                            <div className={'h-100 row'}>
                                <div className={' col-12 mx-auto'}>
                                    {result}
                                </div>
                            </div>
                        </div>

                        <Modal centered={true} isOpen={showCancelWarning} toggle={() => {
                            setShowCancelWarning(!showCancelWarning)
                        }}>
                            <ModalHeader className={'justify-content-between'} toggle={() => {
                                setShowCancelWarning(!showCancelWarning)
                            }} close={ <a className={'btn btn-round '}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowCancelWarning(false)
                                }}>
                                <i className={'fa-light fa-multiply'} aria-hidden={'true'}>
                                </i>
                            </a>}
                            >
                                <span className={'h5'}>{
                                    strings.fmt?.message?.data_edit_warning?.title || ''
                                }</span>
                            </ModalHeader>
                            <ModalBody>
                                {/* content changes if idle / expire sessions expired. */}
                                <small className={'d-block my-4'}>
                                    {strings.fmt?.message?.data_edit_warning?.content || ''}
                                </small>
                                <div className={'row justify-content-end'}>
                                    <div className={'col-auto mb-2 mb-md-0'}>
                                        <button
                                            type={'button'}
                                            className={'btn btn-primary w-100'}
                                            onClick={ () => {
                                                setShowCancelWarning(false)
                                            }}>
                                            {strings.app?.text?.no || ''}
                                        </button>
                                    </div>
                                    <div className={'col-auto'}>
                                        <button type={'button'} onClick={() => {
                                            const isValid = validateRoute(
                                                activeModules.arr,
                                                MODULE_TABLE.fmt.moduleName,
                                                MODULE_TABLE.fmt
                                                    .routes.viewCareprofessional,
                                                true
                                            )

                                            setModeButtonClicked(true)

                                            dispatch(push(
                                                _.replace(isValid.route,
                                                    ':careprofessionalId',
                                                    careprofessionalId || ''
                                                )
                                            ))

                                            setShowCancelWarning(false)
                                        }} className={'btn btn-primary w-100'}>
                                            {strings.app?.text.yes}
                                        </button>

                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>

                    </div>
                </div>

            </div>

        </div>

        <div
            className={'position-fixed bottom-0 end-0 pe-5 fs-label fw-light version-text'}
        >
            {MODULE_VERSION}
        </div>
    </div>
}

export default CareprofessionalDetailsInterface
