import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { TokenData } from '@app/types/type.token'
import { useEditCareProfessionalMutation, useGetCareProfessionalMutation } from '@fmt/api'
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import DeptLocation from '@fmt/components/careprofessionals/details/inputs/DeptLocation'
import Email from '@fmt/components/careprofessionals/details/inputs/Email'
import CareProfessionalName from '@fmt/components/careprofessionals/details/inputs/Name'
import PhoneNumber from '@fmt/components/careprofessionals/details/inputs/PhoneNumber'
import Profession from '@fmt/components/careprofessionals/details/inputs/Profession'
import {
    CAREPROFESSIONAL_FORMIK_INITIAL_VALUES,
    CAREPROFESSIONAL_VALIDATION_SCHEMA
} from '@fmt/constants'
import { CareProfessional, CareProfessionalKeys } from '@fmt/type'
import { push } from '@lagunovsky/redux-react-router'
import { useFormik } from 'formik'
import _ from 'lodash'

const AccordionComponent = (
    { mode, isOpen, obj, fetchData }:
     { mode: 'EDIT' | 'VIEW',
     isOpen?: boolean, obj: CareProfessional, fetchData: (token: TokenData) => () => void}) => {
    const token = useAppSelector(selectToken)

    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const validateRoute = useValidateRoute()
    const dispatch = useAppDispatch()

    const [getCareProfessional, getCareProfessionalMutation] = useGetCareProfessionalMutation()
    // accordion components are now editable.
    const [editCareProfessional, editCareProfessionalMutation] = useEditCareProfessionalMutation()

    const careprofessionalEditFormik = useFormik({
        initialValues: CAREPROFESSIONAL_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CAREPROFESSIONAL_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || '',
            strings.app?.message.error.email || ''
        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken(
                        {
                            value: token.value,
                            id: token.id
                        },
                        token.mode
                    )
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths.editCareProfessional.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        editCareProfessional({
                            authToken: newToken.value,
                            data: {
                                careProfessionalId: obj.careProfessionalId || '',
                                careProfessionalName: values.careProfessionalName || '',
                                careProfessionalPhoneNumber:
                                values.careProfessionalPhoneNumber || '',
                                careProfessionalEmail: values.careProfessionalEmail || '',
                                careProfessionalLocation: values.careProfessionalLocation || '',
                                careProfessionalProfession: values.careProfessionalProfession || ''
                            }
                        })
                    }
                }
            }
            call()
        }
    })

    useEffect(() => {
        const data = editCareProfessionalMutation.data

        if (data) {
            if (data.status === 'OK') {
                fetchData(token)
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [editCareProfessionalMutation.data])

    useEffect(() => {
        if (editCareProfessionalMutation.error) {
            const message = getErrorText(editCareProfessionalMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [editCareProfessionalMutation.error])

    /** create fetch data function */
    const fetchDataSingularData = (token: TokenData) => {
        let isMounted = true

        const call = async () => {
            if (
                token.valid &&
                isOpen === true &&
                getCareProfessionalMutation.isSuccess === false
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths
                            .getCareProfessional.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getCareProfessional({
                            authToken: newToken.value,
                            data: {
                                careProfessionalId: obj.careProfessionalId
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
        }
    }

    // ONLY fetch it when obj.needAccordion is true AND if isSuccess is false.
    useEffect(() => {
        return fetchDataSingularData(token)
    }, [token.id, token.valid, isOpen, getCareProfessionalMutation.isSuccess])

    useEffect(() => {
        if (getCareProfessionalMutation.data) {
            const data = getCareProfessionalMutation.data
            careprofessionalEditFormik.setValues({
                ...careprofessionalEditFormik.values,
                ...data.data
            })
        }
    }, [getCareProfessionalMutation.data])

    useEffect(() => {
        if (getCareProfessionalMutation.error) {
            const message = getErrorText(getCareProfessionalMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getCareProfessionalMutation.error])

    const ViewButton = <button
        type={'button'}
        className={'btn btn-secondary w-100'}
        onClick={(e) => {
            e.stopPropagation()

            const isValid = validateRoute(
                activeModules.arr,
                MODULE_TABLE.fmt.moduleName,
                MODULE_TABLE.fmt.routes.viewCareprofessional,
                true
            )

            dispatch(push(
                _.replace(isValid.route,
                    ':careprofessionalId',
                    obj.careProfessionalId
                )
            ))
        }}
    >
        {strings.fmt?.text.careprofessional.view_careprofessional}
    </button>

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: CareProfessionalKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                careprofessionalEditFormik.handleSubmit()
            }
        }
    }

    const NameInput = useMemo(() => {
        return <CareProfessionalName
            readOnly={mode === 'VIEW'}
            careprofessionalEditFormik={careprofessionalEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'careProfessionalProfession')
            }}
        />
    }, [
        mode,
        strings,
        careprofessionalEditFormik.values.careProfessionalName,
        careprofessionalEditFormik.errors.careProfessionalName
    ])

    const ProfessionInput = useMemo(() => {
        return <Profession
            readOnly={mode === 'VIEW'}
            careprofessionalEditFormik={careprofessionalEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'careProfessionalLocation')
            }}
        />
    }, [
        mode,
        strings,
        careprofessionalEditFormik.values.careProfessionalProfession,
        careprofessionalEditFormik.errors.careProfessionalProfession
    ])

    const LocationInput = useMemo(() => {
        return <DeptLocation
            readOnly={mode === 'VIEW'}
            careprofessionalEditFormik={careprofessionalEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'careProfessionalPhoneNumber')
            }}
        />
    }, [
        mode,
        strings,
        careprofessionalEditFormik.values.careProfessionalLocation,
        careprofessionalEditFormik.errors.careProfessionalLocation
    ])

    const PhoneNumberInput = useMemo(() => {
        return <PhoneNumber
            readOnly={mode === 'VIEW'}
            careprofessionalEditFormik={careprofessionalEditFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'careProfessionalEmail')
            }}
        />
    }, [
        mode,
        strings,
        careprofessionalEditFormik.values.careProfessionalPhoneNumber,
        careprofessionalEditFormik.errors.careProfessionalPhoneNumber
    ])

    const EmailInput = useMemo(() => {
        return <Email
            readOnly={mode === 'VIEW'}
            careprofessionalEditFormik={careprofessionalEditFormik}
            handleKeyDown={(e) => {
                if (e.key === 'Enter') { careprofessionalEditFormik.handleSubmit() }
            }}
        />
    }, [
        mode,
        strings,
        careprofessionalEditFormik.values.careProfessionalEmail,
        careprofessionalEditFormik.errors.careProfessionalEmail
    ])

    const LoadingContent = (
        <div className={'spinner-container'}>
            <span className={'spinner-border spinner-border-sm'}></span>
            <span className={'ms-2'}>{
                strings.app?.text.loading || ''
            }</span>
        </div>
    )

    const SubmitButton = <button type={'button'}
        disabled={
        // step 1 shouldn't be disabled here because of formik.
            false
        // step 2 can be skipped entirely.
        }
        className={[
            'btn btn-primary w-100 px-8'
        ].join(' ')}
        onClick={() => {
            careprofessionalEditFormik.handleSubmit()
        }}>
        { editCareProfessionalMutation.isLoading
            ? LoadingContent
            : strings.app?.text.save}
    </button>

    return <>
        {
            getCareProfessionalMutation.isLoading && <small className={'d-block text-center py-2'}>
                <div className={'spinner-container'}>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{
                        strings.app?.text.loading || ''
                    }</span>
                </div>
            </small>
        }
        {getCareProfessionalMutation.isSuccess && <div className={'px-16'}>
            <h5 className={'col fw-semibold'}>{strings.fmt?.text
                .department.details.careprofessional}</h5>
            {/* group one */}
            <div className={'mb-6'}>
                {NameInput}
                {ProfessionInput}
                {LocationInput}
                {PhoneNumberInput}
                {EmailInput}
            </div>

            {mode === 'EDIT' && <div className={'container button-container'}>
                <div className={'row align-items-center mt-6'}>
                    <div className={'col-6 col-lg-4 col-md-6 offset-lg-4 offset-md-0'}>
                        {ViewButton}
                    </div>
                    <div className={'col-6 col-lg-4 col-md-6'}>
                        {SubmitButton}
                    </div>
                </div>
            </div>}
        </div>}
    </>
}

export default AccordionComponent
