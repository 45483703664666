import { MODULE_TABLE } from '@app/app.config'
import {
    ACTION_MUTATION_PROMISE,
    MOBILE_RESPONSIVE_LIMIT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken, setToken } from '@app/slices/slice.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import { back, push } from '@lagunovsky/redux-react-router'
import {
    useGetOverallProgressMutation,
    useGetPersonalDetailsMutation,
    useUpdateProfilePictureMutation
} from '@profile/api'
import { MODULE_VERSION } from '@profile/constants'

import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { TokenData } from '@app/types/type.token'

import { ImageDisplay } from '@profile/styles/ImageDisplay'
import { useGetMenuMutation } from '@sidebar/api'
import {
    HeaderContent,
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/profilePage/Components'

import _ from 'lodash'
import { useMediaQuery } from 'react-responsive'
const MyProfile = () => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)
    const activeModules = useAppSelector(selectActiveModules)

    const validateRoute = useValidateRoute()
    const revalidateToken = useRevalidateToken()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()
    const [getMenu, getMenuMutation] = useGetMenuMutation()

    const isBeyondMd = useMediaQuery({
        query: `(min-width:  ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    const [getOverallProgress, getOverallProgressMutation] = useGetOverallProgressMutation()

    // upload profile picture logic.
    const [file, setFile] = useState<File | null>(null)
    const [updateProfilePicture, updateProfilePictureMutation] = useUpdateProfilePictureMutation()
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [getPersonalDetails, getPersonalDetailsMutation] = useGetPersonalDetailsMutation()

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0]
        if (selectedFile) {
            setFile(selectedFile)
        }
    }

    useEffect(() => {
        if (getMenuMutation.error) {
            const message = getErrorText(getMenuMutation.error)
            if (message.includes('Aborted') === false) {
                console.error(message)
                toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [getMenuMutation.error])

    const handleUpload = async () => {
        if (file) {
            const formData = new FormData()
            formData.append('profilePicture', file)

            const newToken = await revalidateToken({
                value: token.value,
                id: token.id
            }, token.mode)

            const isValid = validateAPIPath(
                activeModules.arr,
                MODULE_TABLE.profile.moduleName,
                MODULE_TABLE.profile.apiPaths.updateProfilePicture.path,
                true
            )

            if (isValid && newToken.value) {
                updateProfilePicture({
                    data: formData,
                    authToken: newToken.value
                }).unwrap().then((data) => {
                    if (data.status === 'OK') {
                        // toast success message.
                        toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                        // we need to use this token now so set it.
                        dispatch(setToken({
                            id: token.id,
                            value: data.data.token,
                            valid: token.valid,
                            mode: token.mode
                        }))
                    } else {
                        toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                    }
                })
            }
        }
    }

    useEffect(() => {
        handleUpload()
    }, [file])

    useEffect(() => {
        if (updateProfilePictureMutation.error) {
            const message = getErrorText(updateProfilePictureMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [updateProfilePictureMutation.error])

    const unsubscribeGetPersonalDetails = () => {
        const unsubscribeMutation = getPersonalDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.reset()
    }

    const unsubscribeGetOverallProgress = () => {
        const unsubscribeMutation = getOverallProgress({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetMenu = () => {
        const unsubscribeMutation = getMenu({ } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.reset()
    }

    useEffect(() => {
        if (getPersonalDetailsMutation.error) {
            const message = getErrorText(getPersonalDetailsMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPersonalDetailsMutation.error])

    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetPersonalDetails()
        unsubscribeGetOverallProgress()
        unsubscribeGetMenu()

        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getOverallProgressPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getMenuPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.profile.moduleName,
                        MODULE_TABLE.profile.apiPaths.getPersonalDetails.path,
                        true
                    )

                    // NOTE: not all need to show a toast error.
                    // only do this error toast method AFTER authentication.
                    if (foundApiPath && newToken.value) {
                        promise = getPersonalDetails({
                            authToken: newToken.value
                        })
                    } else {
                        if (strings.login?.message.error.api_path) {
                            toast.error(
                                `${ MODULE_TABLE.profile
                                    .apiPaths.getPersonalDetails.path }:
                                ${ strings.login?.message.error.api_path }`.trim(),
                                { ...TOASTIFY_DEFAULT_OPTIONS }
                            )
                        }
                    }

                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.profile.moduleName,
                        MODULE_TABLE.profile.apiPaths.getOverallProgress.path,
                        true
                    )

                    if (isValid2 && newToken.value) {
                        getOverallProgressPromise = getOverallProgress({
                            authToken: newToken.value
                        })
                    }

                    const isValid3 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.profile.moduleName,
                        MODULE_TABLE.profile.apiPaths.getMenu.path,
                        true
                    )

                    // NOTE: not all need to show a toast error.
                    // only do this error toast method AFTER authentication.
                    if (isValid3 && newToken.value) {
                        getMenuPromise = getMenu({
                            authToken: newToken.value,
                            data: {
                                isProfile: true
                            }
                        })
                    } else {
                        if (strings.login?.message.error.api_path) {
                            // toast.error(
                            //     `${ MODULE_TABLE.login
                            //         .apiPaths.getMenu.path }:
                            //     ${ strings.login?.message.error.api_path }`.trim(),
                            //     { ...TOASTIFY_DEFAULT_OPTIONS }
                            // )
                        }
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
            getOverallProgressPromise && getOverallProgressPromise.abort()
            getMenuPromise && getMenuPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [activeModules.id])

    const ProfilePic = token.details.pic
        ? <ImageDisplay
            url={token.details.pic}
            className={'rounded-pill profile'}
        />
        : <div
            className={[
                'profile-img-placeholder profile rounded-pill'
            ].join(' ')}
        >
        </div>

    const menuItems = getMenuMutation.data?.data || []

    const MenuItems = <div>
        <div className={'card'}>
            <div className={'card-body py-1'}>
                <div
                    className={'row row-cols-1 row-cols-md-2 pagelinks'}
                    role={'group'}
                >
                    {
                        menuItems.map((item, index) => (
                            <div className={'col'} key={index}>
                                <div className={[
                                    'pagelink',
                                    index === 0
                                        ? 'border-top-0'
                                        : isBeyondMd && index === 1
                                            ? 'border-top-0'
                                            : '',
                                    index === menuItems.length - 1
                                        ? 'border-bottom-0'
                                        : isBeyondMd && index === menuItems.length - 2
                                            ? 'border-bottom-0'
                                            : ''
                                ].join(' ')}>
                                    <i className={[
                                        item.pageIcon, 'fa-light',
                                        isMobile ? 'me-3' : 'me-5'
                                    ].join(' ')} aria-hidden={'true'} />
                                    <div className={'title'}>{item.pageName}</div>
                                    <div className={'link-wrapper ms-auto'}>
                                        <a href={'#'} onClick={(e) => {
                                            e.preventDefault()
                                            // Perform action based on the item clicked
                                            // console.log(`Clicked ${ item.title }`)
                                            const isValid = validateRoute(
                                                activeModules.arr,
                                                MODULE_TABLE.profile.moduleName,
                                                item.pageLink,
                                                true
                                            )

                                            if (isValid) {
                                                dispatch(push(isValid.route))
                                            }
                                        }} className={'nav-link stretched-link'}>
                                            <i
                                                className={
                                                    'fa-light fa-chevron-right small'
                                                } aria-hidden={'true'} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    </div>

    const ProfileHeader = <div className={'container'}>
        <div className={!isMobile ? 'row my-5' : 'row mt-3 mb-5 text-center'}>
            <div className={!isMobile ? 'col-auto' : 'col-12'}>
                <div className={'img-wrapper mx-auto mb-2'}>

                    {ProfilePic}

                    <input
                        type={'file'}
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <button
                        type={'button'}
                        className={'btn  btn-sm'}
                        onClick={() => {
                            if (fileInputRef.current) {
                                fileInputRef.current.click()
                            }
                        }}
                    >
                        <i
                            className={'fa-light fa-pen-to-square'}
                            aria-hidden={'true'}>

                        </i>
                    </button>

                </div>
            </div>
            <div className={!isMobile ? 'col' : 'col-12'}>
                <span className={[
                    'profile-title',
                    isMobile ? 'h5 mt-3' : 'h2'
                ].join(' ')}>{[
                        getPersonalDetailsMutation.data?.data.firstName || '',
                        getPersonalDetailsMutation.data?.data.lastName || ''
                    ].join(' ')}</span>

                <p className={isMobile ? 'mb-1' : 'mb-2'}>{
                    getPersonalDetailsMutation.data?.data.email || ''}</p>

            </div>
        </div>
    </div>

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'container-fluid header text-center'}>
        <div className={'align-items-center h-100 justify-content-between row'}>
            <div className={'col-auto'}>
                {/* <h4 className={'mb-0 '} onClick={() => {
                    dispatch(toggleMenuTopSidebarMobileBar(
                        !showMenuBar.mobile.top.sidebar
                    ))
                }}>
                    <i
                        className={ [
                            'fa-light',
                            showMenuBar.mobile.top.sidebar ? 'fa-bars-staggered' : 'fa-bars'
                        ].join(' ')}
                    >
                    </i>
                </h4> */}
            </div>
            <div className={'col-auto'}>
                {/* image of logo goes here. fixed width but height can change whatever */}
                <img src={'/images_new/header/logo.svg'} />
            </div>
            <div className={'col-auto'}>

            </div>
        </div>

    </div>

    const desktopResult = <>
        <HeaderImage
            className={'p-4'}
            url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} >
        </HeaderImage>
        <HeaderGradient />
        <HeaderContent
            className={'p-4'}
        >
            <button
                type={'button'}
                className={[
                    'btn btn-round btn-rounded'
                ].join('')}
                onClick={(e) => {
                    e.preventDefault()
                    dispatch(back())
                }}
            >
                <i
                    className={[
                        'fa-light',
                        'fa-arrow-left'
                    ].join(' ')}
                    aria-hidden={'true'}>
                </i>
            </button>
        </HeaderContent>
        <div className={'main-content'}>
            <div className={'container-fluid mx-auto'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-10 col-md-8'}>
                        <h1 className={'mt-4'}>{strings.profile?.text.profile}</h1>

                        {ProfileHeader}

                        {MenuItems}

                    </div>
                </div>

            </div>
        </div>
        <div
            className={'position-fixed bottom-0 end-0 pe-5 fs-label fw-light version-text'}
        >
            {MODULE_VERSION}
        </div>
    </>

    const mobileResult = <>
        {mobileHeader}
        <div className={'main-content'}>
            <div className={'container-fluid px-4'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-12'}>
                        <div className={'row align-items-center g-3'}>
                            <div className={'col-auto'}>
                                <button
                                    type={'button'}
                                    className={[
                                        'btn btn-round btn-rounded'
                                    ].join('')}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        dispatch(back())
                                    }}
                                >
                                    <i
                                        className={[
                                            'fa-light',
                                            'fa-arrow-left'
                                        ].join(' ')}
                                        aria-hidden={'true'}>
                                    </i>
                                </button>
                            </div>
                            <div className={'col-auto'}>
                                <h2 className={'mb-0'}>{strings.profile?.text.profile}</h2>

                            </div>
                        </div>

                        {ProfileHeader}

                        {MenuItems}

                    </div>
                </div>

            </div>
        </div>
    </>

    return <div className={'profile-page position-relative'}>
        {/* the container */}

        {
            isMobile ? mobileResult : desktopResult
        }
    </div>
}

export default MyProfile
