import { useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'

import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { selectToken } from '@app/slices/slice.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import {
    DEPARTMENT_FORMIK_INITIAL_VALUES,
    DEPARTMENT_VALIDATION_SCHEMA,
    MODULE_VERSION
} from '@fmt/constants'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'

import { useAddDepartmentMutation } from '@fmt/api'
import DashboardResponsiveHeader from '@fmt/components/DashboardResponsiveHeader'
import Address from '@fmt/components/departments/details/inputs/Address'
import City from '@fmt/components/departments/details/inputs/City'
import DepartmentName from '@fmt/components/departments/details/inputs/DepartmentName'
import Email from '@fmt/components/departments/details/inputs/Email'
import GeneralPhoneNumber from '@fmt/components/departments/details/inputs/GeneralPhoneNumber'
import PostalCode from '@fmt/components/departments/details/inputs/PostalCode'
import StreetName from '@fmt/components/departments/details/inputs/StreetName'
import Website from '@fmt/components/departments/details/inputs/Website'
import { back, push } from '@lagunovsky/redux-react-router'

import { DepartmentKeys } from '@fmt/type'
import _ from 'lodash'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const DepartmentAddInterface = () => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)

    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()
    const validateRoute = useValidateRoute()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()

    const [showCancelWarning, setShowCancelWarning] = useState<boolean>(false)

    const [addDepartment, addDepartmentMutation] = useAddDepartmentMutation()

    const departmentAddFormik = useFormik({
        initialValues: DEPARTMENT_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: DEPARTMENT_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || ''
        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken(
                        {
                            value: token.value,
                            id: token.id
                        },
                        token.mode
                    )
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths.addDepartment.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        addDepartment({
                            authToken: newToken.value,
                            data: {
                                departmentName: values.departmentName || '',
                                departmentAddressStreet: values.departmentAddressStreet,
                                departmentAddressNumber: values.departmentAddressNumber,
                                departmentAddressSuffix: values.departmentAddressSuffix || '',
                                departmentPostalcode: values.departmentPostalcode,
                                departmentCity: values.departmentCity,
                                departmentPhonenumber: values.departmentPhonenumber || '',
                                departmentEmailAddress: values.departmentEmailAddress || '',
                                departmentWebsite: values.departmentWebsite || ''
                            }
                        })
                    }
                }
            }
            call()
        }
    })

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    useEffect(() => {
        const data = addDepartmentMutation.data

        if (data) {
            if (data.status === 'OK') {
                // close component by clearing the add formik.
                // departmentAddFormik.resetForm()
                // redirect user to edit page please.
                const isValid = validateRoute(
                    activeModules.arr,
                    MODULE_TABLE.fmt.moduleName,
                    MODULE_TABLE.fmt.routes.editDepartment,
                    true
                )

                dispatch(push(
                    _.replace(isValid.route,
                        ':departmentId',
                        data.data.departmentId || ''
                    )
                ))
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [addDepartmentMutation.data])

    useEffect(() => {
        if (addDepartmentMutation.error) {
            const message = getErrorText(addDepartmentMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [addDepartmentMutation.error])

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: DepartmentKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                departmentAddFormik.handleSubmit()
            }
        }
    }

    const DepartmentNameInput = useMemo(() => {
        return <DepartmentName
            readOnly={false}
            departmentEditFormik={departmentAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'departmentPostalcode')
            }}
        />
    }, [

        strings,
        departmentAddFormik.values.departmentName,
        departmentAddFormik.errors.departmentName
    ])

    const PostalCodeInput = useMemo(() => {
        return <PostalCode
            readOnly={false}
            departmentEditFormik={departmentAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'departmentAddressNumber')
            }}
        />
    }, [

        strings,
        departmentAddFormik.values.departmentPostalcode,
        departmentAddFormik.errors.departmentPostalcode
    ])

    const AddressInput = useMemo(() => {
        return <Address
            readOnly={false}
            departmentEditFormik={departmentAddFormik}
            handleKeyDown={handleKeyDown}

        />
    }, [

        strings,
        departmentAddFormik.values.departmentAddressNumber,
        departmentAddFormik.errors.departmentAddressNumber,
        departmentAddFormik.values.departmentAddressSuffix,
        departmentAddFormik.errors.departmentAddressSuffix
    ])

    const StreetNameInput = useMemo(() => {
        return <StreetName
            readOnly={false}
            departmentEditFormik={departmentAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'departmentCity')
            }}
        />
    }, [

        strings,
        departmentAddFormik.values.departmentAddressStreet,
        departmentAddFormik.errors.departmentAddressStreet
    ])

    const CityInput = useMemo(() => {
        return <City
            readOnly={false}
            departmentEditFormik={departmentAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'departmentPhonenumber')
            }}
        />
    }, [

        strings,
        departmentAddFormik.values.departmentCity,
        departmentAddFormik.errors.departmentCity
    ])

    /** GROUP 2 */

    const GeneralPhoneNumberInput = useMemo(() => {
        return <GeneralPhoneNumber
            readOnly={false}
            departmentEditFormik={departmentAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'departmentEmailAddress')
            }}
        />
    }, [

        strings,
        departmentAddFormik.values.departmentPhonenumber,
        departmentAddFormik.errors.departmentPhonenumber
    ])

    const EmailInput = useMemo(() => {
        return <Email
            readOnly={false}
            departmentEditFormik={departmentAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'departmentWebsite')
            }}
        />
    }, [

        strings,
        departmentAddFormik.values.departmentEmailAddress,
        departmentAddFormik.errors.departmentEmailAddress
    ])

    const WebsiteInput = useMemo(() => {
        return <Website
            readOnly={false}
            departmentEditFormik={departmentAddFormik}
            handleKeyDown={(e) => {
                if (e.key === 'Enter') { departmentAddFormik.handleSubmit() }
            }}
        />
    }, [

        strings,
        departmentAddFormik.values.departmentWebsite,
        departmentAddFormik.errors.departmentWebsite
    ])

    const CancelButton = <button type={'button'}
        className={[
            'btn btn-secondary w-100 py-2'
        ].join(' ')}
        onClick={() => {
            // if you made changes to the formik, show a warning first via popup
            const obj = departmentAddFormik.touched

            interface FormikTouched {
                [key: string]: boolean;
              }

            function hasTrueValue (obj: FormikTouched) {
                for (const key in obj) {
                    if (obj[key] === true) {
                        return true
                    }
                }
                return false
            }

            const hasTrue = hasTrueValue(obj)
            if (hasTrue) {
                // show warning pls
                setShowCancelWarning(true)
            } else {
                // reset formik.
                departmentAddFormik.resetForm()
            }
        }}>
        {strings.app?.text.cancel}
    </button>

    const LoadingContent = (
        <div className={'spinner-container'}>
            <span className={'spinner-border spinner-border-sm'}></span>
            <span className={'ms-2'}>{
                strings.app?.text.loading || ''
            }</span>
        </div>
    )

    const SubmitButton = <button type={'button'}
        disabled={
            // step 1 shouldn't be disabled here because of formik.
            false
            // step 2 can be skipped entirely.
        }
        className={[
            'btn btn-primary w-100 fw-semibold py-2'
        ].join(' ')}
        onClick={() => {
            departmentAddFormik.handleSubmit()
        }}>
        { addDepartmentMutation.isLoading
            ? LoadingContent
            : strings.app?.text.save}
    </button>

    /** display list of departments here with the data table template. */

    const result = <div className={'row my-8'}>
        <div className={'col-12 col-lg'}>
            <div className={'add-interface'}>
                <h2 className={'mb-5 fw-semibold '} >{strings.fmt
                    ?.text.facility.add.steps['2'].department_data}</h2>
                {/* group one */}
                <div className={'mb-6'}>
                    <h4 className={'mb-6'}>{strings.fmt?.text.facility
                        .menu.name_and_address_details}</h4>
                    {DepartmentNameInput}
                    {PostalCodeInput}
                    {AddressInput}
                    {StreetNameInput}
                    {CityInput}
                </div>
                {/* group two */}

                <div>
                    <h4 className={'mb-6'}>{strings.fmt
                        ?.text.facility.menu.contact_details}</h4>
                    {GeneralPhoneNumberInput}
                    {EmailInput}
                    {WebsiteInput}
                </div>

                <div className={'container'}>
                    <div className={'row align-items-center mt-6'}>
                        <div className={'col-6 col-lg-4 col-md-6 offset-lg-4 offset-md-0'}>
                            {CancelButton}
                        </div>
                        <div className={'col-6 col-lg-4 col-md-6'}>
                            {SubmitButton}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    return <div className={'department-page'}>

        {/* dashboard responsive header */}
        <DashboardResponsiveHeader />

        <div className={'main-content'}>

            <div className={'container-fluid mx-auto'}>
                <div className={'row justify-content-between pt-16 pb-0 px-5'}>
                    <div className={'col-auto'}>
                        <a onClick={(e) => {
                            e.preventDefault()
                            dispatch(back())
                        }} className={'btn btn-round btn-sm-md'}>
                            <i
                                className={'fa-light fa-arrow-left'}
                                aria-hidden={'true'}>
                            </i>
                        </a>
                    </div>
                    <div className={'col-auto'}>
                        <h1 className={'my-0'}>{strings.fmt
                            ?.text.facility.add.steps['3'].department}</h1>
                    </div>
                    <div className={'col-auto'}>
                        <a href={'#'}
                            onClick={(e) => {
                                e.preventDefault()
                            }} className={[
                                'btn btn-round btn-sm-md invisible'
                            ].join(' ')}>
                            <i
                                className={'fa-light fa-arrow-right'}
                                aria-hidden={'true'}
                            >
                            </i>
                        </a>
                    </div>
                </div>
                <div className={'row justify-content-between mt-5'}>
                    <div className={'col'}>
                        <div className={
                            'container-fluid px-5 px-sm-3 department-body list-interface'
                        }>
                            <div className={'h-100 row'}>
                                <div className={' col-12 mx-auto'}>
                                    {result}
                                </div>
                            </div>
                        </div>

                        <Modal centered={true} isOpen={showCancelWarning} toggle={() => {
                            setShowCancelWarning(!showCancelWarning)
                        }}>
                            <ModalHeader className={'justify-content-between'} toggle={() => {
                                setShowCancelWarning(!showCancelWarning)
                            }} close={ <a className={'btn btn-round '}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowCancelWarning(false)
                                }}>
                                <i className={'fa-light fa-multiply'} aria-hidden={'true'}>
                                </i>
                            </a>}
                            >
                                <span className={'h5'}>{
                                    strings.fmt?.message?.data_edit_warning?.title || ''
                                }</span>
                            </ModalHeader>
                            <ModalBody>
                                {/* content changes if idle / expire sessions expired. */}
                                <small className={'d-block my-4'}>
                                    {strings.fmt?.message?.data_edit_warning?.content || ''}
                                </small>
                                <div className={'row justify-content-end'}>
                                    <div className={'col-auto mb-2 mb-md-0'}>
                                        <button
                                            type={'button'}
                                            className={'btn btn-primary w-100'}
                                            onClick={ () => {
                                                setShowCancelWarning(false)
                                            }}>
                                            {strings.app?.text?.no || ''}
                                        </button>
                                    </div>
                                    <div className={'col-auto'}>
                                        <button type={'button'} onClick={() => {
                                        // reset formik.
                                            departmentAddFormik.resetForm()
                                            setShowCancelWarning(false)
                                        }} className={'btn btn-primary w-100'}>
                                            {strings.app?.text.yes}
                                        </button>

                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    </div>
                </div>

            </div>

        </div>

        <div
            className={'position-fixed bottom-0 end-0 pe-5 fs-label fw-light version-text'}
        >
            {MODULE_VERSION}
        </div>
    </div>
}

export default DepartmentAddInterface
