import { useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'

import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { selectToken } from '@app/slices/slice.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import {
    CAREPATH_FORMIK_INITIAL_VALUES,
    CAREPATH_VALIDATION_SCHEMA,
    MODULE_VERSION
} from '@fmt/constants'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'

import { useAddCarepathMutation, useAddTreatmentCarepathMutation } from '@fmt/api'
import DashboardResponsiveHeader from '@fmt/components/DashboardResponsiveHeader'
import CarepathDateName from '@fmt/components/carepaths/details/inputs/DateName'
import CarepathName from '@fmt/components/carepaths/details/inputs/Name'
import { back, push } from '@lagunovsky/redux-react-router'

import LinkTreatmentFromCarepathDropdownAdd from '@fmt/components/carepaths/details/inputs/LinkTreatmentFromCarepathDropdownAdd'
import { CarepathKeys, TreatmentCarepathData } from '@fmt/type'
import _ from 'lodash'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const CarepathAddInterface = () => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)

    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()
    const validateRoute = useValidateRoute()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()

    const [showCancelWarning, setShowCancelWarning] = useState<boolean>(false)

    const [addCarepath, addCarepathMutation] = useAddCarepathMutation()
    const [addTreatmentCarepath, addTreatmentCarepathMutation] = useAddTreatmentCarepathMutation()

    // only one carepath can be linked to one treatment. so in the dropdown, only one option can
    // be clicked. use singular select.
    const [selectedTreatment, setSelectedTreatment] = useState<TreatmentCarepathData>()

    const [isOutsideClickTreatment, setIsOutsideClickTreatment] = useState(false)

    const carepathAddFormik = useFormik({
        initialValues: CAREPATH_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CAREPATH_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || ''
        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken(
                        {
                            value: token.value,
                            id: token.id
                        },
                        token.mode
                    )
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths.addCarepath.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        addCarepath({
                            authToken: newToken.value,
                            data: {
                                dateName: values.dateName || '',
                                carepathName: values.carepathName || ''
                            }
                        })
                    }
                }
            }
            call()
        }
    })

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    useEffect(() => {
        const data = addCarepathMutation.data

        if (data) {
            if (data.status === 'OK') {
                // UPDATE: November 23, 2023: If the user selected
                // a treatment to link, do that first.

                if (selectedTreatment) {
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.apiPaths.addTreatmentCarepath.path,
                        true
                    )

                    if (foundApiPath) {
                        addTreatmentCarepath({
                            authToken: token.value,
                            data: {
                                carepathId: data.data.carepathId,
                                treatmentId: selectedTreatment.treatmentId
                            }
                        })
                    }
                } else {
                // close component by clearing the add formik.
                // carepathAddFormik.resetForm()
                // redirect user to edit page please.
                    const isValid = validateRoute(
                        activeModules.arr,
                        MODULE_TABLE.fmt.moduleName,
                        MODULE_TABLE.fmt.routes.editCarepath,
                        true
                    )

                    dispatch(push(
                        _.replace(isValid.route,
                            ':carepathId',
                            data.data.carepathId || ''
                        )
                    ))
                }
            } else {
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [addCarepathMutation.data])

    useEffect(() => {
        const treatmentCarepathData = addTreatmentCarepathMutation.data
        const addCarepathData = addCarepathMutation.data

        if (treatmentCarepathData && addCarepathData) {
            if (treatmentCarepathData.status === 'OK') {
                // close component by clearing the add formik.
                // carepathAddFormik.resetForm()
                // redirect user to edit page please.
                const isValid = validateRoute(
                    activeModules.arr,
                    MODULE_TABLE.fmt.moduleName,
                    MODULE_TABLE.fmt.routes.editCarepath,
                    true
                )

                dispatch(push(
                    _.replace(isValid.route,
                        ':carepathId',
                        addCarepathData.data.carepathId || ''
                    )
                ))
            } else {
                toast.error(treatmentCarepathData.message, { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [addTreatmentCarepathMutation.data])

    useEffect(() => {
        if (addCarepathMutation.error) {
            const message = getErrorText(addCarepathMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [addCarepathMutation.error])

    useEffect(() => {
        if (addTreatmentCarepathMutation.error) {
            const message = getErrorText(addTreatmentCarepathMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [addTreatmentCarepathMutation.error])

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: CarepathKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                carepathAddFormik.handleSubmit()
            }
        }
    }

    const NameInput = useMemo(() => {
        return <CarepathName
            readOnly={false}
            carepathEditFormik={carepathAddFormik}
            handleKeyDown={(e) => {
                handleKeyDown(e, 'carepathName')
            }}
        />
    }, [
        strings,
        carepathAddFormik.values.carepathName,
        carepathAddFormik.errors.carepathName
    ])

    const DateNameInput = useMemo(() => {
        return <CarepathDateName
            readOnly={false}
            carepathEditFormik={carepathAddFormik}
            handleKeyDown={(e) => {
                if (e.key === 'Enter') { carepathAddFormik.handleSubmit() }
            }}
        />
    }, [
        strings,
        carepathAddFormik.values.dateName,
        carepathAddFormik.errors.dateName
    ])

    const CancelButton = <button type={'button'}
        className={[
            'btn btn-secondary w-100 py-2'
        ].join(' ')}
        onClick={() => {
            // if you made changes to the formik, show a warning first via popup
            const obj = carepathAddFormik.touched

            interface FormikTouched {
                [key: string]: boolean;
              }

            function hasTrueValue (obj: FormikTouched) {
                for (const key in obj) {
                    if (obj[key] === true) {
                        return true
                    }
                }
                return false
            }

            const hasTrue = hasTrueValue(obj)
            if (hasTrue) {
                // show warning pls
                setShowCancelWarning(true)
            } else {
                // reset formik.
                carepathAddFormik.resetForm()
            }
        }}>
        {strings.app?.text.cancel}
    </button>

    const LoadingContent = (
        <div className={'spinner-container'}>
            <span className={'spinner-border spinner-border-sm'}></span>
            <span className={'ms-2'}>{
                strings.app?.text.loading || ''
            }</span>
        </div>
    )

    const SubmitButton = <button type={'button'}
        disabled={
        // step 1 shouldn't be disabled here because of formik.
            false
        // step 2 can be skipped entirely.
        }
        className={[
            'btn btn-primary w-100 fw-semibold py-2'
        ].join(' ')}
        onClick={() => {
            carepathAddFormik.handleSubmit()
        }}>
        { addCarepathMutation.isLoading
            ? LoadingContent
            : strings.app?.text.save}
    </button>

    /** display list of carepaths here with the data table template. */

    const result = <div className={'row my-8'}>
        <div className={'col-12 col-lg'}>
            <div className={'add-interface'}>
                <h2 className={'mb-5 fw-semibold '} >{strings.fmt?.text.carepath.carepath}</h2>
                {/* group one */}
                <div className={'mb-6'}>
                    <h4 className={'mb-6'}>{strings.fmt?.text.facility.add.steps['3'].details}</h4>
                    {NameInput}
                    {DateNameInput}
                    <LinkTreatmentFromCarepathDropdownAdd
                        selectedTreatment={selectedTreatment}
                        setSelectedTreatment={setSelectedTreatment}
                        isOutsideClick={isOutsideClickTreatment}
                        setIsOutsideClick={setIsOutsideClickTreatment}
                    />
                </div>

                <div className={'container'}>
                    <div className={'row align-items-center mt-6'}>
                        <div className={'col-6 col-lg-4 col-md-6 offset-lg-4 offset-md-0'}>
                            {CancelButton}
                        </div>
                        <div className={'col-6 col-lg-4 col-md-6'}>
                            {SubmitButton}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    return <div className={'carepath-page'}>

        {/* dashboard responsive header */}
        <DashboardResponsiveHeader />

        <div className={'main-content'}>

            <div className={'container-fluid mx-auto'}>
                <div className={'row justify-content-between pt-16 pb-0 px-5'}>
                    <div className={'col-auto'}>
                        <a onClick={(e) => {
                            e.preventDefault()
                            dispatch(back())
                        }} className={'btn btn-round btn-sm-md'}>
                            <i
                                className={'fa-light fa-arrow-left'}
                                aria-hidden={'true'}>
                            </i>
                        </a>
                    </div>
                    <div className={'col-auto'}>
                        <h1 className={'my-0'}>{strings.fmt
                            ?.text.carepath.carepath}</h1>
                    </div>
                    <div className={'col-auto'}>
                        <a href={'#'}
                            onClick={(e) => {
                                e.preventDefault()
                            }} className={[
                                'btn btn-round btn-sm-md invisible'
                            ].join(' ')}>
                            <i
                                className={'fa-light fa-arrow-right'}
                                aria-hidden={'true'}
                            >
                            </i>
                        </a>
                    </div>
                </div>
                <div className={'row justify-content-between mt-5'}>
                    <div className={'col'}>
                        <div className={
                            'container-fluid px-5 px-sm-3 carepath-body list-interface'
                        }>
                            <div className={'h-100 row'}>
                                <div className={' col-12 mx-auto'}>
                                    {result}
                                </div>
                            </div>
                        </div>

                        <Modal centered={true} isOpen={showCancelWarning} toggle={() => {
                            setShowCancelWarning(!showCancelWarning)
                        }}>
                            <ModalHeader className={'justify-content-between'} toggle={() => {
                                setShowCancelWarning(!showCancelWarning)
                            }} close={ <a className={'btn btn-round '}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowCancelWarning(false)
                                }}>
                                <i className={'fa-light fa-multiply'} aria-hidden={'true'}>
                                </i>
                            </a>}
                            >
                                <span className={'h5'}>{
                                    strings.fmt?.message?.data_edit_warning?.title || ''
                                }</span>
                            </ModalHeader>
                            <ModalBody>
                                {/* content changes if idle / expire sessions expired. */}
                                <small className={'d-block my-4'}>
                                    {strings.fmt?.message?.data_edit_warning?.content || ''}
                                </small>
                                <div className={'row justify-content-end'}>
                                    <div className={'col-auto mb-2 mb-md-0'}>
                                        <button
                                            type={'button'}
                                            className={'btn btn-primary w-100'}
                                            onClick={ () => {
                                                setShowCancelWarning(false)
                                            }}>
                                            {strings.app?.text?.no || ''}
                                        </button>
                                    </div>
                                    <div className={'col-auto'}>
                                        <button type={'button'} onClick={() => {
                                            // reset formik.
                                            carepathAddFormik.resetForm()
                                            setShowCancelWarning(false)
                                        }} className={'btn btn-primary w-100'}>
                                            {strings.app?.text.yes}
                                        </button>

                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    </div>
                </div>

            </div>

        </div>

        <div
            className={'position-fixed bottom-0 end-0 pe-5 fs-label fw-light version-text'}
        >
            {MODULE_VERSION}
        </div>
    </div>
}

export default CarepathAddInterface
